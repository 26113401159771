import { marked } from 'marked'
import { useContext, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import alertsObserver from '../../observers/alerts-observer'
import { GlobalContext } from '../GlobalContext'
import { LoadingIcon } from '../LoadingIcon'
import { Modal } from './Modal'

export function AboutModal() {
	const { systemConfig } = useContext(GlobalContext)
	const [modalWidth, setModalWidth] = useState(Math.min(900, window.innerWidth * 0.9))
	const [videoLoaded, setVideoLoaded] = useState(false)

	return (
		<Modal id="about" calculateWidth visible getCalculatedWidth={width => setModalWidth(width)}>
			<VimeoPlayer
				style={{ maxWidth: modalWidth, display: videoLoaded ? '' : 'none' }}
				width="100%"
				height={Math.round((modalWidth / 16) * 9)}
				allow="autoplay"
				controls
				url={systemConfig.aboutVideo || ''}
				onReady={() => setVideoLoaded(true)}
				onError={err => alertsObserver.fire('error', 'err: ' + JSON.stringify(err))}
			/>
			<>
				{!videoLoaded ? (
					<div style={{ width: modalWidth, height: Math.round((modalWidth / 16) * 9), display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<LoadingIcon style={{ fontSize: 60, opacity: 0.1 }} />
					</div>
				) : null}
			</>
			<div style={{ padding: 15 }} className="oh-hi-mark oh-hi-mark-about" dangerouslySetInnerHTML={{ __html: marked(systemConfig.aboutText || '') }}></div>
		</Modal>
	)
}
