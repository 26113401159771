import { CSSProperties, useState } from 'react'
import { useHistory } from 'react-router-dom'
import consts from '../../consts'
import { fetchPost } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { navigateToLogin, navigateToParamlessModal } from '../../utils'
import { LoadingIcon } from '../LoadingIcon'
import { Modal } from './Modal'

export function SignupModal() {
	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [showErrorHint, setShowErrorHint] = useState<boolean>(false)

	const history = useHistory()

	function handleSubmitClick() {
		if (!email.trim() || !password.trim()) {
			setShowErrorHint(true)
			return
		}

		setLoading(true)

		fetchPost(consts.api_paths.signup, {
			body: JSON.stringify({
				email,
				password,
			}),
		})
			.then(data => {
				navigateToLogin(history)
				alertsObserver.fire('success', 'Conta criada com sucesso')
			})
			.catch(err => alertsObserver.fire('error', err))
			.finally(() => setLoading(false))
	}

	return (
		<Modal id="signup" width={500} visible calculateWidth={false} style={{ padding: 15 }}>
			<main className="flex flex-column gap-md">
				<h1 className="m-auto">Criar conta</h1>
				<FormSignup onSubmit={handleSubmitClick} />
			</main>
		</Modal>
	)
}

export function FormSignup(props: { onSubmit: () => void; style?: CSSProperties }) {
	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [showErrorHint, setShowErrorHint] = useState<boolean>(false)

	const history = useHistory()

	return (
		<form className="login-form flex flex-column gap-md" style={props.style || {}}>
			<section className="form-group">
				<label htmlFor="email">E-mail</label>
				<input
					id="email"
					name="email"
					type="email"
					className="form-control form-control-compact"
					value={email}
					required
					autoFocus
					disabled={loading}
					onChange={event => setEmail(event.target.value)}
					aria-describedby="email-help"
					placeholder="Digite seu e-mail"
				/>
				<small id="email-help" className={showErrorHint && !email ? 'text-danger' : 'hidden'}>
					E-mail para registro de login
				</small>
			</section>

			<section className="form-group">
				<label htmlFor="new-password">Senha</label>
				<input
					id="new-password"
					name="new-password"
					type="password"
					className="form-control form-control-compact"
					value={password}
					required
					disabled={loading}
					onChange={event => {
						setPassword(event.target.value)
					}}
					aria-describedby="new-password-help"
					placeholder="Digite sua senha"
				/>
				<small id="new-password-help" className={showErrorHint && !password ? 'text-danger' : 'hidden'}>
					Senha para registro de login
				</small>
			</section>

			<button type="button" disabled={loading} className="btn btn-success" onClick={props.onSubmit}>
				Entrar {loading ? <LoadingIcon /> : null}
			</button>

			<div className="flex space-between">
				<button type="button" className="btn btn-link" onClick={() => navigateToLogin(history)}>
					Login
				</button>
				<button type="button" className="btn btn-link" onClick={() => navigateToParamlessModal(history, 'recover')}>
					Recuperar senha
				</button>
			</div>
		</form>
	)
}
