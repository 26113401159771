export type User = {
	id: number,
	name: string,
	photo: string,
	phone: string,
	idHotmart: string,
	emailHotmart: string,
	email: string,
	type: UserType,
	address: string,
	cancellationRequestDate: string,
	passwordPlainText: boolean,
	cep: string,
	city: string,
	complement: string,
	country: string,
	cpf: string,
	number: string,
	password: string,
	typeExpirationDate: string,
	recoveryCode: string,
	recoveryCodeExpirationDate: string,

	keepWatchingList: string[],
	concludedList: string[],
	historyList: string[],
}

export enum UserType {
	Admin,
	Subscriber,
	Suspended,
	Teacher,
	BonusAccess,
	FreeAccess,
	Canceled,
}

export enum UserTypeDesc {
	'Administrador',
	'Assinante',
	'Suspenso',
	'Professor',
	'Acesso Bônus',
	'Acesso livre',
	'Cancelado',
}
