import { CSSProperties, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ModalId } from '../../observers/modals-observer'
import { navigateBackFromModal } from '../../utils'

export type ModalProps = {
	children?: React.ReactChild | React.ReactChild[]
	id: ModalId
	// this must exist so that when pressing "esc" it closes the login modal and then the concrete-case modal
	visible: boolean
	width?: string | number
	calculateWidth?: boolean
	getCalculatedWidth?: (width: number) => void
	style?: CSSProperties
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	modalRef?: any
	// canClose?: () => boolean
}

export function Modal(props: ModalProps) {
	const [modalWidth, setModalWidth] = useState(Math.min(900, window.innerWidth * 0.95))

	const history = useHistory()
	// const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	useEffect(() => {
		// const prevBodyStyleOverFlow = document.body.style.overflow
		// document.body.style.overflow = 'hidden'
		let isMounted = true

		props.getCalculatedWidth && props.getCalculatedWidth(modalWidth)

		function handleResize() {
			const newWidth = Math.min(900, window.innerWidth * 0.95)

			setModalWidth(newWidth)
			props.getCalculatedWidth && props.getCalculatedWidth(newWidth)
		}

		window.addEventListener('resize', handleResize)

		// remove the event listener when the object is destroyed
		return () => {
			isMounted = false
			// document.body.style.overflow = prevBodyStyleOverFlow
			window.removeEventListener('resize', handleResize)
		}
	}, [props])

	const width = props.calculateWidth ? modalWidth : props.width ? props.width : 'auto'
	const zIndex = props.style?.zIndex || stylesModalWrapper.zIndex || 150
	const bgZIndex = (zIndex as any) - 1

	if (!props.visible) return null

	return (
		<>
			<div
				style={{ ...styleBackground, zIndex: bgZIndex }}
				onClick={event => {
					event.stopPropagation()

					// if (!props.canClose || props.canClose()) {
					navigateBackFromModal(history)
					// }
				}}
			></div>
			<div ref={props.modalRef} style={{ ...stylesModalWrapper, zIndex, width: '100%', maxWidth: width, ...(props.style || {}) }} onClick={e => props.onClick && props.onClick(e)}>
				{props.children}
			</div>
		</>
	)
}

const stylesModalWrapper: CSSProperties = {
	position: 'fixed',
	top: 70,
	// padding: 5,
	left: '50%',
	transform: 'translateX(-50%)',
	margin: 0,
	maxHeight: 'calc(99% - 70px - 57px)',
	zIndex: 150,
	backgroundColor: 'rgba(20, 20, 20, 0.9)',
	borderRadius: 3,
	overflow: 'auto',
}

const styleBackground: CSSProperties = {
	backgroundColor: 'black',
	opacity: 0.8,
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	height: 'auto',
	width: 'auto',
	zIndex: 149,
}
