import { useState } from 'react'
import { LoadingIcon } from './LoadingIcon'

export function Score(props: { value: number; loading: boolean; onScoreChange: (newScore: number) => void }) {
	const [hoverValue, setHoverValue] = useState(props.value)

	function handleScoreClick(newScore: number) {
		if (newScore !== props.value) {
			props.onScoreChange(newScore)
		}
	}

	if (props.loading) {
		return (
			<>
				<LoadingIcon key={1} />
				<LoadingIcon key={2} />
				<LoadingIcon key={3} />
				<LoadingIcon key={4} />
				<LoadingIcon key={5} />
			</>
		)
	}

	return (
		<>
			<i
				key={1}
				onMouseEnter={() => setHoverValue(1)}
				onMouseLeave={() => setHoverValue(props.value)}
				onClick={() => handleScoreClick(1)}
				className={hoverValue >= 0.5 ? 'fas fa-star' : hoverValue > 0 ? 'fas fa-star-half-alt' : 'far fa-star'}
				style={{ color: 'rgb(240, 210, 117)' }}
			></i>
			<i
				key={2}
				onMouseEnter={() => setHoverValue(2)}
				onMouseLeave={() => setHoverValue(props.value)}
				onClick={() => handleScoreClick(2)}
				className={hoverValue >= 1.5 ? 'fas fa-star' : hoverValue > 1 ? 'fas fa-star-half-alt' : 'far fa-star'}
				style={{ color: 'rgb(240, 210, 117)' }}
			></i>
			<i
				key={3}
				onMouseEnter={() => setHoverValue(3)}
				onMouseLeave={() => setHoverValue(props.value)}
				onClick={() => handleScoreClick(3)}
				className={hoverValue >= 2.5 ? 'fas fa-star' : hoverValue > 2 ? 'fas fa-star-half-alt' : 'far fa-star'}
				style={{ color: 'rgb(240, 210, 117)' }}
			></i>
			<i
				key={4}
				onMouseEnter={() => setHoverValue(4)}
				onMouseLeave={() => setHoverValue(props.value)}
				onClick={() => handleScoreClick(4)}
				className={hoverValue >= 3.5 ? 'fas fa-star' : hoverValue > 3 ? 'fas fa-star-half-alt' : 'far fa-star'}
				style={{ color: 'rgb(240, 210, 117)' }}
			></i>
			<i
				key={5}
				onMouseEnter={() => setHoverValue(5)}
				onMouseLeave={() => setHoverValue(props.value)}
				onClick={() => handleScoreClick(5)}
				className={hoverValue >= 4.5 ? 'fas fa-star' : hoverValue > 4 ? 'fas fa-star-half-alt' : 'far fa-star'}
				style={{ color: 'rgb(240, 210, 117)' }}
			></i>
		</>
	)
}
