import { marked } from 'marked'
import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import consts from '../consts'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { TeacherForCarousel } from './MainBodyTypes'

import { useHistory, useParams } from 'react-router-dom'
import { Modal } from './modals/Modal'

export function TeacherModal() {
	const [teacher, setTeacher] = useState<TeacherForCarousel | null>(null)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [playing, setPlaying] = useState(true)
	const [muted, setMuted] = useState(false)

	const [videoUrl, setVideoUrl] = useState('')

	const topElement = useRef<HTMLElement>(null)
	const playerRef = useRef<VimeoPlayer>(null)
	const timeToSkipToRef = useRef(0)
	const history = useHistory<{ t: number }>()

	const { user, setUser, rawTeachers } = useContext(GlobalContext)

	const params = useParams<{ id?: string }>()
	const id = parseInt(params?.id as any)

	useEffect(() => {
		const newTeacher = rawTeachers.find(t => t.id === id)

		if (newTeacher) {
			setTeacher(newTeacher || null)
			setVideoLoaded(false)
			// setPlaying(false)
			setVideoUrl(newTeacher?.introVideo || '')
		}

		if (history.location.state?.t) {
			timeToSkipToRef.current = history.location.state.t
		}

		return () => {}
	}, [rawTeachers])

	const videoWidth = Math.min(900, window.innerWidth * 0.9)
	return (
		<Modal id="teacher" visible style={{ width: videoWidth, maxWidth: 900, overflowY: 'auto', overflowX: 'hidden', scrollBehavior: 'smooth' }}>
			<span ref={topElement}></span>
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
				{teacher?.photo ? (
					<img
						src={consts.DYNAMIC_FILES_URL + '/' + teacher?.photo}
						style={{ maxWidth: videoWidth, maxHeight: Math.round((videoWidth / 16) * 9), borderRadius: 2, display: videoLoaded ? 'none' : '' }}
					/>
				) : (
					<div style={{ height: '125px' }}></div>
				)}
				{!videoLoaded ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10 }} /> : null}
				<VimeoPlayer
					ref={playerRef}
					controls
					volume={muted ? 0 : 1}
					playing={playing}
					width={videoWidth}
					height={Math.round((videoWidth / 16) * 9)}
					style={{ display: videoLoaded ? '' : 'none' }}
					onError={() => {
						if (playing) {
							setPlaying(false)
						}
					}}
					url={videoUrl}
					onReady={() => {
						setVideoLoaded(true)

						if (timeToSkipToRef.current > 0) {
							playerRef.current?.seekTo(timeToSkipToRef.current)
						}
					}}
				/>
			</div>

			<div style={{ padding: 10, display: 'flex', flexDirection: 'row', gap: 15 }}>
				<div style={{ width: '100%' }}>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
						{teacher?.photo ? (
							<div style={consts.imgProfileSizeStyle}>
								<img style={consts.imgProfileStyle} src={consts.DYNAMIC_FILES_URL + '/' + teacher?.photo} />
							</div>
						) : null}
						<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 10, width: '100%' }}>
							<h3>{teacher?.name}</h3>
							<h4 style={{ fontSize: 18, lineHeight: 1.1 }}>{teacher?.description}</h4>
						</div>
					</div>
					<div>
						<p className="oh-hi-mark oh-hi-mark-teacher" dangerouslySetInnerHTML={{ __html: marked(teacher ? teacher.curriculum : '') }}></p>
					</div>
					<div className="flex wrap space-around content-center items-center gap-sm" style={{ fontSize: 40 }}>
						{teacher?.facebookLink ? (
							<a className="text-center" href={teacher?.facebookLink} target="_blank">
								<i className="fab fa-facebook-square"></i>
							</a>
						) : (
							<i className="fab fa-facebook-square disabled" style={{ opacity: 0.5 }}></i>
						)}
						{teacher?.instragramLink ? (
							<a className="text-center" href={teacher?.instragramLink} target="_blank">
								<i className="fab fa-instagram-square"></i>
							</a>
						) : (
							<i className="fab fa-instagram-square disabled" style={{ opacity: 0.5 }}></i>
						)}
						{teacher?.twitterLink ? (
							<a className="text-center" href={teacher?.twitterLink} target="_blank">
								<i className="fab fa-twitter-square"></i>
							</a>
						) : (
							<i className="fab fa-twitter-square disabled" style={{ opacity: 0.5 }}></i>
						)}
						{teacher?.youtubeLink ? (
							<a className="text-center" href={teacher?.youtubeLink} target="_blank">
								<i className="fab fa-youtube-square"></i>
							</a>
						) : (
							<i className="fab fa-youtube-square disabled" style={{ opacity: 0.5 }}></i>
						)}
						{teacher?.linkedinLink ? (
							<a className="text-center" href={teacher?.linkedinLink} target="_blank">
								<i className="fab fa-linkedin-in"></i>
							</a>
						) : (
							<i className="fab fa-linkedin-in disabled" style={{ opacity: 0.5 }}></i>
						)}
					</div>
				</div>
			</div>
		</Modal>
	)
}

type CSSObjects = {
	[_: string]: CSSProperties
}

const styles: CSSObjects = {
	wrapper: {
		position: 'fixed',
		top: 50,
		padding: 5,
		left: '50%',
		height: '90%',
		transform: 'translateX(-50%)',
		// padding: 50,
		margin: 0,
		zIndex: 150,
		backgroundColor: '#141414',
	},
	play: {
		backgroundColor: 'white',
		color: 'black',
		padding: '5px 30px 5px 21px',
		borderRadius: '3px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '13px',
		cursor: 'pointer',
	},
}
