import { marked } from 'marked'
import { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { useMediaQuery } from 'react-responsive'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import consts from '../consts'
import videoPreviewObserver from '../observers/video-preview-observer'
import { appPath } from '../routes'
import { LoadingIcon } from './LoadingIcon'
import { ConcreteCaseForCarousel } from './MainBodyTypes'

export function VideoDetailed(props: {
	concreteCase: ConcreteCaseForCarousel
	playing: boolean
	showThumbnail: boolean
	muted: boolean
	setMuted: React.Dispatch<React.SetStateAction<boolean>>
	onEnded: () => void
}) {
	const refSelf = useRef<HTMLDivElement>(null)
	const [loadingImage, setLoadingImage] = useState(true)
	const [loadImageError, setLoadImageError] = useState(false)
	const [playing, setPlaying] = useState(props.playing)
	const [videoReady, setVideoReady] = useState(false)
	const [showManualPlay, setShowManualPlay] = useState(true)
	const [pausedByPreview, setPausedByPreview] = useState(false)
	const [videoOutsideView, setVideoOutsideView] = useState(false)
	const [atHomePage, setAtHomePage] = useState(false)
	// const [muted, setMuted] = useState(true)

	const playerRef = useRef<VimeoPlayer>(null)
	const history = useHistory()
	const location = useLocation()
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	useEffect(() => {
		let isMounted = true

		function videoPreviewCallback(concreteCase: any) {
			// if (matchPath(window.location.pathname, { path: consts.app_paths.root, strict: true })) {
			// setPlaying(!concreteCase)
			setPausedByPreview(!!concreteCase)
			// } else {
			// setPlaying(false)
			// }
		}

		const unregisterCallback = history.listen(listener => {
			setAtHomePage(history.location.pathname.endsWith(consts.APP_URL) || history.location.pathname.endsWith(consts.APP_URL + '/'))
		})

		videoPreviewObserver.subscribeConcreteCase(videoPreviewCallback)

		function handleScroll() {
			setVideoOutsideView(window.scrollY > 350)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			isMounted = false
			window.removeEventListener('scroll', handleScroll)
			videoPreviewObserver.unsubscribeConcreteCase(videoPreviewCallback)
			unregisterCallback()
		}
	}, [])

	useEffect(() => {
		// const match = matchPath(location.pathname, { path: consts.app_paths.root, strict: true })

		// the matchPath above for some reason doesn't work as expected
		setAtHomePage(!location.pathname.includes('concrete-case'))
		setPlaying(!location.pathname.includes('concrete-case'))
		// setPlaying(!matchPath(location.pathname, { path: consts.app_paths.root, strict: true, exact: true }))
	}, [location])

	useEffect(() => {
		// if (props.playing) {
		// 	if (matchPath(window.location.pathname, { path: consts.app_paths.root, strict: true, exact: true })) {
		// 		setPlaying(props.playing)
		// 	} else {
		// 		setPlaying(false)
		// 	}
		// } else {
		setPlaying(props.playing)
		// }
	}, [props])

	function handleDetailsClick(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		// event.stopPropagation()
		if (props.concreteCase.id < 0) {
			setPlaying(!playing)

			if (!playing) {
				playerRef.current.getInternalPlayer().play()
			}

			props.setMuted(false)
			return
		}

		setPlaying(false)
		// setMuted(true)

		const time = (playerRef.current?.getDuration() || 0) - (playerRef.current?.getCurrentTime() || 0) < 30 ? 0 : playerRef.current?.getCurrentTime()

		// modalsObserver.fire('concrete-case', 'open', props)
		if (matchPath(window.location.pathname, appPath(consts.app_paths.teachers))) {
			history.push(appPath(consts.app_paths.teachers_concrete_case, { id: props.concreteCase.id }), { t: time })
		} else {
			history.push(appPath(consts.app_paths.concrete_case, { id: props.concreteCase.id }), { t: time })
		}
	}

	const modulesDuration = props.concreteCase.modules.reduce((sum, el) => sum + el.duration, 0)
	const percentageWatched = Math.min(props.concreteCase.userWatchInfo?.durationTotal && modulesDuration > 0 ? (props.concreteCase.userWatchInfo?.durationTotal / modulesDuration) * 100 : 0, 100)

	const videoWidth = window.innerWidth * (isTabletOrMobile ? 1 : 0.5) - 30
	const videoHeigth = ((window.innerWidth * (isTabletOrMobile ? 1 : 0.5) - 30) / 16) * 9

	const showVideoPlayer = !!videoReady
	const actuallyPlaying = props.playing && playing && !videoOutsideView && atHomePage && videoReady && !pausedByPreview

	return (
		<article style={{ display: 'flex', justifyContent: 'space-around' }}>
			{!isTabletOrMobile && (
				<div style={{ flexBasis: '50%', paddingRight: 30 }}>
					{/* {props.concreteCase.date && <h1 style={{ marginBottom: 5 }}>{new Date(props.concreteCase.date).getFullYear()}</h1>} */}
					<h2>{props.concreteCase.title}</h2>
					<p className="oh-hi-mark oh-hi-mark-concrete-case" style={{ lineHeight: 1.1 }} dangerouslySetInnerHTML={{ __html: marked(props.concreteCase.description) }}></p>
					{/* <div> */}
					{props.concreteCase.id > 0 && (
						<h3 style={stylePlayButton} onClick={handleDetailsClick}>
							<i className="fas fa-play"></i> Saiba mais
						</h3>
					)}
					{/* </div> */}
				</div>
			)}
			<div style={{ position: 'relative', ...(isTabletOrMobile ? {} : { flexBasis: '50%' }), marginBottom: 50 }} ref={refSelf} className="video">
				<div
					style={{ cursor: 'pointer', display: showManualPlay === false ? 'none' : '', opacity: 0, height: '100%', width: '100%', position: 'absolute', zIndex: 200 }}
					onClick={handleDetailsClick}
				></div>
				{props.showThumbnail && (
					<>
						<div style={{ width: '100%' }}>
							<img
								className="video__thumbnail"
								src={props.concreteCase.thumbnail ? consts.DYNAMIC_FILES_URL + '/' + props.concreteCase.thumbnail : ''}
								alt={props.concreteCase.title}
								style={{ display: showVideoPlayer ? 'none' : '', width: videoWidth, height: videoHeigth }}
								onClick={handleDetailsClick}
								onLoadStart={() => {
									setLoadingImage(true)
									setLoadImageError(false)
								}}
								onLoad={() => {
									setLoadingImage(false)
									setLoadImageError(false)
								}}
								onError={() => {
									setLoadImageError(true)
									setLoadingImage(false)
								}}
							/>
							<div style={{ display: showVideoPlayer ? '' : 'none' }}>
								<VimeoPlayer
									ref={playerRef}
									onReady={() => setVideoReady(true)}
									onStart={() => {
										setVideoReady(true)

										if (videoOutsideView || !atHomePage) {
											const player = playerRef.current?.getInternalPlayer()

											if (player && typeof player.pause === 'function') {
												player.pause()
											}

											setPlaying(false)
										}
									}}
									onEnded={() => props.onEnded()}
									volume={props.muted ? 0 : 1}
									width={videoWidth}
									height={videoHeigth}
									url={props.concreteCase.introVideo}
									playing={actuallyPlaying}
									onError={() => {
										if (!props.muted && playing) {
											setTimeout(() => {
												props.setMuted(true)
												// playerRef.current?.getInternalPlayer().setVolume(0)
												setPlaying(true)
											}, 100)
										} else {
											setShowManualPlay(true)
										}

										setPlaying(false)
									}}
								/>
								{percentageWatched ? <div style={{ height: 3, width: `${percentageWatched}%`, backgroundColor: 'red', position: 'absolute', left: 0 }}></div> : null}
							</div>
						</div>
					</>
				)}
				<div style={{ height: 50, position: 'absolute', right: 20, bottom: -60 }}>
					<i
						className={`fas ${props.muted ? 'fa-volume-mute' : 'fa-volume-up'}`}
						style={{ fontSize: 20, border: '1px solid white', borderRadius: '50%', padding: 10, zIndex: 201, color: 'white', float: 'right' }}
						onClick={e => {
							// e.stopPropagation()
							props.setMuted(prev => !prev)
							// playerRef.current?.getInternalPlayer().setVolume(muted ? 0 : 1)
						}}
					/>
				</div>
				{loadingImage || !videoReady ? (
					<div style={styleFloatingLoading}>
						<LoadingIcon />
					</div>
				) : null}
				{loadImageError ? <i className="fas fa-times-circle" style={styleFloatingError}></i> : null}
			</div>
		</article>
	)
}

const styleFloatingLoading: CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	fontSize: 50,
	opacity: 0.5,
	color: 'gray',
	transform: 'translateX(-50%) translateY(-50%)',
}

const styleFloatingError: CSSProperties = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	fontSize: 50,
	color: 'darkred',
	opacity: 0.8,
	transform: 'translateX(-50%) translateY(-50%)',
}

const stylePlayButton: CSSProperties = {
	backgroundColor: 'white',
	color: 'black',
	padding: '10px 30px',
	borderRadius: '3px',
	display: 'inline-block',
	cursor: 'pointer',
}
