import { ChangeEvent, KeyboardEvent, MouseEvent, useReducer, useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import consts from '../../consts'
import { useEffectAsync } from '../../custom-hooks'
import { fetchGet } from '../../fetch'
import { appPath } from '../../routes'
import { PaginationState, SortType } from '../../types'
import { defaultCatch, getNextSortOrder, getSortOrderForRequest } from '../../utils'
import { LoadingButton } from '../LoadingButton'
import { TableSortArrow } from '../TableSortArrow'
import { Category, CategorySortTypeDesc, CategoryType } from './CategoryTypes'

export type CategoryTableSortFields = {
	order: SortType
	label: SortType
	sortType: SortType
}

type SortField = keyof CategoryTableSortFields

export type CategoryResponse = [Category[], number]

export function CategoryGrid() {
	const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0)
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationState>(consts.paginationStateDefault)
	const [sortOrder, setSortOrder] = useState<CategoryTableSortFields>({ order: 'asc', label: 'none', sortType: 'none' })
	const [inputFilter, setInputFilter] = useState<string>('')

	const [type, setType] = useState<CategoryType | undefined>(undefined)
	const [categories, setCategories] = useState<Category[]>([])
	const [originalCategories, setOriginalCategories] = useState<Category[]>([])

	useEffectAsync<CategoryResponse>(
		() => {
			setLoading(true)
			return fetchGet('/categories', {
				type: type,
				_page: pagination.activePage,
				_filter: pagination.filter,
				_order: getSortOrderForRequest(sortOrder),
			})
		},
		handleCategoriesResponse,
		defaultCatch,
		setLoadingFalse,
		[forceUpdate, type, pagination.activePage, pagination.filter, sortOrder]
	)

	function setLoadingFalse() {
		setLoading(false)
	}

	function handleCategoriesResponse(data: CategoryResponse) {
		setStateCategories(data[0], pagination.filter)
		setPagination(prev => ({
			...prev,
			totalCount: data[1],
		}))
	}

	function setStateCategories(newCategories: Category[], filter: string) {
		setOriginalCategories(newCategories)
		setCategories(getFilteredCategories(newCategories, filter))
	}

	function getFilteredCategories(categories: Category[], filter: string) {
		if (filter.trim() === '') {
			return categories.slice()
		}

		filter = filter.toUpperCase()

		return categories.filter(
			cat => cat.label.toString().toUpperCase().includes(filter) || cat.order?.toString().toUpperCase().includes(filter) || CategorySortTypeDesc[cat.sortType].toUpperCase().includes(filter)
		)
	}

	function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
		const filter = event.target.value

		setInputFilter(filter)

		setStateCategories(originalCategories, filter)
	}

	function handleSearchKeyPress(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			setPagination(prev => ({ ...prev, activePage: 1, filter: inputFilter }))
			setForceUpdate()
		}
	}

	function handleReloadClick() {
		setForceUpdate()
	}

	function handlePaginationChange(page: number) {
		setPagination(prev => ({ ...prev, activePage: page }))
	}

	function handleSortOrderClick(event: MouseEvent<HTMLTableHeaderCellElement>, field: SortField) {
		if (event.currentTarget.style.cursor === 'not-allowed') {
			return
		}

		const nextSort = getNextSortOrder(sortOrder[field])

		const newSortOrder = { ...sortOrder }
		newSortOrder[field] = nextSort

		setSortOrder(newSortOrder)
	}

	return (
		<section className="w-100 flex flex-column gap-sm">
			<div className="flex flex-between items-center wrap gap-sm">
				<h1 className="text-primary font-md">Cadastro de Categorias</h1>
				<div className="flex flex-center btn-group-toggle" data-toggle="buttons">
					<button disabled={loading} onClick={() => setType(CategoryType.Dynamic)} className={`btn btn-secondary w-12ch ${type === CategoryType.Dynamic ? 'active' : ''}`}>
						Dinâmicas
					</button>
					<button disabled={loading} onClick={() => setType(undefined)} className={`btn btn-secondary w-12ch ${type === undefined ? 'active' : ''}`}>
						Todas
					</button>
					<button disabled={loading} onClick={() => setType(CategoryType.Fixed)} className={`btn btn-secondary w-12ch ${type === CategoryType.Fixed ? 'active' : ''}`}>
						Fixas
					</button>
					<button disabled={loading} onClick={() => setType(CategoryType.Hidden)} className={`btn btn-secondary w-12ch ${type === CategoryType.Hidden ? 'active' : ''}`}>
						Ocultas
					</button>
				</div>
			</div>
			<div className="flex wrap gap-sm space-between">
				<div className="flex gap-sm align-items-center">
					<LoadingButton animated={loading} disabled={loading} onClick={handleReloadClick} />
					<span>|</span>
					<Link to={appPath(consts.app_paths.admin_categories_new)}>
						<button className="btn btn-success">
							<i className="fas fa-plus"></i> Adicionar
						</button>
					</Link>
				</div>
				<div>
					<div className="input-group">
						<input type="text" className="form-control" disabled={loading} value={inputFilter} onChange={handleSearchChange} onKeyPress={handleSearchKeyPress} />
						<i className="fa fa-search input-addon" />
					</div>
				</div>
			</div>

			<table className="table w-100">
				<thead>
					<tr>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'order')}>
							<div className="flex space-between">
								<span>Ordem</span>
								<TableSortArrow order={sortOrder.order} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'label')}>
							<div className="flex space-between">
								<span>Nome</span>
								<TableSortArrow order={sortOrder.label} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'sortType')}>
							<div className="flex space-between">
								<span>Ordem de exibição</span>
								<TableSortArrow order={sortOrder.sortType} />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{categories.map(category => (
						<tr key={category.id}>
							<td>
								<Link to={appPath(consts.app_paths.admin_categories_id, { id: category.id })}>{category.order}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_categories_id, { id: category.id })}>{category.label}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_categories_id, { id: category.id })}>{CategorySortTypeDesc[category.sortType]}</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				activePage={pagination.activePage}
				itemsCountPerPage={pagination.countPerPage}
				totalItemsCount={pagination.totalCount}
				pageRangeDisplayed={pagination.range}
				onChange={handlePaginationChange}
			/>
		</section>
	)
}
