export enum CategoryType {
	Fixed,
	Dynamic,
	Hidden,
}

export enum CategoryTypeDesc {
	'Fixa',
	'Dinâmica',
}

export enum CategorySortType {
	DateCreatedAsc,
	DateCreatedDesc,
	MostViewedAsc,
	MostViewedDesc,
}

export enum CategorySortTypeDesc {
	'Mais antigas',
	'Mais recentes',
	'Menos acessados',
	'Mais acessados',
}

export type Category = {
	id: number
	type: CategoryType
	label: string
	order: number | undefined
	sortType: CategorySortType
}
