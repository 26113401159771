import { TeacherForCarousel } from "../components/MainBodyTypes"

export type TeacherDetailsObserverFunction = (teacher: TeacherForCarousel | null) => void

class TeacherDetailsObserver {
	private observers: TeacherDetailsObserverFunction[] = []
	private teacher!: TeacherForCarousel | null;

	subscribe(fn: TeacherDetailsObserverFunction) {
		this.observers.push(fn)
	}

	unsubscribe(callback: TeacherDetailsObserverFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fire(teacher: TeacherForCarousel | null) {
		this.observers.forEach(fn => {
			fn(teacher)
		})
	}

	setTeacher(teacher: TeacherForCarousel | null){
		this.teacher = teacher
	}
	getTeacher(){
		return this.teacher;
	}
}

const teacherDetailsObserver = new TeacherDetailsObserver()

export default teacherDetailsObserver
