import { useContext, useEffect, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { useHistory } from 'react-router-dom'
import consts from '../../consts'
import { fetchGet, fetchPost } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { navigateBackFromModal, navigateToParamlessModal } from '../../utils'
import { User, UserType } from '../cruds/UserTypes'
import { GlobalContext } from '../GlobalContext'
import { LoadingIcon } from '../LoadingIcon'
import { Modal } from './Modal'

export function LoginModal() {
	const url = new URL(window.location.href)

	const isNewUser = !!url.searchParams.get('new')
	const bonusUserEmail = url.searchParams.get('bonus') || ''
	const bonusCcId = parseInt(url.searchParams.get('bonusCcId') || '') || 0
	const blocked = url.searchParams.get('blocked') || ''

	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>(bonusUserEmail)
	const [password, setPassword] = useState<string>('')
	const [rememberMe, setRememberMe] = useState(true)
	const [errorMessage, setErrorMessage] = useState<string>('')
	const { user, setUser, setLoadingLoggedUser, systemConfig } = useContext(GlobalContext)

	const history = useHistory<{ redirectToProfile?: boolean; videoUrl?: string }>()

	const [bonusVideoUrl, setBonusVideoUrl] = useState(history.location?.state?.videoUrl || '')

	useEffect(() => {
		if (!bonusCcId || bonusVideoUrl) {
			return
		}

		fetchGet(consts.api_paths.bonus_accesses_by_cc_id + '/' + bonusCcId)
			.then((json: any) => setBonusVideoUrl(json?.videoUrlAcquired || ''))
			.catch(err => alertsObserver.fire('error', err))
	}, [])

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		if (!email.trim() || !password.trim()) {
			setErrorMessage('Favor preencher campos obrigatórios')
			return
		}

		setErrorMessage('')

		setLoading(true)
		setLoadingLoggedUser(true)

		fetchPost<User>(consts.api_paths.login, {
			body: JSON.stringify({
				email,
				password,
				rememberMe,
			}),
		})
			.then(data => {
				setUser(data)

				setLoading(false)
				setLoadingLoggedUser(false)

				if (history.location?.state?.redirectToProfile) {
					history.push(appPath(consts.app_paths.profile))
				} else {
					navigateBackFromModal(history)
				}
			})
			.catch(err => {
				setLoading(false)
				setLoadingLoggedUser(false)
				setErrorMessage(err)
			})
	}

	const videoWidth = Math.min(window.innerWidth * 0.9, 500)
	const videoHeigth = (videoWidth / 16) * 9

	const offerPremium = !!user
	const hotmartLink = offerPremium ? systemConfig.hotmartPremiumLink : systemConfig.hotmartCheckoutLink

	return (
		<Modal id="login" visible calculateWidth={false} width={500} style={{ padding: 15, zIndex: consts.loginModalZIndex }}>
			<main className="flex flex-column gap-md">
				{isNewUser || bonusUserEmail ? (
					<div style={{ textAlign: 'center' }}>
						<h2 className="text-primary lh-12">
							{isNewUser ? (
								<>
									Parabéns! Agora é um <i>assinante premium</i>
								</>
							) : (
								<>Sua senha foi enviada para o email</>
							)}
						</h2>
						{bonusUserEmail && <h2 className="text-primary lh-12">{bonusUserEmail}</h2>}
						<VimeoPlayer url={bonusVideoUrl} width="100%" height={videoHeigth} controls playing />
					</div>
				) : (
					<h1 style={{ fontSize: 30 }}>Entrar</h1>
				)}
				{!blocked ? (
					<>
						<form className="flex flex-column gap-md" onSubmit={handleSubmit}>
							{(isNewUser || bonusUserEmail) && <h2 className="text-center">Confira sua caixa de entrada ou SPAM e digite abaixo a senha recebida</h2>}
							<section className="form-group form-group-big-input">
								<label htmlFor="email">E-mail</label>
								<input
									id="email"
									name="email"
									type="text"
									className="form-control"
									value={email}
									required
									autoFocus={!bonusUserEmail}
									disabled={loading}
									onChange={event => setEmail(event.target.value)}
									aria-describedby="email-help"
									placeholder="Digite seu e-mail"
								/>
								<small id="email-help" className="hidden">
									E-mail registrado para login
								</small>
							</section>

							<section className="form-group form-group-big-input">
								<label htmlFor="password">Senha</label>
								<input
									id="password"
									name="password"
									type="password"
									className="form-control"
									value={password}
									required
									autoFocus={!!bonusUserEmail}
									disabled={loading}
									onChange={event => {
										setPassword(event.target.value)
									}}
									aria-describedby="password-help"
									placeholder="Digite sua senha"
								/>
								<small id="password-help" className="hidden">
									Senha registrado para login
								</small>
							</section>

							{errorMessage && <h3 className="text-danger">{errorMessage}</h3>}

							<button type="submit" disabled={loading} className="btn btn-danger btn-bg">
								Entrar {loading ? <LoadingIcon /> : null}
							</button>

							<section className="flex space-between">
								<section className="form-group">
									<input
										style={{ marginRight: 10 }}
										id="rememberMe"
										name="rememberMe"
										type="checkbox"
										checked={rememberMe}
										disabled={loading}
										onChange={event => {
											setRememberMe(event.target.checked)
										}}
										aria-describedby="rememberMe-help"
									/>
									<label style={{ margin: 0 }} htmlFor="rememberMe">
										Lembre-se de mim
									</label>
									{/* <small id="rememberMe-help" className="hidden">Senha registrado para login</small> */}
								</section>
								{/* <span className="btn btn-link" onClick={() => { modalsObserver.fire('login', 'close'); modalsObserver.fire('signup', 'open'); }}>Criar Conta</span> */}
								<section>
									<span
										className="btn-link cursor-pointer"
										style={errorMessage ? { border: '1px solid blue', borderRadius: 3, padding: 5 } : {}}
										onClick={() => navigateToParamlessModal(history, 'recover')}
									>
										Esqueci minha senha
									</span>
								</section>
							</section>
						</form>
					</>
				) : (
					<>
						{user && user.typeExpirationDate && new Date(user.typeExpirationDate) < new Date() ? (
							<>
								{user.type === UserType.BonusAccess ? (
									<h1 className="text-primary font-md text-center">O prazo para você assistir os casos gratuitos expirou</h1>
								) : (
									<h1 className="text-primary font-md text-center">O prazo para você assistir os casos concretos expirou</h1>
								)}
							</>
						) : (
							<h1 className="text-primary font-md text-center">Este caso concreto está disponível somente para assinantes premium</h1>
						)}
					</>
				)}

				{!(isNewUser || bonusUserEmail) && (
					<>
						<hr />

						<section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, padding: '0 15px' }}>
							<h3>Ainda não é assinante premium?</h3>
							<a
								className="btn text-center"
								style={{ padding: '15px 30px', fontSize: 17, fontWeight: 'bold', backgroundColor: 'rgb(113, 159, 82)', borderRadius: 1 }}
								href={hotmartLink}
								target="_blank"
								onClick={e => !hotmartLink && e.preventDefault()}
							>
								{offerPremium ? (
									<span>
										ADQUIRA O PLANO PREMIUM <i style={{ marginLeft: 5 }} className="fas fa-chevron-right"></i>
									</span>
								) : (
									<span>
										EXPERIMENTE GRÁTIS POR {systemConfig.daysFreeTest || <LoadingIcon />} DIAS <i style={{ marginLeft: 5 }} className="fas fa-chevron-right"></i>
									</span>
								)}
							</a>

							<button
								className="btn text-center"
								style={{ padding: '15px 30px', fontSize: 17, fontWeight: 'bold', backgroundColor: 'var(--danger)', borderRadius: 1 }}
								onClick={e => navigateBackFromModal(history)}
							>
								FECHAR
							</button>
						</section>
					</>
				)}
			</main>
		</Modal>
	)
}
