import { ConcreteCaseForCarousel } from '../components/MainBodyTypes'

export type VideoPreviewObserverConcreteCaseFunction = (concreteCase: ConcreteCaseForCarousel | null, videoWrapper: HTMLDivElement | null) => void

class VideoPreviewObserver {
	private observers: VideoPreviewObserverConcreteCaseFunction[] = []

	subscribeConcreteCase(fn: VideoPreviewObserverConcreteCaseFunction) {
		this.observers.push(fn)
	}

	unsubscribeConcreteCase(callback: VideoPreviewObserverConcreteCaseFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fireConcreteCase(concreteCase: ConcreteCaseForCarousel | null, videoWrapper: HTMLDivElement | null) {
		this.observers.forEach(fn => {
			fn(concreteCase, videoWrapper)
		})
	}
}

const videoPreviewObserver = new VideoPreviewObserver()

export default videoPreviewObserver
