import { useEffect, useRef } from 'react'
import { Link, matchPath } from 'react-router-dom'
import consts from '../../consts'
import { appPath } from '../../routes'
import { Modal } from './Modal'

export function SignupHotmartModal() {
	const hotmartWindow = useRef<Window | null>(null)

	useEffect(() => {
		if (!hotmartWindow.current || hotmartWindow.current.closed) {
			hotmartWindow.current = window.open('https://pay.hotmart.com/K15463555G?checkoutMode=2', '_blank')
		}
	}, [])

	return (
		<Modal id="signup-hotmart" visible style={{ padding: 10 }}>
			<h1 style={{ margin: 15 }}>Obrigado por experimentar nossos 30 dias gratuitos</h1>

			<hr />

			<h2 style={{ margin: 15, lineHeight: 1.2 }}>Após confirmação de cadastro será enviado um e-mail com uma senha temporária para o mesmo endereço de e-mail informado ao hotmart.</h2>

			<h2 style={{ margin: 15, lineHeight: 1.2 }}>Verifique sua caixa de entrada ou spam nos próximos minutos.</h2>

			<h3 style={{ margin: 15, lineHeight: 1.2 }}>
				<Link
					to={matchPath(window.location.pathname, { exact: false, path: consts.app_paths.teachers }) ? appPath(consts.app_paths.teachers_login) : appPath(consts.app_paths.login)}
					className="btn-link"
				>
					Clique aqui{' '}
				</Link>
				para fazer login
			</h3>

			<h3 style={{ margin: 15, lineHeight: 1.2 }}>
				Em caso dúvida ou problema contacte nosso{' '}
				<Link
					to={matchPath(window.location.pathname, { exact: false, path: consts.app_paths.teachers }) ? appPath(consts.app_paths.teachers_support) : appPath(consts.app_paths.support)}
					className="btn-link"
				>
					suporte
				</Link>
			</h3>
		</Modal>
	)
}
