import { useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import consts from '../consts'
import { appPath } from '../routes'
import { navigateToParamlessModal } from '../utils'
import { GlobalContext } from './GlobalContext'

export function NavbarMobile() {
	const { setForceHomePageUpdate, systemConfig } = useContext(GlobalContext)

	const history = useHistory()

	return (
		<nav className="navbar-mobile hover-children-select flex flex-row space-evenly items-center">
			<Link to={appPath(consts.app_paths.root)} className="flex flex-column items-center space-between gap-xs" onClick={() => setForceHomePageUpdate()}>
				<i className="fas fa-home"></i>
				<span>Início</span>
			</Link>
			<span className="flex flex-column items-center space-between gap-xs" onClick={() => navigateToParamlessModal(history, 'about')}>
				<i className="fas fa-users"></i>
				<span>Sobre</span>
			</span>
			<span className="flex flex-column items-center space-between gap-xs" onClick={() => navigateToParamlessModal(history, 'lives')}>
				<i className="fas fa-play"></i>
				<span>Lives</span>
			</span>
			<Link to={appPath(consts.app_paths.teachers)} className="flex flex-column items-center space-between gap-xs">
				<i className="fas fa-user-graduate"></i>
				<span>Professores</span>
			</Link>
			<span className="flex flex-column items-center space-between gap-xs" onClick={() => navigateToParamlessModal(history, 'support')}>
				<i className="fas fa-headset"></i>
				<span>Suporte</span>
			</span>
			<li className="flex flex-column items-center space-between gap-xs" style={{ color: '#FFDF00', border: '1px solid rgb(255, 223, 0)', borderRadius: '11px', padding: '4px 6px' }}>
				<i className="fas fa-crown"></i>
				<a className="text-center" href={systemConfig.hotmartCheckoutLink} target="_blank">
					Mês Grátis
				</a>
			</li>

			{/* <span
				className="flex flex-column items-center space-between gap-xs"
				onClick={() => {
					window.screen.orientation
						.lock(window.screen.orientation.type.includes('landscape') ? 'portrait-primary' : 'landscape-primary')
						.then(() => alertsObserver.fire('success', window.screen.orientation.type))
						.catch(err => alertsObserver.fire('error', JSON.stringify(err)))
				}}
			>
				<i className="fas fa-skull"></i>
			</span> */}
		</nav>
	)
}

// {
// 	loadingLoggedUser ? (
// 		<LoadingIcon />
// 	) : !user ? (
// 		<Link to={appPath(consts.app_paths.login)} className="btn btn-danger">
// 			{/* <i className="fas fa-user"></i> */}
// 			<span>Entrar</span>
// 		</Link>
// 	) : (
// 		<Link to={appPath(consts.app_paths.profile)} className="flex flex-column items-center space-between gap-xs">
// 			<i className="fas fa-user"></i>
// 			<span>Perfil</span>
// 		</Link>
// 	)
// }
