import { useEffect } from "react"

export function useEffectAsync<T>(promise: Promise<T> | (() => Promise<T>), resolve: (data: T) => void, reject: ((err: any) => void), fAnally?: Function, deps?: React.DependencyList | undefined) {
	useEffect(() => {
		let isMounted = true;

		(typeof promise === 'function' ? promise() : promise)
			.then((data: T) => {
				if (isMounted) {
					resolve(data);
				}
			})
			.catch((err: any) => {
				if (isMounted) {
					reject(err);
				}
			})
			.finally(() => fAnally && fAnally())

		return () => {
			isMounted = false;
		};
	}, deps);
}

// import { useEffect } from 'react'

// export function useEffectAsync<T>(promise: Promise<T> | (() => Promise<T>), deps?: React.DependencyList | undefined) {
// 	useEffect(() => {
// 		let isMounted = true;
		
// 		(typeof promise === 'function' ? promise() : promise)
// 			.then(data => isMounted && resolve(data as T))
// 			.catch(err => isMounted && reject(err))
			
// 		return () => {
// 			isMounted = false;
// 		};
// 	}, deps);

// 	return new Promise((resolve: (value: T) => void, reject) => {
		
// 	})
// }

