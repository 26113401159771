import { useContext } from 'react'
import { removeAccents } from '../utils'
import { CarouselConcreteCases } from './CarouselConcreteCases'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { ConcreteCaseForCarousel } from './MainBodyTypes'

export function MainBodyTeachers() {
	const { user, setUser, forceHomePageUpdate, rawTeachers, concreteCasesMasterList, loadingAll, filter } = useContext(GlobalContext)

	function getConcreteCases(teacherId: number): ConcreteCaseForCarousel[] {
		const filterUpperCase = removeAccents(filter.trim().toUpperCase())

		let filteredList = []

		if (filterUpperCase) {
			filteredList = concreteCasesMasterList.filter(
				cc =>
					cc.teacher.id === teacherId &&
					(removeAccents(cc.title.toUpperCase()).includes(filterUpperCase) ||
						removeAccents(cc.description.toUpperCase()).includes(filterUpperCase) ||
						cc.modules.find(m => removeAccents(m.title.toUpperCase()).includes(filterUpperCase) || removeAccents(m.description.toUpperCase()).includes(filterUpperCase)) ||
						removeAccents(cc.teacher.name.toUpperCase()).includes(filterUpperCase))
			)
		} else {
			filteredList = concreteCasesMasterList.filter(cc => cc.teacher.id === teacherId)
		}

		return filteredList.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
	}

	return (
		<main className="main">
			<section className="flex-column gap-lg">
				{loadingAll ? <LoadingIcon /> : null}
				{rawTeachers.map(teacher => (
					<CarouselConcreteCases
						key={teacher.id}
						id={teacher.id}
						label={
							<>
								{teacher.name} - {teacher.description}{' '}
								{<SocialMedia {...{ facebook: teacher.facebookLink, instagram: teacher.instragramLink, youtube: teacher.youtubeLink, twitter: teacher.twitterLink, linkedin: teacher.linkedinLink }} />}
							</>
						}
						labelFontSize={20}
						size="md"
						concreteCases={getConcreteCases(teacher.id)}
						showTeacherIntro={teacher}
						allPendingCommentsCount={0}
					/>
				))}
			</section>
		</main>
	)
}

function SocialMedia(props: { facebook: string; instagram: string; youtube: string; twitter: string; linkedin: string }) {
	return (
		<>
			{props.facebook && (
				<a href={props.facebook} target="_blank" style={{ outline: 'none' }}>
					<i style={{ marginLeft: 15, cursor: 'pointer' }} className="fab fa-facebook-square"></i>
				</a>
			)}
			{props.instagram && (
				<a href={props.instagram} target="_blank" style={{ outline: 'none' }}>
					<i style={{ marginLeft: 15, cursor: 'pointer' }} className="fab fa-instagram-square"></i>
				</a>
			)}
			{props.youtube && (
				<a href={props.youtube} target="_blank" style={{ outline: 'none' }}>
					<i style={{ marginLeft: 15, cursor: 'pointer' }} className="fab fa-youtube-square"></i>
				</a>
			)}
			{props.twitter && (
				<a href={props.twitter} target="_blank" style={{ outline: 'none' }}>
					<i style={{ marginLeft: 15, cursor: 'pointer' }} className="fab fa-twitter-square"></i>
				</a>
			)}
			{props.linkedin && (
				<a href={props.linkedin} target="_blank" style={{ outline: 'none' }}>
					<i style={{ marginLeft: 15, cursor: 'pointer' }} className="fab fa-linkedin-in"></i>
				</a>
			)}
		</>
	)
}
