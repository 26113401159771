import { MouseEventHandler } from 'react'
import { LoadingIcon } from './LoadingIcon'

export type LoadingButtonProps = {
	className?: string
	onClick?: MouseEventHandler<HTMLElement>
	label?: string
	disabled?: boolean
	animated?: boolean
}

export function LoadingButton(props: LoadingButtonProps) {
	return (
		<button className="btn btn-primary" onClick={props.onClick} disabled={props.disabled}>
			<LoadingIcon animated={props.animated} /> {' ' + (props.label === undefined ? 'Carregar' : '')}
		</button>
	)
}
