import { CSSProperties, MouseEvent, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import consts from '../consts'
import globalsObserver from '../observers/globals-observer'
import teacherDetailsObserver from '../observers/teacher-details-observer'
import videoIntroPreviewObserver from '../observers/video-intro-preview-observer'
import { appPath } from '../routes'
import { TeacherForCarousel } from './MainBodyTypes'

export function VideoIntro(props: TeacherForCarousel & { style?: CSSProperties }) {
	const [timeoutId, setTimeoutid] = useState<NodeJS.Timeout>()
	const refSelf = useRef<HTMLDivElement>(null)
	const history = useHistory()

	function handleMouseEnter() {
		if (props.id && refSelf.current) {
			setTimeoutid(
				setTimeout(() => {
					if (refSelf.current && !globalsObserver.getDraggingCarousel()) {
						videoIntroPreviewObserver.fire(props, refSelf.current)
					}
				}, 300)
			)
		}
	}

	function handleMouseLeave() {
		timeoutId && clearTimeout(timeoutId)
	}

	function handleDetailsClick(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		event.stopPropagation()

		teacherDetailsObserver.setTeacher(props)
		history.push(appPath(consts.app_paths.teachers_id, { id: props.id }))
	}

	return (
		<article style={{ cursor: 'pointer', position: 'relative' }} ref={refSelf} className="video">
			<div style={{ display: 'flex', justifyContent: 'center', height: props.style?.maxHeight }} onClick={handleDetailsClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
				<img style={props.style} src={consts.DYNAMIC_FILES_URL + '/' + props.photo} alt={props.name} />
			</div>
		</article>
	)
}
