import { User } from "./UserTypes";

export enum PersonType {
	Natural,
	Juridical,
}

export enum PersonTypeDesc {
	'Pessoa física',
	'Pessoa jurídica',
}

export type Teacher = {
	id: number,
	name: string,
	order: number | undefined,
	phone: string,
	description: string,
	facebookLink: string,
	instragramLink: string,
	youtubeLink: string,
	twitterLink: string,
	linkedinLink: string,
	photo: string,
	curriculum: string,
	emailHotmart: string,
	email: string,
	passwordHotmart: string,
	country: string,
	cep: string,
	state: string,
	city: string,
	address: string,
	number: string,
	complement: string,
	contract: string,
	personType: PersonType,
	companyName: string,
	cnpj: string,
	pix: string,
	bankCode: string,
	bankAgency: string,
	bankAccount: string,
	obs: string,
	introVideo: string,
	userId: number,
}
