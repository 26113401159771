export type AlertType = 'warning' | 'error' | 'success'

export type AlertsObserverFunction = (type: AlertType, message: string | JSX.Element) => void

class AlertsObserver {
	private observers: AlertsObserverFunction[] = []

	subscribe(fn: AlertsObserverFunction) {
		this.observers.push(fn)
	}

	unsubscribe(callback: AlertsObserverFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fire(type: AlertType, message: string | JSX.Element) {
		this.observers.forEach(fn => {
			fn(type, message)
		})
	}
}

const alertsObserver = new AlertsObserver()

export default alertsObserver
