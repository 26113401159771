import { CategorySortType } from './cruds/CategoryTypes'
import { UserType } from './cruds/UserTypes'

export type ModuleForCarousel = {
	id: number
	title: string
	description: string
	thumbnail: string
	thumbnailSm: string
	duration: number
	number: number
	videoUrl?: string
	userWatchInfo?: UserWatchInfo
}

export type TeacherForCarousel = {
	id: number
	name: string
	description: string
	curriculum: string
	photo: string
	facebookLink: string
	instragramLink: string
	youtubeLink: string
	twitterLink: string
	linkedinLink: string
	introVideo: string
}

export type ConcreteCaseForCarousel = {
	id: number
	code: string
	title: string
	description: string
	score: number
	date: string
	thumbnail: string
	thumbnailSm: string
	introVideo: string
	categoryConcreteCases: CategoryConcreteCaseForHomePage[]
	viewCount: number
	pendingCommentsCount: number
	category: CategoryForHomePage
	userWatchInfo?: UserWatchInfo
	teacher: TeacherForCarousel
	modules: ModuleForCarousel[]
}

export type CategoryForCarousel = {
	id: number
	label: string
	loading: boolean
	// concreteCases: ConcreteCaseForCarousel[],
}

export type UserComment = {
	id: number
	text: string
	name: string
	photo: string
	score: number
	type: UserType
}

export type Comment = {
	id: number
	text: string
	date: string
	status: CommentStatus
	user: UserComment
	commentToAdd: string
	showCommentToAdd: boolean
	comments: Comment[]
}

export type UserWatchInfo = {
	type: UserWatchListType
	duration: number
	durationTotal: number
	concluded: boolean
	score: number
	date: string
	certificateFile?: string
}

export type LiveRawForHomePage = {
	id: number
	title: string
	videoUrl: string
	duration: number
	keywords: string
	teachersNames: string
}

export type LiveForHomePage = {
	id: number
	title: string
	videoUrl: string
	duration: number
	keywords: string
	teachersNames: string
	userWatchInfo?: UserWatchInfo
}

export enum UserWatchListType {
	MyList,
	Watched,
}

export enum CommentStatus {
	Pending,
	Accepted,
	Rejected,
}

export type CategoryForHomePage = {
	id: number
	label: string
	order: number
	sortType: CategorySortType
}

export type ConcreteCaseForHomePage = {
	id: number
	description: string
	code: string
	title: string
	score: number
	viewCount: number
	pendingCommentsCount: number
	date: string
	teacherId: number
	mainCategoryId: number
	introVideo: string
	thumbnail: string
	thumbnailSm: string
	categoryConcreteCases: CategoryConcreteCaseForHomePage[]
	modules: ModuleForHomePage[]
}

export type ModuleForHomePage = {
	id: number
	title: string
	description: string
	number: number
	duration: number
	thumbnail: string
	thumbnailSm: string
}

export type CategoryConcreteCaseForHomePage = {
	categoryId: number
	order: number
	sortType: CategorySortType
}

export type TeacherForHomePage = {
	id: number
	introVideo: string
	name: string
	order: number
	description: string
	facebookLink: string
	instragramLink: string
	youtubeLink: string
	twitterLink: string
	linkedinLink: string
	photo: string
	curriculum: string
}

export type UserWatchListForHomePage = {
	type: UserWatchListType
	duration: number
	durationTotal: number
	concluded: boolean
	score: number
	date: string
	concreteCaseId?: number
	moduleId?: number
	liveId?: number
}
