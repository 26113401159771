import { useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { navigateToParamlessModal } from '../utils'
import { GlobalContext } from './GlobalContext'

export function NavbarMobileTop() {
	const { setForceHomePageUpdate, loadingLoggedUser, user } = useContext(GlobalContext)
	const [displaySearch, setDisplaySearch] = useState(false)
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	const history = useHistory()

	return null

	return (
		<nav style={{ width: '100vw', position: 'fixed', top: 0, zIndex: 99999, height: 40, display: isTabletOrMobile ? 'flex' : 'none', justifyContent: 'space-around', alignItems: 'center' }}>
			<span className="flex flex-column items-center space-between gap-xs" onClick={() => navigateToParamlessModal(history, 'lives')}>
				<i className="fas fa-arrow-left"></i>
			</span>
			{displaySearch && (
				<span className="flex flex-column items-center space-between gap-xs" onClick={() => navigateToParamlessModal(history, 'lives')}>
					<input type="text" />
				</span>
			)}
			<span className="flex flex-column items-center space-between gap-xs" onClick={() => setDisplaySearch(prev => !prev)}>
				<i className="fa fa-search input-addon"></i>
			</span>
		</nav>
	)
}
