import { useEffect, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { fetchGet } from '../fetch'

export function LiteVideoPlayer() {
	const [videoUrl, setVideoUrl] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	useEffect(() => {
		const ccInfo = window.location.pathname.split('/lite/')[1]
		const [ccId, modNumber] = ccInfo.split('/')

		fetchGet(`/video-url/${Number(ccId) || '0'}/${Number(modNumber) || ''}`)
			.then((res: any) => {
				if (res.url) {
					return setVideoUrl(res.url)
				}

				if (res.blocked) {
					return setErrorMessage('Acesso bloqueado')
				}

				setErrorMessage('Erro ao carregar video, certifique-se que está logado e tem acesso ao video em questão')
			})
			.catch(err => {
				if (typeof err === 'string') {
					return setErrorMessage(err)
				}

				if (err && err.message) {
					return setErrorMessage(err.message)
				}

				setErrorMessage('Erro ao carregar video, certifique-se que está logado e tem acesso ao video em questão')
			})
	}, [])

	return (
		<>{errorMessage ? <h1>{errorMessage}</h1> : videoUrl ? <VimeoPlayer controls width="100vw" height="100vh" style={{ width: '100vw', height: '100vh' }} url={videoUrl} /> : <h1>Carregando...</h1>}</>
	)
}
