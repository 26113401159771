import { useContext, useEffect, useRef, useState } from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/scrollbar'
import { Video } from './Video'
import { VideoDetailed } from './VideoDetailed'
import { ConcreteCaseForCarousel, TeacherForCarousel } from './MainBodyTypes'
import { LoadingIcon } from './LoadingIcon'
import { GlobalContext } from './GlobalContext'
import { VideoIntro } from './VideoIntro'
import carouselDragObserver from '../observers/globals-observer'
import videoPreviewObserver from '../observers/video-preview-observer'
import { useMediaQuery } from 'react-responsive'
import { UserType } from './cruds/UserTypes'
import consts from '../consts'
import { useLocation } from 'react-router'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const VIDEO_WIDTH = 300
const MAX_REGULAR_VIDEO_WIDTH = 336
const MAX_SPOTLIGHT_VIDEO_WIDTH = 800
// const EMPTY_CONCRETE_CASE: ConcreteCaseForCarousel = {
// 	id: 0, code: '', date: '', description: '', introVideo: '', score: 0, modules: [], comments: [], teacher: {
// 		id: 0, curriculum: '', description: '', name: '', photo: '', facebookLink: '', instragramLink: '', youtubeLink: '', twitterLink: '', linkedinLink: '' }, thumbnail: 'thumbnail-empty.png', title: '' }

export function CarouselConcreteCases(props: {
	id: number
	size: 'lg' | 'md'
	labelFontSize: number
	label: string | JSX.Element
	showTeacherIntro?: TeacherForCarousel
	concreteCases: ConcreteCaseForCarousel[]
	allPendingCommentsCount: number
}) {
	const location = useLocation()
	const [slidesPerView, setSlidesPerView] = useState(Math.max(Math.floor(window.innerWidth / VIDEO_WIDTH), 2))
	const [concreteCases, setConcreteCases] = useState<ConcreteCaseForCarousel[]>([...props.concreteCases])
	const [currentSpotlightIndex, setCurrentSpotlightIndex] = useState(0)
	const [videoDetailedMuted, setVideoDetailedMuted] = useState(true)

	const [amountToRender, setAmoutToRender] = useState(slidesPerView + 2)
	const { loadingAll, user } = useContext(GlobalContext)

	const swiperCoreRef = useRef<SwiperCore | null>(null)
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	useEffect(() => {
		let isMounted = true

		if (concreteCases.length === 0 || props.concreteCases.length === concreteCases.length) {
			setConcreteCases([...props.concreteCases])
		} else {
			setConcreteCases([])

			setTimeout(() => {
				if (!isMounted) {
					return
				}

				setConcreteCases([...props.concreteCases])
			}, 500)
		}

		return () => {
			isMounted = false
		}
	}, [props])

	useEffect(() => {
		function handleResize() {
			setSlidesPerView(Math.max(Math.floor(window.innerWidth / VIDEO_WIDTH), 2))
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	if (!loadingAll && concreteCases.length === 0) {
		if (props.id === consts.categoryPendingCommentsId && user?.type === UserType.Admin) {
			return (
				<div>
					<h2 style={{ marginBottom: 15, marginTop: isTabletOrMobile ? 40 : 60, marginLeft: 5 }}>
						{props.label} ({props.allPendingCommentsCount})
					</h2>
				</div>
			)
		}
		return null
	}

	if (concreteCases.length === 0) {
		if (props.id === consts.categoryMyListId && user?.type === UserType.BonusAccess) {
			return null
		}

		if (props.id === consts.categoryBonusesId && user?.type !== UserType.BonusAccess) {
			return null
		}

		if (props.id === consts.categoryPendingCommentsId && user?.type !== UserType.Admin) {
			return null
		}
	}

	// Math.max(slidesPerView - 2, 1)

	const slidesPerViewCalculated = (props.size || 'md') === 'lg' ? 1 : slidesPerView
	const maxVideoWidth = (props.size || 'md') === 'lg' ? MAX_SPOTLIGHT_VIDEO_WIDTH : MAX_REGULAR_VIDEO_WIDTH
	const maxVideoHeigth = Math.round((maxVideoWidth / 16) * 9)

	const aproxVideoWidth = (window.innerWidth - 20 - slidesPerViewCalculated * 10) / slidesPerViewCalculated
	const aproxVideoHeight = (aproxVideoWidth * 9) / 16

	// rendering the Swipper only when all the videos are available seems to fix a lot of bugs
	if (concreteCases.length === 0) {
		return (
			<div style={props.size === 'md' ? { minHeight: aproxVideoHeight } : {}}>
				<h2 style={{ marginBottom: 15, marginTop: isTabletOrMobile ? 40 : 60, marginLeft: 5 }}>
					{props.label} {loadingAll ? <LoadingIcon style={{ color: 'gray' }} /> : null}
				</h2>
			</div>
		)
	}

	const haveNavigation = props.size !== 'lg' && concreteCases.length + (props.showTeacherIntro ? 1 : 0) > slidesPerViewCalculated

	return (
		<div style={props.size === 'md' ? { minHeight: aproxVideoHeight } : {}}>
			<h2 style={{ marginBottom: 15, marginTop: isTabletOrMobile ? 40 : 60, marginLeft: 5, fontSize: isTabletOrMobile ? props.labelFontSize - 4 : props.labelFontSize }}>
				{props.size === 'lg' ? '' : props.label} {loadingAll ? <LoadingIcon style={{ color: 'gray' }} /> : null}
				{/* <Link to={appPath(consts.app_paths.concrete_case_path, { id: 25 })}> LINKE </Link> */}
			</h2>
			{
				<Swiper
					key={`${slidesPerView}_${props.label}_gambi`}
					onInit={s => {
						s.on('touchStart', () => {
							carouselDragObserver.setDraggingCarousel(true)
						})
						s.on('touchEnd', () => {
							carouselDragObserver.setDraggingCarousel(false)
						})

						swiperCoreRef.current = s
					}}
					spaceBetween={15}
					slidesPerView={slidesPerViewCalculated}
					navigation={haveNavigation}
					onSlideChange={s => {
						setCurrentSpotlightIndex(s.activeIndex)
					}}
					loop={haveNavigation}
					pagination={{
						el: '.swiper-pagination',
						clickable: true,
						// type: 'bullets',
						// renderBullet: (i, className) => {
						// 	return '<span className="' + className + '" style="color: white; z-index: 100">' + i + '</span>'
						// },
					}}
					// scrollbar={{ draggable: true }}
					style={{ padding: props.size === 'lg' ? 0 : '0px 30px' }}
					// hides the images it's not seeing, to save on images requested,
					onSlideChangeTransitionStart={s => {
						setAmoutToRender(s.realIndex + slidesPerView + 2)
						videoPreviewObserver.fireConcreteCase(null, null)
					}}
				>
					{props.showTeacherIntro ? (
						<SwiperSlide key={'tt' + props.showTeacherIntro.id}>
							<VideoIntro style={{ maxWidth: aproxVideoWidth, maxHeight: aproxVideoHeight, borderRadius: '50%' }} {...props.showTeacherIntro} />
						</SwiperSlide>
					) : null}
					{concreteCases.map((cc, idx) => (
						<SwiperSlide key={'ss' + props.label + cc.id}>
							{props.size === 'lg' ? (
								<VideoDetailed
									key={'vd' + cc.id}
									concreteCase={cc}
									playing={(currentSpotlightIndex === idx && window.scrollY < 300) || location.search.includes('ext=')}
									showThumbnail={idx <= amountToRender}
									muted={videoDetailedMuted}
									setMuted={setVideoDetailedMuted}
									onEnded={() => (swiperCoreRef.current?.realIndex === concreteCases.length - 1 ? swiperCoreRef.current?.slideTo(0) : swiperCoreRef.current?.slideNext())}
								/>
							) : (
								<Video
									key={'vd' + props.label + cc.id}
									user={user}
									concreteCase={cc}
									showThumbnail={idx <= amountToRender}
									style={{ maxWidth: maxVideoWidth, maxHeight: maxVideoHeigth, minHeight: aproxVideoHeight - 30 }}
								/>
							)}
						</SwiperSlide>
					))}
					{props.size === 'lg' && (
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							{concreteCases.map((cc, i) => (
								<i
									key={cc.id}
									className="fas fa-circle mr-10"
									style={{ cursor: 'pointer', color: i === currentSpotlightIndex ? 'white' : 'gray' }}
									onClick={() => {
										swiperCoreRef.current?.slideTo(i)
									}}
								/>
							))}
						</div>
					)}
				</Swiper>
			}
		</div>
	)
}
