import { createContext } from 'react'
import { UserType } from './cruds/UserTypes'
import { CategoryForHomePage, ConcreteCaseForCarousel, ConcreteCaseForHomePage, LiveForHomePage, LiveRawForHomePage, TeacherForHomePage, UserWatchListForHomePage } from './MainBodyTypes'

export type LoggedUserInfo = {
	id: number
	name: string
	cpf: string
	phone: string
	country: string
	cep: string
	city: string
	address: string
	number: string
	complement: string
	email: string
	photo: string
	type: UserType
	passwordPlainText: boolean
	typeExpirationDate: string
	teacherId?: number
}

export type SystemConfigInfo = {
	phoneNumber: string
	openingHoursMessage: string
	whatsappNumber: string
	aboutVideo: string
	aboutText: string
	facebookLink: string
	instragramLink: string
	youtubeLink: string
	twitterLink: string
	linkedinLink: string
	daysFreeTest: number
	hotmartCheckoutLink: string
	hotmartPremiumLink: string
	spotlightVideoUrl?: string
}

type GlobalContextParamType = {
	user: LoggedUserInfo | null
	setUser: React.Dispatch<React.SetStateAction<LoggedUserInfo | null>>
	systemConfig: SystemConfigInfo
	setSystemConfig: React.Dispatch<React.SetStateAction<SystemConfigInfo>>
	filter: string
	setFilter: React.Dispatch<React.SetStateAction<string>>

	loadingAll: boolean
	setLoadingAll: React.Dispatch<React.SetStateAction<boolean>>
	rawCategories: CategoryForHomePage[]
	setRawCategories: React.Dispatch<React.SetStateAction<CategoryForHomePage[]>>
	rawConcreteCases: ConcreteCaseForHomePage[]
	setRawConcreteCases: React.Dispatch<React.SetStateAction<ConcreteCaseForHomePage[]>>
	rawLives: LiveRawForHomePage[]
	setRawLives: React.Dispatch<React.SetStateAction<LiveRawForHomePage[]>>
	rawTeachers: TeacherForHomePage[]
	setRawTeachers: React.Dispatch<React.SetStateAction<TeacherForHomePage[]>>
	rawBonuses: number[]
	setRawBonuses: React.Dispatch<React.SetStateAction<number[]>>
	rawUserWatchList: UserWatchListForHomePage[]
	setRawUserWatchList: React.Dispatch<React.SetStateAction<UserWatchListForHomePage[]>>
	concreteCasesMasterList: ConcreteCaseForCarousel[]
	setConcreteCasesMasterList: React.Dispatch<React.SetStateAction<ConcreteCaseForCarousel[]>>
	livesMasterList: LiveForHomePage[]
	setLivesMasterList: React.Dispatch<React.SetStateAction<LiveForHomePage[]>>

	forceHomePageUpdate: boolean
	setForceHomePageUpdate: React.DispatchWithoutAction
	loadingLoggedUser: boolean
	setLoadingLoggedUser: React.Dispatch<React.SetStateAction<boolean>>
}

export const GlobalContext = createContext<GlobalContextParamType>(null as any)
