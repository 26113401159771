import { FormEvent, useEffect, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import consts from '../consts'
import { fetchGet, fetchPost } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import { appPath } from '../routes'
import { BonusAccess } from './cruds/BonusAccessTypes'
import { LoadingIcon } from './LoadingIcon'
import { Modal } from './modals/Modal'

// const VimeoPlayer = require('react-player/vimeo') as any

export function BonusAccessModal() {
	const [loading, setLoading] = useState<boolean>(false)
	const [link, setLink] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [expirationDate, setExpirationDate] = useState<string>('')
	const [videoUrl, setVideoUrl] = useState<string>('')
	const [videoUrlAcquired, setVideoUrlAcquired] = useState<string>('')
	const [thumbnail, setThumbnail] = useState<string>('')
	const [thumbnailAcquired, setThumbnailAcquired] = useState<string>('')
	const [concreteCaseId, setConcreteCaseId] = useState(0)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [videoError, setVideoError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [playing, setPlaying] = useState(false)

	useEffect(() => {
		let isMounted = true

		const path = window.location.pathname.split('/bonus/')[1]

		if (path) {
			setLoading(true)

			fetchGet<BonusAccess>(consts.api_paths.bonus_accesses_by_path + '/' + path)
				.then(json => {
					if (!isMounted) return
					setConcreteCaseId(json.id)
					setTitle(json.title)
					setExpirationDate(json.expirationDate)
					setVideoUrl(json.videoUrl)
					setVideoUrlAcquired(json.videoUrlAcquired)
					setLink(consts.HOST + consts.APP_URL + json.link)
					setThumbnail(json.thumbnail)
					setThumbnailAcquired(json.thumbnailAcquired)
					setConcreteCaseId(json.concreteCaseId)

					setPlaying(true)
				})
				.catch(err => {
					if (!isMounted) return
					setErrorMessage(err)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		}

		return () => {
			isMounted = false
		}
	}, [])

	return (
		<Modal id="bonus-access" visible width={780} style={{ padding: 10, textAlign: 'center' }}>
			<div className="flex content-center flex-column">
				<h1 style={{ lineHeight: 1.3, color: '#4472C4', visibility: errorMessage ? 'hidden' : 'visible' }}>Você está a um passo de receber o seu acesso GRATUITO ao caso:</h1>
				<h1 style={{ lineHeight: 1.3, color: '#4472C4', marginBottom: 10 }}>{errorMessage ? errorMessage : loading ? <LoadingIcon /> : title}</h1>
				<div style={{ width: '100%', height: Math.round((Math.min(window.innerWidth, 780 - 80) / 16) * 9) }}>
					{errorMessage ? (
						<h1 style={{ marginTop: 50 }}>
							<Link className="btn-link" to={appPath(consts.app_paths.root)}>
								Clique aqui para ir à página inicial
							</Link>
						</h1>
					) : videoError ? (
						<i style={{ fontSize: 50, color: 'red', marginTop: '20%' }} className="fas fa-times-circle" />
					) : (
						!videoLoaded && <LoadingIcon style={{ fontSize: 50, color: 'gray', marginTop: '20%' }} />
					)}
					<VimeoPlayer
						width="100%"
						height="100%"
						onReady={() => setVideoLoaded(true)}
						onError={(err: any) => {
							const errorMessage = (err && JSON.stringify(err)) + ''

							if (errorMessage.includes('NotAllowedError')) {
								setPlaying(false)
							} else {
								alertsObserver.fire('error', errorMessage)
								setVideoError(true)
							}
						}}
						controls
						playing={playing}
						url={videoUrl}
						style={{ display: videoLoaded && !videoError ? '' : 'none' }}
					/>
				</div>
				{!errorMessage && (
					<>
						<BonusCaseFormSignup concreteCaseId={concreteCaseId} videoUrl={videoUrlAcquired} />
					</>
				)}
			</div>
		</Modal>
	)
}

function BonusCaseFormSignup(props: { concreteCaseId: number; videoUrl: string }) {
	const [name, setName] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [successMessage, setSuccessMessage] = useState('')
	const [errorMessage, setErrorMessage] = useState('')

	const [loading, setLoading] = useState(false)

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

	const history = useHistory()

	const widthClassForFormGroup = isTabletOrMobile ? 'w-100' : 'w-49'

	function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()

		setErrorMessage('')
		setSuccessMessage('')
		setLoading(true)

		fetchPost(consts.api_paths.signup_bonus, {
			body: JSON.stringify({
				name,
				email,
				phone,
				concreteCaseId: props.concreteCaseId,
			}),
		})
			.then(() => {
				setSuccessMessage('Conta gerada com sucesso, verifique sua caixa de entrada ou SPAM')
				history.push(appPath(consts.app_paths.login) + '?bonus=' + email + '&bonusCcId=' + props.concreteCaseId, { videoUrl: props.videoUrl })
			})
			.catch(err => setErrorMessage(err))
			.finally(() => setLoading(false))
	}

	return (
		<div className="flex items-center flex-column w-100">
			<h1 style={{ lineHeight: 1.3, textAlign: 'center', maxWidth: 500, width: '100%', margin: 20 }}>Preencha os dados abaixo para gerar seu login e senha em nossa Biblioteca</h1>
			<form className="flex wrap flex-row space-around gap-1p" style={{ maxWidth: 500, width: '100%', textAlign: 'left' }} onSubmit={handleSubmit}>
				{errorMessage && <h3 className="text-danger text-center">{errorMessage}</h3>}
				{successMessage && <h3 className="text-success text-center">{successMessage}</h3>}
				<section className="form-group mb-15 w-100">
					<label htmlFor="name">Nome</label>
					<input
						id="name"
						name="name"
						type="text"
						className="form-control"
						value={name}
						autoFocus
						required
						disabled={loading}
						onChange={event => {
							setName(event.target.value)
						}}
						placeholder="Digite seu nome"
					/>
				</section>
				<section className={'form-group mb-15 ' + widthClassForFormGroup}>
					<label htmlFor="email">E-mail</label>
					<input
						id="email"
						name="email"
						type="email"
						className="form-control"
						value={email}
						required
						disabled={loading}
						onChange={event => {
							setEmail(event.target.value)
						}}
						placeholder="Digite seu email"
					/>
				</section>
				<section className={'form-group mb-15 ' + widthClassForFormGroup}>
					<label htmlFor="email">Fone</label>
					<input
						id="phone"
						name="phone"
						type="text"
						className="form-control"
						value={phone}
						disabled={loading}
						onChange={event => {
							setPhone(event.target.value)
						}}
						placeholder="Digite seu telefone"
					/>
				</section>

				<section className="form-group mb-15 text-center">
					<button type="submit" className="btn btn-danger" style={{ padding: '15px 30px' }}>
						GERAR SENHA DE ACESSO {loading && <LoadingIcon />}
					</button>
				</section>
			</form>
		</div>
	)
}
