import { useEffect, useRef, useState } from 'react'
import alertsObserver, { AlertType } from '../observers/alerts-observer'

export function AlertMessages() {
	const [errors, setErrors] = useState<(string | JSX.Element)[]>([])
	const [warnings, setWarnings] = useState<(string | JSX.Element)[]>([])
	const [successes, setSuccesses] = useState<(string | JSX.Element)[]>([])
	const [fadingClass, setFadingClass] = useState('no-transition')

	// const selfRef = useRef<HTMLDivElement>(null)
	const timeoutFadeRef = useRef<NodeJS.Timeout | null>(null)
	const timeoutClearRef = useRef<NodeJS.Timeout | null>(null)

	const watchFunction = (type: AlertType, message: string | JSX.Element) => {
		timeoutFadeRef.current && clearTimeout(timeoutFadeRef.current)
		timeoutClearRef.current && clearTimeout(timeoutClearRef.current)

		timeoutFadeRef.current = setTimeout(() => {
			// selfRef.current?.classList.add('alert-transition')
			setFadingClass('alert-transition')

			timeoutClearRef.current = setTimeout(() => {
				setErrors([])
				setWarnings([])
				setSuccesses([])
			}, 2000)
		}, 3000)

		setFadingClass('no-transition')

		// selfRef.current?.classList.remove('alert-transition')

		switch (type) {
			case 'error':
				return setErrors(prev => [...prev, message])
			case 'warning':
				return setWarnings(prev => [...prev, message])
			case 'success':
				return setSuccesses(prev => [...prev, message])
		}
	}

	useEffect(() => {
		alertsObserver.subscribe(watchFunction)

		return () => {
			alertsObserver.unsubscribe(watchFunction)
		}
	})

	function renderList(className: string, list: (string | JSX.Element)[], onClick: Function) {
		if (list.length === 0) {
			return null
		}

		return (
			<div className={`alert ${className} flex flex-between`} role="alert" style={{ margin: 5 }}>
				<div>
					{list.map((el, idx) => (
						<p key={idx}>{el}</p>
					))}
				</div>
				<i className="fas fa-times hover-select" style={{ marginLeft: 5 }} onClick={() => onClick()} />
			</div>
		)
	}

	if (errors.length === 0 && warnings.length === 0 && successes.length === 0) {
		return null
	}

	return (
		<section
			// ref={selfRef}
			className={`alerts-wrapper ${fadingClass}`}
			style={{ position: 'fixed', right: 10, top: 70, zIndex: 500, backgroundColor: 'black', padding: 15, maxWidth: '50ch' }}
			onMouseEnter={() => {
				timeoutFadeRef.current && clearTimeout(timeoutFadeRef.current)
				timeoutClearRef.current && clearTimeout(timeoutClearRef.current)
				// selfRef.current?.classList.remove('alert-transition')
				setFadingClass('no-transition')
			}}
			onMouseLeave={() => {
				timeoutFadeRef.current = setTimeout(() => {
					setFadingClass('alert-transition')
					// selfRef.current?.classList.add('alert-transition')

					timeoutClearRef.current = setTimeout(() => {
						setErrors([])
						setWarnings([])
						setSuccesses([])
					}, 2000)
				}, 3000)
			}}
		>
			{renderList('alert-danger', errors, () => {
				setErrors([])
			})}
			{renderList('alert-warning', warnings, () => {
				setWarnings([])
			})}
			{renderList('alert-success', successes, () => {
				setSuccesses([])
			})}
		</section>
	)
}
