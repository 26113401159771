import { CSSProperties } from 'react'
import { TeacherForCarousel } from './components/MainBodyTypes'
import { PaginationState } from './types'

const paginationStateDefault: PaginationState = {
	activePage: 1,
	countPerPage: 10,
	totalCount: 10,
	range: 999,
	filter: '',
}

const imgThumbnailStyle: CSSProperties = {
	height: '100px',
	width: '177px',
	display: 'block',
	border: '1px solid white',
	marginBottom: '5px',
	cursor: 'pointer',
}

const imgProfileSizeStyle: CSSProperties = {
	height: '100px',
	width: '100px',
}

const imgProfileStyle: CSSProperties = {
	...imgProfileSizeStyle,
	display: 'block',
	border: '1px solid white',
	cursor: 'pointer',
	borderRadius: '50%',
}

const EMPTY_TEACHER_FOR_CAROUSEL: TeacherForCarousel = {
	id: 0,
	name: '',
	description: '',
	curriculum: '',
	photo: '',
	facebookLink: '',
	instragramLink: '',
	youtubeLink: '',
	twitterLink: '',
	linkedinLink: '',
	introVideo: '',
}

const app_paths = {
	root: '/',
	login: '/login',
	signup: '/signup',
	signup_hotmart: '/signup-hotmart',
	about: '/about',
	lives: '/lives',
	teachers: '/teachers',
	teachers_id: '/teachers/intro/:id',
	teachers_login: '/teachers/login',
	teachers_signup: '/teachers/signup',
	teachers_signup_hotmart: '/teachers/signup-hotmart',
	teachers_about: '/teachers/about',
	teachers_lives: '/teachers/lives',
	teachers_support: '/teachers/support',
	teachers_recover: '/teachers/recover',
	teachers_profile: '/teachers/profile',
	teachers_concrete_case: '/teachers/concrete-case/:id',
	teachers_concrete_case_login: '/teachers/concrete-case/:id/login',
	bonuses: '/bonuses',
	support: '/support',
	recover: '/recover',
	reset: '/reset',
	profile: '/profile',
	bonus: '/bonus/:path',
	concrete_case: '/concrete-case/:id',
	concrete_case_login: '/concrete-case/:id/login',
	user_profile: '/user/set-profile',

	admin: '/admin',
	admin_users: '/admin/users',
	admin_users_new: '/admin/users/new',
	admin_users_id: '/admin/users/:id',

	admin_categories: '/admin/categories',
	admin_categories_new: '/admin/categories/new',
	admin_categories_id: '/admin/categories/:id',
	admin_teachers: '/admin/teachers',
	admin_teachers_new: '/admin/teachers/new',
	admin_teachers_id: '/admin/teachers/:id',
	admin_concrete_cases: '/admin/concrete-cases',
	admin_concrete_cases_new: '/admin/concrete-cases/new',
	admin_concrete_cases_id: '/admin/concrete-cases/:id',
	admin_bonus_access: '/admin/bonus-access',
	admin_bonus_access_new: '/admin/bonus-access/new',
	admin_bonus_access_id: '/admin/bonus-access/:id',
	admin_system_messages: '/admin/system-messages',
	admin_lives: '/admin/lives',
	admin_lives_id: '/admin/lives/:id',
	admin_lives_new: '/admin/lives/new',
}

const api_paths = {
	login: '/authentication/login',
	logout: '/authentication/logout',
	signup: '/authentication/register',
	signup_bonus: '/users/signup-from-bonus',
	recover: '/authentication/recover',
	reset: '/authentication/reset',
	categories: '/categories',
	categories_for_select: '/categories/for-select',
	teachers: '/teachers',
	teachers_for_select: '/teachers/for-select',
	concrete_cases: '/concrete-cases',
	concrete_cases_for_select: '/concrete-cases/for-select',
	concrete_cases_for_home_page_id: '/concrete-cases/for-home-page',
	bonus_accesses: '/bonus-accesses',
	bonus_accesses_by_path: '/bonus-accesses/by-path',
	bonus_accesses_by_cc_id: '/bonus-accesses/by-cc-id',
	users: '/users',
	users_resend_access_by_id: '/users/resend-access/by-id',
	users_logged_in: '/users/logged-in',
	users_set_profile: '/users/set-profile',
	users_for_select: '/users/for-select',
	users_set_random_password: '/users/set-random-password',
	lives: '/lives',
	system_config: '/system-config',
	all_info_for_app: '/all-info-for-app',
	home_categories: '/categories-for-home-page',
	home_concrete_cases: '/concrete-cases-for-home-page',
	home_lives: '/lives/for-home-page',
	comments: '/comments',
	add_remove_from_my_list: '/concrete-cases/add-remote-from-my-list',
	update_score: '/update-score',
	concrete_case_modules_urls: '/concrete-case-modules-urls',
	contact_info: '/system-config/info',
	teacher_for_corousel: '/teachers/teachers-for-corousel',
	concrete_case_with_teacher: '/concrete-cases/for-corousel',
	system_config_info: '/system-config/info',
	send_email: '/send-email',
	comments_add: '/comments/add',
	concrete_case_update_watch_time: '/concrete-cases/update-watch-time',
	update_live_progress: '/lives/update-progress',
	update_comment_status: '/comments/update-status',
}

const categorySpotlightId = 6
const categoryNewReleasesId = 2
const categoryKeepWatchingId = 3
const categoryMyListId = 4
const categoryPopularId = 5
const categoryConcludedId = 1
const categoryBonusesId = 24
const categoryPendingCommentsId = 25

const consts = {
	API_URL: process.env.NODE_ENV === 'production' ? '/evaed-app/api' : 'http://localhost:5000/evaed-app/api',
	// API_URL: 'https://evaed.org/evaed-app/api',
	APP_URL: '/evaed-app/app',
	HOST: 'https://evaed.org',
	DYNAMIC_FILES_URL: process.env.NODE_ENV === 'production' ? '/evaed-app/app/img' : 'https://evaed.org/evaed-app/app/img',
	EMPTY_TEACHER_FOR_CAROUSEL,
	paginationStateDefault,
	app_paths,
	api_paths,
	version: 21,
	categoriesForUser: [categoryKeepWatchingId, categoryMyListId, categoryConcludedId, categoryBonusesId, categoryPendingCommentsId],
	categoriesFixed: [categorySpotlightId, categoryNewReleasesId, categoryKeepWatchingId, categoryMyListId, categoryPopularId, categoryConcludedId],
	categorySpotlightId,
	categoryNewReleasesId,
	categoryKeepWatchingId,
	categoryMyListId,
	categoryPopularId,
	categoryConcludedId,
	categoryBonusesId,
	categoryPendingCommentsId,
	imgThumbnailStyle,
	imgProfileStyle,
	imgProfileSizeStyle,
	loginModalZIndex: 500,
}

export default consts
