export type ConcreteCase = {
	id: number
	viewCount: number
	description: string
	code: string
	mainCategory_label: string
	date: string
	title: string
	introVideo: string
	urlIsExternal: boolean
	onlyForMembers: boolean
	teacherId?: number
	modules: Module[]
	thumbnail: string
	thumbnailChanged: boolean
	mainCategoryId?: number
	secondaryCategoriesIds: number[]
}

export type Module = {
	id: number
	title: string
	description: string
	descriptionFormatted: string
	number: number
	duration: number
	durationStr: string
	videoUrl: string
	markForDelete: boolean
	thumbnail: string
	thumbnailChanged: boolean
	showDescriptionType: MarkedTextType
}

export type ConcreteCasesSelectType = {
	value: number
	label: string
	title: string
	thumbnail: string
	introVideo: string
}

export enum MarkedTextType {
	Text,
	Preview,
}
