import { marked } from 'marked'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import consts from '../../consts'
import { fetchGet, fetchPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { extractSrcFromIframe } from '../../utils'
import { FormRowTitle } from '../FormRowTitle'
import { SystemConfig } from './SystemConfigFormTypes'

enum MarkedTextType {
	Text,
	Preview,
}

export function SystemConfigForm() {
	const [loading, setLoading] = useState<boolean>(false)
	const [readOnly, setReadOnly] = useState<boolean>(false)
	const [showAboutTextType, setShowAboutTextType] = useState<MarkedTextType>(MarkedTextType.Text)

	const [hotmartId, setHotmartId] = useState<string>('')
	const [hotmartToken, setHotmartToken] = useState<string>('')
	const [welcomeVideoUrl, setWelcomeVideoUrl] = useState<string>('')
	const [facebookLink, setFacebookLink] = useState<string>('')
	const [instragramLink, setInstragramLink] = useState<string>('')
	const [youtubeLink, setYoutubeLink] = useState<string>('')
	const [twitterLink, setTwitterLink] = useState<string>('')
	const [linkedinLink, setLinkedinLink] = useState<string>('')
	const [phoneNumber, setPhoneNumber] = useState<string>('')
	const [whatsappNumber, setWhatsappNumber] = useState<string>('')
	const [openingHoursMessage, setOpeningHoursMessage] = useState<string>('')
	const [aboutVideo, setAboutVideo] = useState('')
	const [aboutText, setAboutText] = useState('')
	const [aboutTextFormatted, setAboutTextFormatted] = useState('')
	const [daysFreeTest, setDaysFreeTest] = useState(0)
	const [hotmartCheckoutLink, setHotmartCheckoutLink] = useState('')
	const [hotmartPremiumLink, setHotmartPremiumLink] = useState('')
	const [machineCode, setMachineCode] = useState(0)
	const [emailSequenceCode, setEmailSequenceCode] = useState(0)
	const [sequenceLevelCode, setSequenceLevelCode] = useState(0)
	const [spotlightVideoUrl, setSpotlightVideoUrl] = useState('')

	useEffect(() => {
		let isMounted = true

		setLoading(true)

		fetchGet<SystemConfig>(consts.api_paths.system_config)
			.then(json => {
				if (!isMounted) return
				setHotmartId(json.hotmartId)
				setHotmartToken(json.hotmartToken)
				setWelcomeVideoUrl(json.welcomeVideoUrl)
				setFacebookLink(json.facebookLink)
				setInstragramLink(json.instragramLink)
				setYoutubeLink(json.youtubeLink)
				setTwitterLink(json.twitterLink)
				setLinkedinLink(json.linkedinLink)
				setPhoneNumber(json.phoneNumber)
				setWhatsappNumber(json.whatsappNumber)
				setOpeningHoursMessage(json.openingHoursMessage)
				setAboutVideo(json.aboutVideo)
				setAboutText(json.aboutText)
				setDaysFreeTest(json.daysFreeTest)
				setHotmartCheckoutLink(json.hotmartCheckoutLink)
				setHotmartPremiumLink(json.hotmartPremiumLink)
				setMachineCode(json.machineCode)
				setEmailSequenceCode(json.emailSequenceCode)
				setSequenceLevelCode(json.sequenceLevelCode)
				setSpotlightVideoUrl(json.spotlightVideoUrl)
			})
			.catch(err => {
				if (!isMounted) return
				alertsObserver.fire('error', `Ao consultar Configurações: ${err}`)
				setReadOnly(true)
			})
			.finally(() => {
				if (!isMounted) return
				setLoading(false)
			})

		return () => {
			isMounted = false
		}
	}, [])

	function handleSaveClick() {
		setLoading(true)

		fetchPut(consts.api_paths.system_config, {
			body: JSON.stringify({
				hotmartId,
				hotmartToken,
				welcomeVideoUrl,
				facebookLink,
				instragramLink,
				youtubeLink,
				twitterLink,
				linkedinLink,
				phoneNumber,
				whatsappNumber,
				openingHoursMessage,
				aboutVideo,
				aboutText,
				daysFreeTest,
				hotmartCheckoutLink,
				hotmartPremiumLink,
				machineCode,
				emailSequenceCode,
				sequenceLevelCode,
				spotlightVideoUrl,
			}),
		})
			.then(data => alertsObserver.fire('success', `Mensagens salvas com sucesso`))
			.catch(err => alertsObserver.fire('error', `Ao salvar mensagens: ${err}`))
			.finally(() => setLoading(false))
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>
			<FormRowTitle id={' '} label="Mensagens do Sistema" loading={loading} allowDeletingButton={false} />
			<h3 className="m-auto">Informações para assinatura premium Hotmart</h3>
			<div className="form-group">
				<label htmlFor="hotmartId">Hotmart ID</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={hotmartId}
					onChange={event => setHotmartId(event.target.value)}
					id="hotmartId"
					name="hotmartId"
					aria-describedby="hotmartId-help"
					placeholder=""
				/>
				{/* <small id="hotmartId-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="hotmartToken">Hotmart Token</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={hotmartToken}
					onChange={event => setHotmartToken(event.target.value)}
					id="hotmartToken"
					name="hotmartToken"
					aria-describedby="hotmartToken-help"
					placeholder=""
				/>
				{/* <small id="hotmartToken-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="welcomeVideoUrl">Url de vídeo de boas vindas</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={welcomeVideoUrl}
					onChange={event => setWelcomeVideoUrl(event.target.value)}
					id="welcomeVideoUrl"
					name="welcomeVideoUrl"
					aria-describedby="welcomeVideoUrl-help"
					placeholder=""
				/>
				{/* <small id="welcomeVideoUrl-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="daysFreeTest">Número de dias de teste grátis</label>
				<input
					type="number"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={daysFreeTest || ''}
					onChange={event => setDaysFreeTest(parseInt(event.target.value))}
					id="daysFreeTest"
					name="daysFreeTest"
					aria-describedby="daysFreeTest-help"
					placeholder=""
				/>
				{/* <small id="daysFreeTest-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="hotmartCheckoutLink">Link de checkout do hotmart (com período gratuito)</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={hotmartCheckoutLink}
					onChange={event => setHotmartCheckoutLink(event.target.value)}
					id="hotmartCheckoutLink"
					name="hotmartCheckoutLink"
					aria-describedby="hotmartCheckoutLink-help"
					placeholder=""
				/>
				{/* <small id="daysFreeTest-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="hotmartPremiumLink">Link assinatura premium do hotmart</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={hotmartPremiumLink}
					onChange={event => setHotmartPremiumLink(event.target.value)}
					id="hotmartPremiumLink"
					name="hotmartPremiumLink"
					aria-describedby="hotmartPremiumLink-help"
					placeholder=""
				/>
				{/* <small id="daysFreeTest-help" className="form-text text-muted"></small> */}
			</div>
			<hr />
			<h3 className="m-auto">
				Informações da máquina <i>padrão</i> LeadLovers
			</h3>
			<hr />
			<div className="form-group">
				<label htmlFor="machineCode">Identificador da Máquina: (ID)</label>
				<input
					type="number"
					className="form-control"
					value={machineCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setMachineCode(event.target.value as any)}
					id="machineCode"
					name="machineCode"
					aria-describedby="machineCode-help"
				/>
			</div>
			<div className="form-group">
				<label htmlFor="emailSequenceCode">Identificador do funil:</label>
				<label id="emailSequenceCode-help" style={{ display: 'block' }} className="form-text text-danger">
					Código extraído ao executar o seguinte código na edição do formulário:
					<br />
					document.querySelector('#cbo-mchstr-funnel').value
					<br />
					<a className="btn text-center text-success" href={consts.DYNAMIC_FILES_URL + '/form-console.png'} target="_blank">
						CLIQUE AQUI PARA VER AS INSTRUÇÕES
					</a>
				</label>
				<input
					type="number"
					className="form-control"
					value={emailSequenceCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmailSequenceCode(event.target.value as any)}
					id="emailSequenceCode"
					name="emailSequenceCode"
					aria-describedby="emailSequenceCode-help"
				/>
			</div>
			<div className="form-group">
				<label htmlFor="sequenceLevelCode">Sequência do funil:</label>
				<label id="sequenceLevelCode-help" style={{ display: 'block' }} className="form-text text-danger">
					Número da sequência do funíl na edição do formulário
				</label>
				<input
					type="number"
					className="form-control"
					value={sequenceLevelCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setSequenceLevelCode(event.target.value as any)}
					id="sequenceLevelCode"
					name="sequenceLevelCode"
					aria-describedby="sequenceLevelCode-help"
				/>
			</div>
			<hr />
			<h3 className="m-auto">Gerais</h3>
			<hr />
			<div className="form-group">
				<label htmlFor="facebookLink">Url de vídeo destaque fixo</label>
				<input
					type="text"
					className="form-control"
					value={spotlightVideoUrl || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setSpotlightVideoUrl(event.target.value)}
					id="spotlightVideoUrl"
					name="spotlightVideoUrl"
					aria-describedby="spotlightVideoUrl-help"
				/>
				{/* <small id="spotlightVideoUrl-help" className="form-text text-muted"></small> */}
			</div>
			<hr />
			<h3 className="m-auto">Redes Sociais</h3>
			<hr />
			<div className="form-group">
				<label htmlFor="facebookLink">Facebook</label>
				<input
					type="text"
					className="form-control"
					value={facebookLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setFacebookLink(event.target.value)}
					id="facebookLink"
					name="facebookLink"
					aria-describedby="facebookLink-help"
				/>
				{/* <small id="facebookLink-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="instragramLink">Instagram</label>
				<input
					type="text"
					className="form-control"
					value={instragramLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setInstragramLink(event.target.value)}
					id="instragramLink"
					name="instragramLink"
					aria-describedby="instragramLink-help"
				/>
				{/* <small id="instragramLink-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="youtubeLink">Youtube</label>
				<input
					type="text"
					className="form-control"
					value={youtubeLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setYoutubeLink(event.target.value)}
					id="youtubeLink"
					name="youtubeLink"
					aria-describedby="youtubeLink-help"
				/>
				{/* <small id="youtubeLink-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="twitterLink">Twitter</label>
				<input
					type="text"
					className="form-control"
					value={twitterLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setTwitterLink(event.target.value)}
					id="twitterLink"
					name="twitterLink"
					aria-describedby="twitterLink-help"
				/>
				{/* <small id="twitterLink-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="linkedinLink">Linkedin</label>
				<input
					type="text"
					className="form-control"
					value={linkedinLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setLinkedinLink(event.target.value)}
					id="linkedinLink"
					name="linkedinLink"
					aria-describedby="linkedinLink-help"
				/>
				{/* <small id="linkedinLink-help" className="form-text text-muted"></small> */}
			</div>
			<h3 className="m-auto">Telefones de Contato</h3>
			<div className="form-group">
				<label htmlFor="phoneNumber">Número de telefone para contato</label>
				<input
					type="text"
					className="form-control"
					value={phoneNumber || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setPhoneNumber(event.target.value)}
					id="phoneNumber"
					name="phoneNumber"
					aria-describedby="phoneNumber-help"
				/>
				{/* <small id="phoneNumber-help" className="form-text text-muted"></small> */}
			</div>
			<div className="form-group">
				<label htmlFor="whatsappNumber">Número de Whatsapp para contato</label>
				<input
					type="text"
					className="form-control"
					value={whatsappNumber || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setWhatsappNumber(event.target.value)}
					id="whatsappNumber"
					name="whatsappNumber"
					aria-describedby="whatsappNumber-help"
				/>
				{/* <small id="whatsappNumber-help" className="form-text text-muted"></small> */}
			</div>
			<h3 className="m-auto">Horários</h3>
			<div className="form-group">
				<label htmlFor="openingHoursMessage">Mensagem sobre horário de atendimento</label>
				<input
					type="text"
					className="form-control"
					value={openingHoursMessage || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setOpeningHoursMessage(event.target.value)}
					id="openingHoursMessage"
					name="openingHoursMessage"
					aria-describedby="openingHoursMessage-help"
				/>
				{/* <small id="openingHoursMessage-help" className="form-text text-muted"></small> */}
			</div>
			<h3 className="m-auto">Página "sobre nós"</h3>
			<div className="form-group">
				<label htmlFor="aboutVideo">Url de vídeo de apresentação do professor no Vimeo</label>
				<label id="aboutVideo-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={aboutVideo || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setAboutVideo(extractSrcFromIframe(event.target.value) || event.target.value)}
					id="aboutVideo"
					name="aboutVideo"
					aria-describedby="aboutVideo-help"
				/>
			</div>
			<div className="form-group">
				<label htmlFor="aboutText">Texto "Sobre"</label>
				<div className="flex gap-sm mb-xs">
					<button type="button" className="btn btn-success" onClick={() => setShowAboutTextType(MarkedTextType.Text)}>
						Texto
					</button>
					<button
						type="button"
						className="btn btn-success"
						onClick={() => {
							setShowAboutTextType(MarkedTextType.Preview)
							setAboutTextFormatted(marked(aboutText || ''))
						}}
					>
						Preview
					</button>
				</div>
				{showAboutTextType === MarkedTextType.Text ? (
					<textarea
						className="form-control"
						value={aboutText || ''}
						required
						disabled={readOnly || loading}
						rows={5}
						onChange={event => setAboutText(event.target.value)}
						id="aboutText"
						name="aboutText"
						aria-describedby="aboutText-help"
					/>
				) : (
					<div className="oh-hi-mark" dangerouslySetInnerHTML={{ __html: aboutTextFormatted }}></div>
				)}

				<small id="aboutText-help" className="form-text text-muted">
					Texto que será exibido na página de Sobre Nós
				</small>
			</div>
			<div>
				<button
					type="button"
					className="btn btn-success"
					disabled={readOnly || loading}
					onClick={() => {
						handleSaveClick()
					}}
				>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
