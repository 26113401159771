import { useState } from 'react'
import { fetchGet } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import { AdminPanelMenuList } from './AdminPanelMenuList'
import { BonusAccessForm } from './cruds/BonusAccessForm'
import { BonusAccessGrid } from './cruds/BonusAccessGrid'
import { CategoryForm } from './cruds/CategoryForm'
import { CategoryGrid } from './cruds/CategoryGrid'
import { ConcreteCaseForm } from './cruds/ConcreteCaseForm'
import { ConcreteCaseGrid } from './cruds/ConcreteCaseGrid'
import { LiveForm } from './cruds/LiveForm'
import { LiveGrid } from './cruds/LiveGrid'
import { SystemConfigForm } from './cruds/SystemConfigForm'
import { TeacherForm } from './cruds/TeacherForm'
import { TeacherGrid } from './cruds/TeacherGrid'
import { UserForm } from './cruds/UserForm'
import { UserGrid } from './cruds/UserGrid'

export function MainBodyAdminPanel() {
	const [showUtils, setShowUtils] = useState(false)

	return (
		<main className="container flex content-center items-center gap-lg flex-column m-center">
			<h1 className="text-primary font-lg" onClick={ev => setShowUtils(ev.altKey && ev.shiftKey)}>
				SISTEMA
			</h1>
			{showUtils && (
				<div className="flex gap-xs">
					<button
						className="btn btn-success"
						onClick={() => {
							fetchGet('/concrete-cases/generate-missing-certificates')
								.then(json => alertsObserver.fire('success', JSON.stringify(json)))
								.catch(err => alertsObserver.fire('error', err))
						}}
					>
						Gerar certificados
					</button>

					<button
						className="btn btn-success"
						onClick={() => {
							fetchGet('/concrete-cases/update-view-count')
								.then(json => alertsObserver.fire('success', JSON.stringify(json)))
								.catch(err => alertsObserver.fire('error', err))
						}}
					>
						Atualizar ViewCount
					</button>

					<button
						className="btn btn-success"
						onClick={() => {
							fetchGet('/generate-compact-images')
								.then(json => alertsObserver.fire('success', JSON.stringify(json)))
								.catch(err => alertsObserver.fire('error', err))
						}}
					>
						Gerar imagens compactadas
					</button>
				</div>
			)}
			<AdminPanelMenuList />
		</main>
	)
}

export function MainBodyAdminPanelCategoryGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-categories" />
			<CategoryGrid />
		</main>
	)
}

export function MainBodyAdminPanelCategoryForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-categories" />
			<CategoryForm />
		</main>
	)
}

export function MainBodyAdminPanelTeacherGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-teachers" />
			<TeacherGrid />
		</main>
	)
}

export function MainBodyAdminPanelTeacherForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-teachers" />
			<TeacherForm />
		</main>
	)
}

export function MainBodyAdminPanelUserGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-users" />
			<UserGrid />
		</main>
	)
}

export function MainBodyAdminPanelUserForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-users" />
			<UserForm />
		</main>
	)
}

export function MainBodyAdminPanelConcreteCaseGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-concrete-cases" />
			<ConcreteCaseGrid />
		</main>
	)
}

export function MainBodyAdminPanelConcreteCaseForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-concrete-cases" />
			<ConcreteCaseForm />
		</main>
	)
}

export function MainBodyAdminPanelBonusAccessGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-bonus-access" />
			<BonusAccessGrid />
		</main>
	)
}

export function MainBodyAdminPanelBonusAccessForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-bonus-access" />
			<BonusAccessForm />
		</main>
	)
}

export function MainBodyAdminPanelSystemMessageCrud() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-system-messages" />
			<SystemConfigForm />
		</main>
	)
}

export function MainBodyAdminPanelLiveGrid() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-lives" />
			<LiveGrid />
		</main>
	)
}

export function MainBodyAdminPanelLiveForm() {
	return (
		<main className="main-admin-panel flex gap-sm">
			<AdminPanelMenuList size="sm" active="crud-lives" />
			<LiveForm />
		</main>
	)
}
