// import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './App'
import { LiteVideoPlayer } from './components/LiteVideoPlayer'

const isLite = window.location.pathname.includes('/lite/')

ReactDOM.render(
	isLite ? (
		<LiteVideoPlayer />
	) : (
		<React.StrictMode>
			<Router>
				<App />
			</Router>
		</React.StrictMode>
	),
	document.getElementById('root')
)

// serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
