import { marked } from 'marked'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import consts from '../../consts'
import { fetchDelete, fetchGet, fetchPostFile, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { defaultSelectStyles, extractSrcFromIframe, secondsToTime, timeToSeconds } from '../../utils'
import { FormRowTitle } from '../FormRowTitle'
import { LoadingIcon } from '../LoadingIcon'
import { ConcreteCase, MarkedTextType, Module } from './ConcreteCaseTypes'

export function ConcreteCaseForm() {
	const imageRefs = useRef<HTMLImageElement[]>([])
	const inputRefs = useRef<HTMLInputElement[]>([])

	const [viewCount, setViewCount] = useState<number>(0)
	const [code, setCode] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [introVideo, setIntroVideo] = useState<string>('')
	const [date, setDate] = useState<string>('')
	const [modules, setModules] = useState<Module[]>([])
	const [teacherId, setTeacherId] = useState<number | undefined>(undefined)
	const [mainCategoryId, setMainCategoryId] = useState<number | undefined>(undefined)
	const [secondaryCategoriesIds, setSecondaryCategoriesIds] = useState<number[]>([])
	const [thumbnail, setThumbnail] = useState<string>('')
	const [urlIsExternal, setUrlIsExternal] = useState(false)
	const [onlyForMembers, setOnlyForMembers] = useState(false)

	const [teachersSelect, setTeachersSelect] = useState<{ value: number; label: string }[]>([])
	const [categoriesSelect, setCategoriesSelect] = useState<{ value: number; label: string }[]>([])
	const [selectedMainCategory, setSelectedMainCategory] = useState<{ value: number; label: string } | undefined>(undefined)
	const [selectedSecondaryCategories, setSelectedSecondaryCategories] = useState<{ value: number; label: string }[]>([])
	const [selectedTeacher, setSelectedTeacher] = useState<{ value: number; label: string } | undefined>(undefined)
	const [thumbnailChanged, setThumbnailChanged] = useState<boolean>(false)

	const [showDescriptionType, setShowDescriptionType] = useState<MarkedTextType>(MarkedTextType.Text)
	const [descriptionFormatted, setDescriptionFormatted] = useState<string>('')

	const thumbnailImgRef = useRef<HTMLImageElement>(null)
	const thumbnailInputRef = useRef<HTMLInputElement>(null)

	const [loading, setLoading] = useState<boolean>(false)
	const [loadingTeachers, setLoadingTeachers] = useState<boolean>(false)
	const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	useEffect(() => {
		let isMounted = true

		function loadSelectTeacher(teacherId?: number) {
			setLoadingTeachers(true)
			fetchGet<{ label: string; value: number }[]>(consts.api_paths.teachers_for_select)
				.then(json => {
					if (!isMounted) return
					setTeachersSelect(json)

					if (teacherId) {
						setSelectedTeacher(json.find(el => el.value === teacherId))
					}
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar select de Professores: ${err}`)
				})
				.finally(() => {
					if (!isMounted) return
					setLoadingTeachers(false)
				})
		}

		function loadSelectCategories(mainCategoryId?: number, secondaryCategoriesIds?: number[]) {
			setLoadingCategories(true)
			fetchGet<[]>(consts.api_paths.categories_for_select)
				.then(json => {
					if (!isMounted) return
					setCategoriesSelect(json)

					if (mainCategoryId) {
						setSelectedMainCategory(json.find((el: any) => el.value === mainCategoryId))
					}

					if (secondaryCategoriesIds && secondaryCategoriesIds.length > 0) {
						setSelectedSecondaryCategories(json.filter((el: any) => secondaryCategoriesIds.find(elId => elId === el.value)))
					}
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar select de Categorias: ${err}`)
				})
				.finally(() => {
					if (!isMounted) return
					setLoadingCategories(false)
				})
		}

		if (id) {
			setLoading(true)

			fetchGet<ConcreteCase>(consts.api_paths.concrete_cases + '/' + id)
				.then(json => {
					if (!isMounted) return
					setCode(json.code)
					setTitle(json.title)
					setDescription(json.description)
					setTeacherId(json.teacherId)
					setIntroVideo(json.introVideo)
					setUrlIsExternal(json.urlIsExternal)
					setOnlyForMembers(json.onlyForMembers)
					setModules(
						json.modules.map(mod => ({
							...mod,
							durationStr: secondsToTime(mod.duration),
							showDescriptionType: MarkedTextType.Text,
						}))
					)
					setDate(json.date)
					setMainCategoryId(json.mainCategoryId)
					setSecondaryCategoriesIds(json.secondaryCategoriesIds)
					setThumbnail(json.thumbnail)

					if (thumbnailImgRef.current && json.thumbnail) {
						thumbnailImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.thumbnail
					}

					;(json.modules as { thumbnail: string }[]).forEach((module, idx) => {
						if (module.thumbnail && imageRefs.current[idx]) {
							imageRefs.current[idx].src = consts.DYNAMIC_FILES_URL + '/' + module.thumbnail
						}
					})

					setTimeout(() => loadSelectTeacher(json.teacherId), 0)
					setTimeout(() => loadSelectCategories(json.mainCategoryId, json.secondaryCategoriesIds), 0)
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Caso Concreto ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		} else {
			loadSelectTeacher()
			loadSelectCategories()
		}

		return () => {
			isMounted = false
		}
	}, [])

	function editModuleField(id: number, field: keyof Module, value: string | number | boolean) {
		setModules(prev =>
			prev.map(el => {
				if (el.id === id) {
					;(el[field] as any) = value
				}

				return el
			})
		)
	}

	function reorderModules() {
		// It's not re-rendering after the changes,
		// sometimes React decides to render after some other changes were made
		//setModules(modules.sort((a, b) => a.number - b.number))
	}

	function handleDeleteClick() {
		setLoading(true)
		setShowDelete(false)

		fetchDelete(consts.api_paths.concrete_cases + '/' + id)
			.then(() => {
				alertsObserver.fire('success', `Caso Concreto ${id} removido com sucesso`)
				setReadOnly(true)
			})
			.catch(err => {
				alertsObserver.fire('error', `Ao deletar Caso Concreto ${id}: ${err}`)
				setShowDelete(true)
			})
			.finally(() => setLoading(false))
	}

	async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		setLoading(true)
		window.scrollTo(0, 0)

		let thumbnailToSave = thumbnail

		if (thumbnailChanged && thumbnailInputRef.current && thumbnailInputRef.current.files && thumbnailInputRef.current.files[0]) {
			const formData = new FormData()

			formData.append('image', thumbnailInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/image/concrete-case/0', {
					body: formData,
				})

				if (json.error) {
					setLoading(false)
					alertsObserver.fire('error', `Ao salvar capa do caso concreto: ${json.message}`)
					return
				}

				thumbnailToSave = json.filename
			} catch (err) {
				setLoading(false)
				alertsObserver.fire('error', `Ao salvar capa do caso concreto: ${err}`)
				return
			}
		}

		let modulesToSave = modules.filter(el => !el.markForDelete)

		const modulePromises = modulesToSave
			.map((module, idx) => {
				if (!module.thumbnailChanged || !inputRefs.current[idx] || !inputRefs.current[idx].files) {
					return null
				}

				const formData = new FormData()

				formData.append('image', (inputRefs.current[idx].files as any)[0])

				return fetchPostFile('/files/image/module/' + module.id, {
					body: formData,
				})
			})
			.filter(el => el)

		if (modulePromises.length > 0) {
			try {
				const jsons = await Promise.all(modulePromises)

				const jsonWithErrors = jsons.filter(el => el?.error)

				if (jsonWithErrors.length > 0) {
					setLoading(false)

					jsonWithErrors.forEach(jsonWithError => {
						alertsObserver.fire('error', `Ao salvar capas dos módulos: ${jsonWithError?.message}`)
					})

					return
				}

				modulesToSave = modulesToSave.map(module => {
					module.thumbnail = jsons.find(json => json?.id === module.id)?.filename || module.thumbnail

					return module
				})
			} catch (err) {
				setLoading(false)
				alertsObserver.fire('error', `Ao salvar capas dos módulos: ${err}`)
				return
			}
		}

		setModules(prev =>
			prev.map(module => ({
				...module,
				thumbnail: modulesToSave.find(el => el.id === module.id)?.thumbnail || module.thumbnail,
			}))
		)

		fetchPostOrPut(consts.api_paths.concrete_cases, id, {
			body: JSON.stringify({
				code,
				title,
				description,
				introVideo,
				urlIsExternal,
				onlyForMembers,
				modules: modulesToSave.map(({ showDescriptionType, descriptionFormatted, durationStr, ...module }) => ({ ...module, duration: timeToSeconds(durationStr) || 0 })),
				teacherId,
				mainCategoryId,
				secondaryCategoriesIds,
				date,
				thumbnail: thumbnailToSave,
			}),
		})
			.then(data => {
				alertsObserver.fire('success', `Caso Concreto ${data.id} salva com sucesso`)

				setThumbnailChanged(false)

				setThumbnail(thumbnailToSave)

				setModules(prev => prev.map(module => ({ ...module, thumbnailChanged: false })))
			})
			.catch(err => alertsObserver.fire('error', `Ao salvar Caso Concreto ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	function handleModuleImageChange(input: HTMLInputElement | null, img: HTMLImageElement | null, changeState: number | Function) {
		if (!input || !img) {
			return
		}

		if (input.files && input.files[0]) {
			const file = new FileReader()

			file.onload = e => {
				img.src = e.target?.result as any

				if (typeof changeState === 'function') {
					changeState(true)
				} else {
					setModules(prev =>
						prev.map(el => {
							if (el.id === changeState) {
								el.thumbnailChanged = true
							}

							return el
						})
					)
				}
			}

			file.readAsDataURL(input.files[0])
		}
	}

	const formIsValid = code && title && description && introVideo && modules && teacherId && mainCategoryId

	return (
		<form className="w-100 flex flex-column wrap gap-sm" onSubmit={handleSubmit}>
			<div>
				<Link to={appPath(consts.app_paths.admin_concrete_cases)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle id={id} label="Caso Concreto" loading={loading} allowDeletingButton={!readOnly && showDelete && !loading} onDeleteConfirmationClick={handleDeleteClick} />

			<div className="form-group">
				<label htmlFor="code">Código</label>
				<input
					type="text"
					className="form-control"
					value={code || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCode(event.target.value)}
					id="code"
					name="code"
					aria-describedby="code-help"
				/>
				<small id="code-help" className="form-text text-muted">
					Ex: PP-01-2019
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="viewCount">Nº de Acessos</label>
				<input type="text" className="form-control" value={viewCount} required disabled id="viewCount" name="viewCount" aria-describedby="viewCount-help" />
				{/* <small id="viewCount-help" className="form-text text-muted">Ex: PP-01-2019</small> */}
			</div>

			<div className="form-group">
				<label htmlFor="title">Título</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={title}
					onChange={event => setTitle(event.target.value)}
					id="title"
					name="title"
					aria-describedby="title-help"
					placeholder=""
				/>
				{/* <small id="title-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="description">Resumo do Caso Concreto</label>
				<div className="flex gap-sm mb-xs">
					<button type="button" className="btn btn-success" onClick={() => setShowDescriptionType(MarkedTextType.Text)}>
						Texto
					</button>
					<button
						type="button"
						className="btn btn-success"
						onClick={() => {
							setShowDescriptionType(MarkedTextType.Preview)
							setDescriptionFormatted(marked(description))
						}}
					>
						Preview
					</button>
				</div>
				{showDescriptionType === MarkedTextType.Text ? (
					<textarea
						className="form-control"
						value={description || ''}
						required
						disabled={readOnly || loading}
						rows={5}
						onChange={event => setDescription(event.target.value)}
						id="description"
						name="description"
						aria-describedby="description-help"
					/>
				) : (
					<div className="oh-hi-mark" dangerouslySetInnerHTML={{ __html: descriptionFormatted }}></div>
				)}

				{/* <input type="text" className="form-control" value={description || ''} onChange={(event) => setdescription(event.target.value)} id="description" name="description" aria-describedby="description-help" /> */}
				{/* <small id="description-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="date">Data</label>
				<input
					type="date"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={date as any}
					onChange={event => {
						setDate(event.target.value as any)
					}}
					id="date"
					name="date"
					aria-describedby="date-help"
					placeholder=""
				/>
				{/* <small id="date-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="teacher">Professor {loadingTeachers ? <LoadingIcon /> : null}</label>
				<Select
					options={teachersSelect}
					value={selectedTeacher}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingTeachers}
					onChange={value => {
						setSelectedTeacher(value || undefined)
						setTeacherId(value?.value)
					}}
					name="teacher"
					id="teacher"
				/>
			</div>

			<div className="form-group">
				<img ref={thumbnailImgRef} style={consts.imgThumbnailStyle} onClick={() => thumbnailInputRef.current?.click()} />
				<label htmlFor="thumbnail">Capa do vídeo</label>
				<input
					ref={thumbnailInputRef}
					type="file"
					accept="image/*"
					className="form-control"
					style={{ display: 'none' }}
					disabled={readOnly || loading}
					onChange={event => handleModuleImageChange(thumbnailInputRef.current, thumbnailImgRef.current, setThumbnailChanged)}
					id="thumbnail"
					name="thumbnail"
					aria-describedby="thumbnail-help"
				/>
				{/* <small id="thumbnail-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="introVideo">Url do vídeo de introdução</label>
				<label id="introVideo-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo
					<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={introVideo || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setIntroVideo(extractSrcFromIframe(event.target.value) || event.target.value)}
					id="introVideo"
					name="introVideo"
					aria-describedby="introVideo-help"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="urlIsExternal">
					<input
						type="checkbox"
						className="form-control"
						checked={urlIsExternal}
						disabled={readOnly || loading}
						onChange={event => setUrlIsExternal(event.target.checked)}
						id="urlIsExternal"
						name="urlIsExternal"
					/>
					<span>URL externa</span>
				</label>
			</div>

			<div className="form-group">
				<label htmlFor="onlyForMembers">
					<input
						type="checkbox"
						className="form-control"
						checked={onlyForMembers}
						disabled={readOnly || loading}
						onChange={event => setOnlyForMembers(event.target.checked)}
						id="onlyForMembers"
						name="onlyForMembers"
					/>
					<span>Exibir apenas para membros</span>
				</label>
			</div>

			<div className="form-group">
				<label htmlFor="mainCategory">Categoria Princial {loadingCategories ? <LoadingIcon /> : null}</label>
				<Select
					options={categoriesSelect.filter(cat => cat.value !== consts.categorySpotlightId)}
					value={selectedMainCategory}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingCategories}
					onChange={value => {
						setSelectedMainCategory(value || undefined)
						setMainCategoryId(value?.value)
					}}
					name="mainCategory"
					id="mainCategory"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="secondaryCategories">Categorias Secundárias {loadingCategories ? <LoadingIcon /> : null}</label>
				<Select
					options={categoriesSelect}
					isMulti={true}
					value={selectedSecondaryCategories}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingCategories}
					onChange={value => {
						setSelectedSecondaryCategories(value as any)
						setSecondaryCategoriesIds(((value || []) as unknown as { value: number }[]).map(el => el.value))
					}}
					name="secondaryCategories"
					id="secondaryCategories"
				/>
			</div>

			<h2 className="m-auto">
				Módulos do Caso Concreto{' '}
				<button
					type="button"
					className="btn btn-success"
					onClick={() =>
						setModules(prev => [
							...prev,
							{
								id: -(prev.length + 1),
								title: '',
								description: '',
								number: prev.length + 1,
								duration: 0,
								durationStr: '00:00',
								videoUrl: '',
								markForDelete: false,
								thumbnail: '',
								thumbnailChanged: false,
								descriptionFormatted: '',
								showDescriptionType: MarkedTextType.Text,
							},
						])
					}
				>
					<i className="fas fa-plus" /> Adicionar módulo
				</button>
			</h2>

			{modules.map((el, idx) => (
				<div key={el.id} className="flex flex-column gap-sm">
					<div className="form-group">
						<label htmlFor={`moduleMarkForDelete[${el.id}]`}>
							<input
								type="checkbox"
								className="form-control"
								checked={el.markForDelete}
								disabled={readOnly || loading}
								onChange={event => editModuleField(el.id, 'markForDelete', event.target.checked)}
								id={`moduleMarkForDelete[${el.id}]`}
								name={`moduleMarkForDelete[${el.id}]`}
							/>
							<span>Marcar para deleção</span>
						</label>
					</div>

					<div className="form-group flex gap-sm">
						<div>
							<label htmlFor={`moduleNumber[${el.id}]`}>Número</label>
							<input
								type="text"
								className="form-control"
								value={el.number || ''}
								required
								disabled={readOnly || loading || el.markForDelete}
								onChange={event => editModuleField(el.id, 'number', event.target.value)}
								onBlur={reorderModules}
								id={`moduleNumber[${el.id}]`}
								name={`moduleNumber[${el.id}]`}
							/>
						</div>

						<div style={{ width: '100%' }}>
							<label htmlFor={`moduleTitle[${el.id}]`}>Título</label>
							<input
								type="text"
								className="form-control"
								value={el.title || ''}
								required
								disabled={readOnly || loading || el.markForDelete}
								onChange={event => editModuleField(el.id, 'title', event.target.value)}
								id={`moduleTitle[${el.id}]`}
								name={`moduleTitle[${el.id}]`}
							/>
						</div>

						<div>
							<label htmlFor={`moduleDuration[${el.id}]`}>Duração (hh:mm:ss)</label>
							<input
								type="time"
								step="1"
								className="form-control"
								value={el.durationStr}
								required
								disabled={readOnly || loading || el.markForDelete}
								onChange={event => editModuleField(el.id, 'durationStr', event.target.value)}
								id={`moduleDuration[${el.id}]`}
								name={`moduleDuration[${el.id}]`}
							/>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor={`moduleThumbnail[${el.id}]`}>Capa do vídeo</label>
						<img ref={imgEl => (imageRefs.current[idx] = imgEl as any)} style={consts.imgThumbnailStyle} />
						<input
							ref={inputEl => (inputRefs.current[idx] = inputEl as any)}
							type="file"
							accept="image/*"
							className="form-control"
							disabled={readOnly || loading || el.markForDelete}
							onChange={event => handleModuleImageChange(inputRefs.current[idx], imageRefs.current[idx], el.id)}
							id={`moduleThumbnail[${el.id}]`}
							name={`moduleThumbnail[${el.id}]`}
							aria-describedby={`moduleThumbnail[${el.id}]-help`}
						/>
						{/* <small id={`moduleThumbnail[${el.id}]-help`} className="form-text text-muted"></small> */}
					</div>

					<div className="form-group">
						<label htmlFor={`moduleVideoUrl[${el.id}]`}>Url do vídeo</label>
						<input
							type="text"
							className="form-control"
							value={el.videoUrl || ''}
							required
							disabled={readOnly || loading || el.markForDelete}
							onChange={event => editModuleField(el.id, 'videoUrl', event.target.value)}
							id={`moduleVideoUrl[${el.id}]`}
							name={`moduleVideoUrl[${el.id}]`}
						/>
					</div>

					<div className="form-group">
						<label htmlFor={`moduleDescription[${el.id}]`}>Descrição</label>
						<div className="flex gap-sm mb-xs">
							<button type="button" className="btn btn-success" onClick={() => editModuleField(el.id, 'showDescriptionType', MarkedTextType.Text)}>
								Texto
							</button>
							<button
								type="button"
								className="btn btn-success"
								onClick={() => {
									editModuleField(el.id, 'showDescriptionType', MarkedTextType.Preview)
									editModuleField(el.id, 'descriptionFormatted', marked(el.description || ''))
								}}
							>
								Preview
							</button>
						</div>
						{el.showDescriptionType === MarkedTextType.Text ? (
							<textarea
								className="form-control"
								value={el.description || ''}
								required
								disabled={readOnly || loading || el.markForDelete}
								rows={5}
								onChange={event => editModuleField(el.id, 'description', event.target.value)}
								id={`moduleDescription[${el.id}]`}
								name={`moduleDescription[${el.id}]`}
							/>
						) : (
							<div className="oh-hi-mark" dangerouslySetInnerHTML={{ __html: el.descriptionFormatted }}></div>
						)}
					</div>
					<hr />
				</div>
			))}

			<div>
				<button type="submit" className="btn btn-success" disabled={readOnly || loading || !formIsValid}>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
