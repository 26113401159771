import { useEffect, useReducer, useState } from 'react'
import { Footer } from './components/Footer'
import { Navbar } from './components/Navbar'
import { NavbarMobile } from './components/NavbarMobile'
import { NavbarMobileTop } from './components/NavbarMobileTop'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import {
	MainBodyAdminPanel,
	MainBodyAdminPanelCategoryForm,
	MainBodyAdminPanelCategoryGrid,
	MainBodyAdminPanelConcreteCaseGrid,
	MainBodyAdminPanelTeacherGrid,
	MainBodyAdminPanelUserGrid,
	MainBodyAdminPanelBonusAccessGrid,
	MainBodyAdminPanelSystemMessageCrud,
	MainBodyAdminPanelUserForm,
	MainBodyAdminPanelLiveGrid,
	MainBodyAdminPanelTeacherForm,
	MainBodyAdminPanelConcreteCaseForm,
	MainBodyAdminPanelBonusAccessForm,
	MainBodyAdminPanelLiveForm,
} from './components/MainBodyAdminPanel'
import { MainBodyConcreteCases } from './components/MainBodyConcreteCases'
import { MainBodyTeachers } from './components/MainBodyTeachers'
import { appPath } from './routes'
import consts from './consts'
import { fetchGet } from './fetch'
import { GlobalContext, LoggedUserInfo, SystemConfigInfo } from './components/GlobalContext'
import { User, UserType } from './components/cruds/UserTypes'
import { AlertMessages } from './components/AlertMessages'
import { Reset } from './components/Reset'
import { VideoPreview } from './components/VideoPreview'
import { ProfileModal } from './components/modals/ProfileModal'
import alertsObserver from './observers/alerts-observer'
import { VideoIntroPreview } from './components/VideoIntroPreview'
import { ConcreteCaseModal } from './components/ConcreteCaseModal'
import { CategoryForHomePage, ConcreteCaseForCarousel, ConcreteCaseForHomePage, LiveForHomePage, LiveRawForHomePage, TeacherForHomePage, UserWatchListForHomePage } from './components/MainBodyTypes'
import { LoginModal } from './components/modals/LoginModal'
import { SuportModal } from './components/SuportModal'
import { AboutModal } from './components/modals/AboutModal'
import { LivesModal } from './components/modals/LivesModal'
import { SignupModal } from './components/modals/SignupModal'
import { RecoverModal } from './components/modals/RecoverModal'
import { BonusAccessModal } from './components/BonusAccessModal'
import { TeacherModal } from './components/TeacherModal'
import { SignupHotmartModal } from './components/modals/SignupHotmartModal'
import { LoadingIcon } from './components/LoadingIcon'
import { LiteVideoPlayer } from './components/LiteVideoPlayer'
import { InstallButton } from './InstallButton'
import { Bonuses } from './components/Bonuses'
import './App.css'

export function App() {
	const [user, setUser] = useState<LoggedUserInfo | null>(null)
	const [loadingLoggedUser, setLoadingLoggedUser] = useState(true)
	const [loadingAll, setLoadingAll] = useState(false)
	const [systemConfig, setSystemConfig] = useState<SystemConfigInfo>({
		phoneNumber: '',
		whatsappNumber: '',
		openingHoursMessage: '',
		aboutVideo: '',
		aboutText: '',
		facebookLink: '',
		instragramLink: '',
		youtubeLink: '',
		twitterLink: '',
		linkedinLink: '',
		daysFreeTest: 0,
		hotmartCheckoutLink: '',
		hotmartPremiumLink: '',
		spotlightVideoUrl: '',
	})

	const [filter, setFilter] = useState('')
	const [rawCategories, setRawCategories] = useState<CategoryForHomePage[]>([])
	const [rawConcreteCases, setRawConcreteCases] = useState<ConcreteCaseForHomePage[]>([])
	const [rawTeachers, setRawTeachers] = useState<TeacherForHomePage[]>([])
	const [rawBonuses, setRawBonuses] = useState<number[]>([])
	const [rawUserWatchList, setRawUserWatchList] = useState<UserWatchListForHomePage[]>([])
	const [rawLives, setRawLives] = useState<LiveRawForHomePage[]>([])
	const [concreteCasesMasterList, setConcreteCasesMasterList] = useState<ConcreteCaseForCarousel[]>([])
	const [livesMasterList, setLivesMasterList] = useState<LiveForHomePage[]>([])
	const [forceHomePageUpdate, setForceHomePageUpdate] = useReducer(x => x + 1, 0)

	const history = useHistory()

	useEffect(() => {
		function changeBodyOverflowY() {
			if (
				window.location.pathname.endsWith(consts.APP_URL) ||
				window.location.pathname.endsWith(consts.app_paths.teachers + '/') ||
				window.location.pathname.endsWith(consts.app_paths.teachers) ||
				window.location.pathname.endsWith(consts.APP_URL + '/') ||
				window.location.pathname.includes(consts.app_paths.admin)
			) {
				document.body.style.overflowY = 'auto'
			} else {
				document.body.style.overflowY = 'hidden'
			}
		}

		const unsubscribe = history.listen(changeBodyOverflowY)

		changeBodyOverflowY()

		return unsubscribe
	}, [filter])

	useEffect(() => {
		setLoadingLoggedUser(true)

		fetchGet<User>(consts.api_paths.users_logged_in)
			.then(user => setUser(user.id ? user : null))
			.catch(err => alertsObserver.fire('error', 'Ao logar: ' + err))
			.finally(() => setLoadingLoggedUser(false))

		fetchGet<SystemConfigInfo>(consts.api_paths.system_config_info)
			.then(json => setSystemConfig(json))
			.catch(err => alertsObserver.fire('error', 'Ao carregar info: ' + err))

		try {
			const savedCategories: [] = JSON.parse((window.localStorage.getItem('rawCategories') as any) || [])
			const savedConcreteCases: [] = JSON.parse((window.localStorage.getItem('rawConcreteCases') as any) || [])
			const savedLives: [] = JSON.parse((window.localStorage.getItem('rawLives') as any) || [])
			const savedTeachers: [] = JSON.parse((window.localStorage.getItem('rawTeachers') as any) || [])
			const savedUserWatchList: [] = JSON.parse((window.localStorage.getItem('rawUserWatchList') as any) || [])

			setRawCategories(savedCategories)
			setRawConcreteCases(savedConcreteCases)
			setRawTeachers(savedTeachers)
			setRawUserWatchList(savedUserWatchList)
			setRawLives(savedLives)

			buildConcreteCasesMasterList(savedCategories, savedConcreteCases, savedTeachers, savedUserWatchList)
			buildLivesMasterList(savedLives, savedUserWatchList)
		} catch (e) {}

		// function handleKeyDown(e: KeyboardEvent) {
		// 	if (e.key === 'Escape') {
		// 		e.preventDefault()

		// 		if (window.location.pathname.indexOf('/profile') > 0 && user?.passwordPlainText) {
		// 			//alert('Crie uma nova senha!')
		// 		} else {
		// 			navigateBackFromModal(history)
		// 		}
		// 	}
		// }

		// document.addEventListener('keydown', handleKeyDown)

		navigator?.serviceWorker?.getRegistrations().then(registrations => {
			for (let registration of registrations) {
				registration.unregister().then(console.log)
			}
		})

		return () => {
			// document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	useEffect(() => {
		setLoadingAll(true)

		fetchGet<{
			categories: CategoryForHomePage[]
			concreteCases: ConcreteCaseForHomePage[]
			teachers: TeacherForHomePage[]
			userWatchList: UserWatchListForHomePage[]
			lives: LiveRawForHomePage[]
			bonuses: number[]
		}>(consts.api_paths.all_info_for_app)
			.then(json => {
				setRawCategories(json.categories)
				setRawConcreteCases(json.concreteCases)
				setRawTeachers(json.teachers)
				setRawUserWatchList(json.userWatchList)
				setRawLives(json.lives)
				setRawBonuses(json.bonuses)

				try {
					window.localStorage.setItem('rawCategories', JSON.stringify(json.categories))
					window.localStorage.setItem('rawConcreteCases', JSON.stringify(json.concreteCases))
					window.localStorage.setItem('rawTeachers', JSON.stringify(json.teachers))
					window.localStorage.setItem('rawUserWatchList', JSON.stringify(json.userWatchList))
					window.localStorage.setItem('rawlives', JSON.stringify(json.lives))
				} catch (e) {}

				buildConcreteCasesMasterList(json.categories, json.concreteCases, json.teachers, json.userWatchList)
				buildLivesMasterList(json.lives, json.userWatchList)
			})
			.catch(err => alertsObserver.fire('error', 'Ao carregar tudo: ' + err))
			.finally(() => setLoadingAll(false))
	}, [user, forceHomePageUpdate])

	function buildConcreteCasesMasterList(categories: CategoryForHomePage[], concreteCases: ConcreteCaseForHomePage[], teachers: TeacherForHomePage[], userWatchList: UserWatchListForHomePage[]) {
		setConcreteCasesMasterList(
			concreteCases.map(cc => {
				const userWatchInfoConcreteCase = userWatchList.find(watch => watch.concreteCaseId === cc.id)
				const teacher = teachers.find(t => t.id === cc.teacherId)

				return {
					...cc,
					category: categories.find(cat => cat.id === cc.mainCategoryId) || { id: 0, label: '', order: 0, sortType: 0 },
					userWatchInfo: userWatchInfoConcreteCase,
					teacher: teacher || consts.EMPTY_TEACHER_FOR_CAROUSEL,
					modules: cc.modules.map(mod => {
						const userWatchInfoModule = userWatchList.find(watch => watch.moduleId === mod.id)

						return {
							id: mod.id,
							title: mod.title,
							description: mod.description,
							thumbnail: mod.thumbnail,
							thumbnailSm: mod.thumbnailSm,
							duration: mod.duration,
							durationTotal: 0,
							number: mod.number,
							videoUrl: undefined,
							userWatchInfo: userWatchInfoModule,
						}
					}),
				}
			})
		)
	}

	function buildLivesMasterList(lives: LiveRawForHomePage[], userWatchList: UserWatchListForHomePage[]) {
		setLivesMasterList(lives.map(live => ({ ...live, userWatchInfo: userWatchList.find(watch => watch.liveId === live.id) })))
	}

	// // const profileMatch = matchPath(history.location.pathname, { path: consts.app_paths.profile }) || matchPath(history.location.pathname, consts.app_paths.teachers_profile)
	// const profileMatch = window.location.pathname.indexOf('/profile') > 0

	// if (user && window.location.pathname.indexOf('/login') > 0) {
	// 	// return (
	// 	// 	<Redirect to={appPath(consts.app_paths.root)} />
	// 	// )
	// 	history.replace({ pathname: appPath(consts.app_paths.root) })
	// }

	if (window.location.pathname.includes('/lite/')) {
		return <LiteVideoPlayer />
	}

	return (
		<GlobalContext.Provider
			value={{
				user,
				setUser,
				systemConfig,
				setSystemConfig,
				filter,
				setFilter,
				rawCategories,
				setRawCategories,
				rawConcreteCases,
				setRawConcreteCases,
				rawTeachers,
				setRawTeachers,
				rawUserWatchList,
				setRawUserWatchList,
				forceHomePageUpdate,
				setForceHomePageUpdate,
				loadingAll,
				setLoadingAll,
				loadingLoggedUser,
				setLoadingLoggedUser,
				concreteCasesMasterList,
				setConcreteCasesMasterList,
				rawLives,
				setRawLives,
				livesMasterList,
				setLivesMasterList,
				rawBonuses,
				setRawBonuses,
			}}
		>
			{/* <Router> */}
			<Navbar />
			<NavbarMobileTop />
			<AlertMessages />
			<InstallButton />
			<VideoPreview />

			{/* {loadingLoggedUser ? (
				<LoadingIcon />
			) : user?.passwordPlainText && !profileMatch ? (
				<>
					<Redirect to={appPath(consts.app_paths.profile)} />
				</>
			) : ( */}
			{false && user?.passwordPlainText ? (
				<>
					<Redirect to={appPath(consts.app_paths.profile)} />
				</>
			) : (
				<Switch>
					<Route path={appPath(consts.app_paths.reset)} exact>
						<Reset />
					</Route>
					<Route path={appPath(consts.app_paths.admin)}>
						{loadingLoggedUser ? (
							<LoadingIcon />
						) : user?.type !== UserType.Admin ? (
							<Redirect to={appPath(consts.app_paths.login)} />
						) : (
							<Switch>
								<Route path={appPath(consts.app_paths.admin)} exact>
									<MainBodyAdminPanel />
								</Route>
								<Route path={appPath(consts.app_paths.admin_users)} exact>
									<MainBodyAdminPanelUserGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_users_new)} exact>
									<MainBodyAdminPanelUserForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_users_id)} exact>
									<MainBodyAdminPanelUserForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_categories)} exact>
									<MainBodyAdminPanelCategoryGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_categories_new)} exact>
									<MainBodyAdminPanelCategoryForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_categories_id)} exact>
									<MainBodyAdminPanelCategoryForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_teachers)} exact>
									<MainBodyAdminPanelTeacherGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_teachers_id)} exact>
									<MainBodyAdminPanelTeacherForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_concrete_cases)} exact>
									<MainBodyAdminPanelConcreteCaseGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_concrete_cases_new)} exact>
									<MainBodyAdminPanelConcreteCaseForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_concrete_cases_id)} exact>
									<MainBodyAdminPanelConcreteCaseForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_bonus_access)} exact>
									<MainBodyAdminPanelBonusAccessGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_bonus_access_new)} exact>
									<MainBodyAdminPanelBonusAccessForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_bonus_access_id)} exact>
									<MainBodyAdminPanelBonusAccessForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_system_messages)} exact>
									<MainBodyAdminPanelSystemMessageCrud />
								</Route>
								<Route path={appPath(consts.app_paths.admin_lives)} exact>
									<MainBodyAdminPanelLiveGrid />
								</Route>
								<Route path={appPath(consts.app_paths.admin_lives_new)} exact>
									<MainBodyAdminPanelLiveForm />
								</Route>
								<Route path={appPath(consts.app_paths.admin_lives_id)} exact>
									<MainBodyAdminPanelLiveForm />
								</Route>
							</Switch>
						)}
					</Route>
					<Route path={appPath(consts.app_paths.teachers)}>
						<MainBodyTeachers />

						<Route path={appPath(consts.app_paths.teachers_about)} exact>
							<AboutModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_lives)} exact>
							<LivesModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_support)} exact>
							<SuportModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_login)} exact>
							<LoginModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_signup)} exact>
							<SignupModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_signup_hotmart)} exact>
							<SignupHotmartModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_recover)} exact>
							<RecoverModal />
						</Route>
						<Route path={appPath(consts.app_paths.teachers_profile)} exact>
							<ProfileModal />
						</Route>

						<Switch>
							<Route path={appPath(consts.app_paths.teachers_concrete_case)}>
								<ConcreteCaseModal />

								<Route path={appPath(consts.app_paths.teachers_concrete_case_login)}>
									<LoginModal />
								</Route>
							</Route>
							<Route path={appPath(consts.app_paths.teachers_id)}>
								<TeacherModal />
							</Route>
							<Route>
								{/* <VideoPreview /> */}
								<VideoIntroPreview />
							</Route>
						</Switch>
					</Route>
					<Route path={appPath(consts.app_paths.bonuses)}>
						<Bonuses />
					</Route>
					<Route path={appPath(consts.app_paths.root)}>
						<MainBodyConcreteCases />

						<Route path={appPath(consts.app_paths.about)} exact>
							<AboutModal />
						</Route>
						<Route path={appPath(consts.app_paths.lives)} exact>
							<LivesModal />
						</Route>
						<Route path={appPath(consts.app_paths.support)} exact>
							<SuportModal />
						</Route>
						<Route path={appPath(consts.app_paths.login)} exact>
							<LoginModal />
						</Route>
						<Route path={appPath(consts.app_paths.signup)} exact>
							<SignupModal />
						</Route>
						<Route path={appPath(consts.app_paths.signup_hotmart)} exact>
							<SignupHotmartModal />
						</Route>
						<Route path={appPath(consts.app_paths.recover)} exact>
							<RecoverModal />
						</Route>
						<Route path={appPath(consts.app_paths.bonus)} exact>
							<BonusAccessModal />
						</Route>
						<Route path={appPath(consts.app_paths.profile)} exact>
							<ProfileModal />
						</Route>

						<Switch>
							<Route path={appPath(consts.app_paths.concrete_case)}>
								<ConcreteCaseModal />

								<Route path={appPath(consts.app_paths.concrete_case_login)}>
									<LoginModal />
								</Route>
							</Route>
							<Route>{/* <VideoPreview /> */}</Route>
						</Switch>
					</Route>
					<Route>
						<Redirect to={appPath(consts.app_paths.root)} />
					</Route>
				</Switch>
			)}
			<Footer />
			<NavbarMobile />
			{/* </Router> */}
		</GlobalContext.Provider>
	)
}
