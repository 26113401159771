import { CSSProperties, useEffect, useState } from 'react'
import { LoadingIcon } from './LoadingIcon'

export type FormRowTitleProps = {
	id?: string | undefined
	label: string
	loading: boolean
	allowDeletingButton?: boolean
	onDeleteConfirmationClick?: Function
}

const deleteIconStyle: CSSProperties = {
	display: 'inline-block',
	width: '4ch',
}

const secondsToDelete = 4

export function FormRowTitle(props: FormRowTitleProps) {
	const [deleting, setDeleting] = useState<boolean>(false)
	const [secondsToEnableButton, setSecondsToEnableButton] = useState<number>(secondsToDelete)

	useEffect(() => {
		let isMounted = true

		if (deleting && secondsToEnableButton > 0) {
			setTimeout(() => {
				if (!isMounted) {
					return
				}

				setSecondsToEnableButton(prev => prev - 1)
			}, 1000)
		}

		return () => {
			isMounted = false
		}
	}, [secondsToEnableButton])

	function renderDeletingConfirmation() {
		return (
			<div className="flex wrap gap-sm items-center">
				<button
					type="button"
					className="btn btn-danger"
					disabled={secondsToEnableButton > 0}
					onClick={() => {
						if (props.onDeleteConfirmationClick) props.onDeleteConfirmationClick()
					}}
				>
					{secondsToEnableButton > 0 ? <span style={deleteIconStyle}>({secondsToEnableButton})</span> : <i className="fas fa-skull" style={deleteIconStyle}></i>} CONFIRMAR{' '}
					{secondsToEnableButton > 0 ? <span style={deleteIconStyle}>({secondsToEnableButton})</span> : <i className="fas fa-skull" style={deleteIconStyle}></i>}
				</button>
				<span>Deseja realmente deletar?</span>
				<button
					type="button"
					className="btn btn-success"
					onClick={() => {
						setDeleting(false)
						setSecondsToEnableButton(secondsToDelete)
					}}
				>
					CANCELAR DELEÇÃO
				</button>
			</div>
		)
	}

	if (props.id) {
		return (
			<div className="flex wrap gap-sm space-between">
				<h1>
					<span>
						Editando {props.label} {props.id}{' '}
					</span>
					{props.loading ? <LoadingIcon /> : null}
				</h1>

				{deleting && props.allowDeletingButton ? renderDeletingConfirmation() : null}

				{!deleting && props.allowDeletingButton ? (
					<button
						type="button"
						className="btn btn-danger"
						disabled={props.loading}
						onClick={() => {
							setDeleting(true)
							setSecondsToEnableButton(prev => prev - 1)
						}}
					>
						<strong>Deletar</strong> | <i className="fas fa-trash-alt" />
					</button>
				) : null}
			</div>
		)
	}

	return (
		<h1>
			<span>Adicionando {props.label} </span>
			{props.loading ? <LoadingIcon /> : null}
		</h1>
	)
}
