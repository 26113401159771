import { TeacherForCarousel } from "../components/MainBodyTypes"

export type VideoIntroPreviewObserverFunction = (teacher: TeacherForCarousel, videoWrapper: HTMLDivElement) => void

class VideoIntroPreviewObserver {
	private observers: VideoIntroPreviewObserverFunction[] = []

	subscribe(fn: VideoIntroPreviewObserverFunction) {
		this.observers.push(fn)
	}

	unsubscribe(callback: VideoIntroPreviewObserverFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fire(teacher: TeacherForCarousel, videoWrapper: HTMLDivElement) {
		this.observers.forEach(fn => {
			fn(teacher, videoWrapper)
		})
	}
}

const videoIntroPreviewObserver = new VideoIntroPreviewObserver()

export default videoIntroPreviewObserver
