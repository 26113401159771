import { marked } from 'marked'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import consts from '../../consts'
import { fetchDelete, fetchGet, fetchPostFile, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { createEditLink, defaultSelectStyles, extractSrcFromIframe } from '../../utils'
import { FormRowTitle } from '../FormRowTitle'
import { LoadingIcon } from '../LoadingIcon'
import { PersonType, PersonTypeDesc, Teacher } from './TeacherTypes'

enum ShowCurricilumType {
	Text,
	Preview,
}

export function TeacherForm() {
	const [loading, setLoading] = useState<boolean>(false)
	const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)

	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [facebookLink, setFacebookLink] = useState<string>('')
	const [instragramLink, setInstragramLink] = useState<string>('')
	const [youtubeLink, setYoutubeLink] = useState<string>('')
	const [twitterLink, setTwitterLink] = useState<string>('')
	const [linkedinLink, setLinkedinLink] = useState<string>('')
	const [photo, setPhoto] = useState<string>('')
	const [order, setOrder] = useState<number | undefined>(undefined)
	const [curriculum, setCurriculum] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [emailHotmart, setEmailHotmart] = useState<string>('')
	const [passwordHotmart, setPasswordHotmart] = useState<string>('')
	const [country, setCountry] = useState<string>('')
	const [cep, setCep] = useState<string>('')
	const [state, setState] = useState<string>('')
	const [city, setCity] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [number, setNumber] = useState<string>('')
	const [complement, setComplement] = useState<string>('')
	const [contract, setContract] = useState<string>('')
	const [personType, setPersonType] = useState<PersonType>(PersonType.Natural)
	const [companyName, setCompanyName] = useState<string>('')
	const [cnpj, setCnpj] = useState<string>('')
	const [pix, setPix] = useState<string>('')
	const [bankCode, setBankCode] = useState<string>('')
	const [bankAgency, setBankAgency] = useState<string>('')
	const [bankAccount, setBankAccount] = useState<string>('')
	const [obs, setObs] = useState<string>('')
	const [introVideo, setintroVideo] = useState<string>('')
	const [userId, setUserId] = useState<number | undefined>(undefined)

	const [usersSelect, setUsersSelect] = useState<{ value: number; label: string }[]>([])
	const [selectedUser, setSelectedUser] = useState<{ value: number; label: string } | undefined>(undefined)

	const [imageChanged, setImageChanged] = useState<boolean>(false)
	const [contractChanged, setContractChanged] = useState<boolean>(false)
	const [showCurriculumType, setShowCurriculumType] = useState<ShowCurricilumType>(ShowCurricilumType.Text)
	const [curriculumFormatted, setCurriculumFormatted] = useState<string>('')

	const photoImgRef = useRef<HTMLImageElement>(null)
	const photoInputRef = useRef<HTMLInputElement>(null)
	const contractInputRef = useRef<HTMLInputElement>(null)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	function loadSelectUsers(userId?: number) {
		setLoadingUsers(true)
		fetchGet<[]>(consts.api_paths.users_for_select)
			.then(json => {
				setUsersSelect(json)

				if (userId) {
					setSelectedUser(json.find((el: any) => el.value === userId))
				}
			})
			.catch(err => alertsObserver.fire('error', `Ao consultar select de Usuários: ${err}`))
			.finally(() => setLoadingUsers(false))
	}

	useEffect(() => {
		let isMounted = true

		if (id) {
			setLoading(true)

			fetchGet<Teacher>(consts.api_paths.teachers + '/' + id)
				.then(json => {
					if (!isMounted) return

					assignObjectToState(json)

					if (photoImgRef.current && json.photo) {
						photoImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.photo
					}

					setTimeout(() => loadSelectUsers(json.userId), 0)
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Professor ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		} else {
			loadSelectUsers()
		}

		return () => {
			isMounted = false
		}
	}, [])

	function assignObjectToState(obj: Teacher) {
		setName(obj.name)
		setPhoto(obj.photo)
		setDescription(obj.description)
		setFacebookLink(obj.facebookLink)
		setInstragramLink(obj.instragramLink)
		setYoutubeLink(obj.youtubeLink)
		setTwitterLink(obj.twitterLink)
		setLinkedinLink(obj.linkedinLink)
		setOrder(obj.order)
		setCurriculum(obj.curriculum)
		setPhone(obj.phone)
		setEmail(obj.email)
		setEmailHotmart(obj.emailHotmart)
		setPasswordHotmart(obj.passwordHotmart)
		setCountry(obj.country)
		setCep(obj.cep)
		setState(obj.state)
		setCity(obj.city)
		setAddress(obj.address)
		setNumber(obj.number)
		setComplement(obj.complement)
		setContract(obj.contract)
		setPersonType(obj.personType)
		setCompanyName(obj.companyName)
		setCnpj(obj.cnpj)
		setPix(obj.pix)
		setBankCode(obj.bankCode)
		setBankAgency(obj.bankAgency)
		setBankAccount(obj.bankAccount)
		setintroVideo(obj.introVideo)
		setObs(obj.obs)
		setUserId(obj.userId)
	}

	function handleDeleteClick() {
		setLoading(true)
		setShowDelete(false)

		fetchDelete(consts.api_paths.teachers + '/' + id)
			.then(() => {
				alertsObserver.fire('success', `Professor ${id} removido com sucesso`)
				setReadOnly(true)
			})
			.catch(err => {
				alertsObserver.fire('error', `Ao deletar Professor ${id}: ${err}`)
				setShowDelete(true)
			})
			.finally(() => setLoading(false))
	}

	async function handleSaveClick() {
		setLoading(true)

		let newPhoto = photo
		let newContract = contract

		if (imageChanged && photoInputRef.current && photoInputRef.current.files) {
			const formData = new FormData()

			formData.append('image', photoInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/image/teacher/0', {
					body: formData,
				})

				alertsObserver.fire('success', 'Imagem enviada com sucesso')

				setImageChanged(false)

				newPhoto = json.filename

				setPhoto(newPhoto)
			} catch (err) {
				alertsObserver.fire('error', `Ao fazer upload da imagem: ${err}`)
				setLoading(false)
				return
			}
		}

		if (contractChanged && contractInputRef.current && contractInputRef.current.files) {
			const formData = new FormData()

			formData.append('pdf', contractInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/contract/0', {
					body: formData,
				})

				alertsObserver.fire('success', 'Contrato enviado com sucesso')

				setContractChanged(false)

				newContract = json.filename

				setContract(newContract)
			} catch (err) {
				alertsObserver.fire('error', `Ao fazer upload do contrato: ${err}`)
				setLoading(false)
				return
			}
		}

		fetchPostOrPut<Teacher>(consts.api_paths.teachers, id, {
			body: JSON.stringify({
				name,
				description,
				facebookLink,
				instragramLink,
				youtubeLink,
				twitterLink,
				linkedinLink,
				order,
				curriculum,
				phone,
				email,
				emailHotmart,
				passwordHotmart,
				country,
				cep,
				state,
				photo: newPhoto || undefined,
				city,
				address,
				number,
				complement,
				contract: newContract,
				personType,
				companyName,
				cnpj,
				pix,
				bankCode,
				bankAgency,
				bankAccount,
				obs,
				introVideo,
				userId,
			}),
		})
			.then(data => {
				alertsObserver.fire(
					'success',
					id ? `Professor ${data.id} salvo com sucesso` : createEditLink(`Professor ${data.id} salvo com sucesso`, appPath(consts.app_paths.admin_teachers_id, { id: data.id }))
				)
				assignObjectToState(data)

				loadSelectUsers(data.userId)
			})
			.catch(err => alertsObserver.fire('error', `Ao salvar Professor ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	function handleIntroVideoChange(value: string) {
		setintroVideo(extractSrcFromIframe(value) || value)
	}

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files[0]) {
			const file = new FileReader()

			file.onload = function (e) {
				if (photoImgRef.current) {
					photoImgRef.current.src = e.target?.result as any
				}
			}

			file.readAsDataURL(event.target.files[0])

			setImageChanged(true)
		}
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin_teachers)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle id={id} label="Professor" loading={loading} allowDeletingButton={!readOnly && showDelete && !loading} onDeleteConfirmationClick={handleDeleteClick} />

			<div className="form-group">
				<img ref={photoImgRef} style={consts.imgProfileStyle} onClick={() => photoInputRef.current?.click()} />
				<label htmlFor="photo">Foto</label>
				<input
					ref={photoInputRef}
					type="file"
					style={{ display: 'none' }}
					accept="image/*"
					className="form-control"
					required
					disabled={readOnly || loading}
					onChange={handleImageChange}
					id="photo"
					name="photo"
					aria-describedby="photo-help"
				/>
				{/* <small id="photo-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="user">
					Usuário{' '}
					{loadingUsers ? (
						<LoadingIcon />
					) : userId ? (
						<Link target="_blank" className="btn btn-link" to={appPath(consts.app_paths.admin_users_id, { id: userId })}>
							Editar usuário
						</Link>
					) : null}
				</label>
				<Select
					placeholder="Deixe em branco para criar um novo usuário"
					isClearable
					options={usersSelect}
					value={selectedUser}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingUsers}
					onChange={value => {
						setSelectedUser(value || undefined)
						setUserId(value?.value)
					}}
					name="user"
					id="user"
				/>
				{/* <small id="user-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="photo">
					Contrato{' '}
					{contract ? (
						<>
							<span> </span>
							<a className="btn btn-link" href={`${consts.DYNAMIC_FILES_URL}/${contract}`} target="_blank">
								Ver contrato atual
							</a>
						</>
					) : null}
				</label>
				<input
					ref={contractInputRef}
					type="file"
					accept="application/pdf"
					className="form-control"
					required
					disabled={readOnly || loading}
					onChange={event => setContractChanged(true)}
					id="photo"
					name="photo"
					aria-describedby="photo-help"
				/>
				<small id="photo-help" className="form-text text-muted">
					{!id ? 'Necessário salvar antes de conseguir editar contrato' : ''}
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="order">Ordem de apresentação do Professor</label>
				<input
					type="text"
					className="form-control"
					value={order || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setOrder(event.target.value ? parseInt(event.target.value) || undefined : undefined)}
					id="order"
					name="order"
					aria-describedby="order-help"
					placeholder="Deixe vazio para que seja o último"
				/>
				<small id="order-help" className="form-text text-muted">
					Este campo afetará a ordem que serão exibidas as Professores na página de professores
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="name">Nome do Professor</label>
				<input
					type="text"
					className="form-control"
					value={name || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setName(event.target.value)}
					id="name"
					name="name"
					aria-describedby="name-help"
				/>
				{/* <small id="name-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="description">Titulação</label>
				<input
					type="text"
					className="form-control"
					value={description || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setDescription(event.target.value)}
					id="description"
					name="description"
					aria-describedby="description-help"
				/>
				<small id="description-help" className="form-text text-muted">
					Ex: Ministro do Tribunal Superior do Trabalho
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="phone">Telefone</label>
				<input
					type="phone"
					className="form-control"
					value={phone || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setPhone(event.target.value)}
					id="phone"
					name="phone"
					aria-describedby="phone-help"
				/>
				{/* <small id="phone-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="email">E-mail Pessoal</label>
				<input
					type="email"
					className="form-control"
					value={email || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmail(event.target.value)}
					id="email"
					name="email"
					aria-describedby="email-help"
				/>
				{/* <small id="email-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="emailHotmart">E-mail Hotmart</label>
				<input
					type="email"
					className="form-control"
					value={emailHotmart || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmailHotmart(event.target.value)}
					id="emailHotmart"
					name="emailHotmart"
					aria-describedby="emailHotmart-help"
				/>
				{/* <small id="emailHotmart-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="passwordHotmart">Senha Hotmart</label>
				<input
					type="text"
					className="form-control"
					value={passwordHotmart || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setPasswordHotmart(event.target.value)}
					id="passwordHotmart"
					name="passwordHotmart"
					aria-describedby="passwordHotmart-help"
				/>
				{/* <small id="passwordHotmart-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="personType">Tipo de pessoa (para recebimento de pagamentos)</label>
				<select name="personType" id="personType" value={personType} className="form-control" required disabled={readOnly || loading} onChange={event => setPersonType(parseInt(event.target.value))}>
					<option value={PersonType.Natural}>{PersonTypeDesc[PersonType.Natural]}</option>
					<option value={PersonType.Juridical}>{PersonTypeDesc[PersonType.Juridical]}</option>
				</select>
				<small id="personType-help" className="form-text text-muted">
					Campo informativo, utilizado para identificação de pagamento
				</small>
			</div>

			<div className="form-group" style={personType === PersonType.Juridical ? {} : { display: 'none' }}>
				<label htmlFor="companyName">Razão social</label>
				<input
					type="text"
					className="form-control"
					value={companyName || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCompanyName(event.target.value)}
					id="companyName"
					name="companyName"
					aria-describedby="companyName-help"
				/>
				{/* <small id="companyName-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group" style={personType === PersonType.Juridical ? {} : { display: 'none' }}>
				<label htmlFor="cnpj">CNPJ</label>
				<input
					type="text"
					className="form-control"
					value={cnpj || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCnpj(event.target.value)}
					id="cnpj"
					name="cnpj"
					aria-describedby="cnpj-help"
				/>
				{/* <small id="cnpj-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="country">País</label>
				<input
					type="text"
					className="form-control"
					value={country || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCountry(event.target.value)}
					id="country"
					name="country"
					aria-describedby="country-help"
				/>
				<small id="country-help" className="form-text text-muted">
					Ex: BRASIL
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="cep">CEP</label>
				<input
					type="text"
					className="form-control"
					value={cep || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCep(event.target.value)}
					id="cep"
					name="cep"
					aria-describedby="cep-help"
				/>
				{/* <small id="cep-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="address">Endereço</label>
				<input
					type="text"
					className="form-control"
					value={address || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setAddress(event.target.value)}
					id="address"
					name="address"
					aria-describedby="address-help"
				/>
				{/* <small id="address-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="number">Número</label>
				<input
					type="text"
					className="form-control"
					value={number || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setNumber(event.target.value)}
					id="number"
					name="number"
					aria-describedby="number-help"
				/>
				{/* <small id="number-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="complement">Complemento</label>
				<input
					type="text"
					className="form-control"
					value={complement || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setComplement(event.target.value)}
					id="complement"
					name="complement"
					aria-describedby="complement-help"
				/>
				{/* <small id="complement-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="city">Cidade</label>
				<input
					type="text"
					className="form-control"
					value={city || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCity(event.target.value)}
					id="city"
					name="city"
					aria-describedby="city-help"
				/>
				{/* <small id="city-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="state">Estado (UF)</label>
				<input
					type="text"
					className="form-control"
					value={state || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setState(event.target.value)}
					id="state"
					name="state"
					aria-describedby="state-help"
				/>
				<small id="state-help" className="form-text text-muted">
					Ex: SC
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="introVideo">Url de vídeo de apresentação do professor no Vimeo</label>
				<label id="introVideo-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={introVideo || ''}
					required
					disabled={readOnly || loading}
					onChange={event => handleIntroVideoChange(event.target.value)}
					id="introVideo"
					name="introVideo"
					aria-describedby="introVideo-help"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="curriculum">Curriculum resumido do Professor</label>
				<div className="flex gap-sm mb-xs">
					<button type="button" className="btn btn-success" onClick={() => setShowCurriculumType(ShowCurricilumType.Text)}>
						Texto
					</button>
					<button
						type="button"
						className="btn btn-success"
						onClick={() => {
							setShowCurriculumType(ShowCurricilumType.Preview)
							setCurriculumFormatted(marked(curriculum))
						}}
					>
						Preview
					</button>
				</div>
				{showCurriculumType === ShowCurricilumType.Text ? (
					<textarea
						className="form-control"
						value={curriculum || ''}
						required
						disabled={readOnly || loading}
						rows={5}
						onChange={event => setCurriculum(event.target.value)}
						id="curriculum"
						name="curriculum"
						aria-describedby="curriculum-help"
					/>
				) : (
					<div className="oh-hi-mark" dangerouslySetInnerHTML={{ __html: curriculumFormatted }}></div>
				)}

				{/* <input type="text" className="form-control" value={curriculum || ''} onChange={(event) => setCurriculum(event.target.value)} id="curriculum" name="curriculum" aria-describedby="curriculum-help" /> */}
				{/* <small id="curriculum-help" className="form-text text-muted"></small> */}
			</div>

			<hr />
			<h3 className="m-auto">Dados bancários para pagamento</h3>
			<hr />

			<div className="form-group">
				<label htmlFor="pix">PIX</label>
				<input
					type="text"
					className="form-control"
					value={pix || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setPix(event.target.value)}
					id="pix"
					name="pix"
					aria-describedby="pix-help"
				/>
				{/* <small id="pix-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="bankCode">Código do Banco</label>
				<input
					type="text"
					className="form-control"
					value={bankCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setBankCode(event.target.value)}
					id="bankCode"
					name="bankCode"
					aria-describedby="bankCode-help"
				/>
				{/* <small id="bankCode-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="bankAgency">Agência</label>
				<input
					type="text"
					className="form-control"
					value={bankAgency || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setBankAgency(event.target.value)}
					id="bankAgency"
					name="bankAgency"
					aria-describedby="bankAgency-help"
				/>
				{/* <small id="bankAgency-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="bankAccount">Conta</label>
				<input
					type="text"
					className="form-control"
					value={bankAccount || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setBankAccount(event.target.value)}
					id="bankAccount"
					name="bankAccount"
					aria-describedby="bankAccount-help"
				/>
				{/* <small id="bankAccount-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="obs">Observações</label>
				<input
					type="text"
					className="form-control"
					value={obs || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setObs(event.target.value)}
					id="obs"
					name="obs"
					aria-describedby="obs-help"
				/>
				{/* <small id="obs-help" className="form-text text-muted"></small> */}
			</div>

			<hr />
			<h3 className="m-auto">Redes Sociais</h3>
			<hr />

			<div className="form-group">
				<label htmlFor="facebookLink">Facebook</label>
				<input
					type="text"
					className="form-control"
					value={facebookLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setFacebookLink(event.target.value)}
					id="facebookLink"
					name="facebookLink"
					aria-describedby="facebookLink-help"
				/>
				{/* <small id="facebookLink-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="instragramLink">Instagram</label>
				<input
					type="text"
					className="form-control"
					value={instragramLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setInstragramLink(event.target.value)}
					id="instragramLink"
					name="instragramLink"
					aria-describedby="instragramLink-help"
				/>
				{/* <small id="instragramLink-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="youtubeLink">Youtube</label>
				<input
					type="text"
					className="form-control"
					value={youtubeLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setYoutubeLink(event.target.value)}
					id="youtubeLink"
					name="youtubeLink"
					aria-describedby="youtubeLink-help"
				/>
				{/* <small id="youtubeLink-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="twitterLink">Twitter</label>
				<input
					type="text"
					className="form-control"
					value={twitterLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setTwitterLink(event.target.value)}
					id="twitterLink"
					name="twitterLink"
					aria-describedby="twitterLink-help"
				/>
				{/* <small id="twitterLink-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="linkedinLink">Linkedin</label>
				<input
					type="text"
					className="form-control"
					value={linkedinLink || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setLinkedinLink(event.target.value)}
					id="linkedinLink"
					name="linkedinLink"
					aria-describedby="linkedinLink-help"
				/>
				{/* <small id="linkedinLink-help" className="form-text text-muted"></small> */}
			</div>

			<div>
				<button type="button" className="btn btn-success" disabled={readOnly || loading || !name} onClick={() => handleSaveClick()}>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
