import { Link, matchPath } from 'react-router-dom'
import { CSSObjectWithLabel } from 'react-select'
import consts from './consts'
import alertsObserver from './observers/alerts-observer'
import { ModalId } from './observers/modals-observer'
import { appPath } from './routes'
import { SortType } from './types'

export function defaultCatch(err: any) {
	alertsObserver.fire('error', err)
}

export function createEditLink(msgBeforeLink: string, path: string, messageLink: string = 'Clique aqui para editar') {
	return (
		<>
			{msgBeforeLink}{' '}
			<Link className="btn btn-link" to={path}>
				{messageLink}
			</Link>{' '}
		</>
	)
}

export enum SortEnumNext {
	'none' = 'asc',
	'asc' = 'desc',
	'desc' = 'none',
}

export function getNextSortOrder(order: SortType): SortType {
	return SortEnumNext[order]
}

export function getSortOrderForRequest(params: any) {
	return Object.keys(params)
		.filter(k => params[k] !== 'none')
		.map(k => `${k}|${params[k]}`)
		.join(';')
}

export function emptyPromise() {
	return new Promise<void>(resolve => {
		resolve()
	})
}

export function defaultSelectStyles(provided: CSSObjectWithLabel): CSSObjectWithLabel {
	return {
		...provided,
		color: 'black',
	}
}

export function extractSrcFromIframe(iframeHtml: string): string {
	const parser = new DOMParser()
	const dom = parser.parseFromString(iframeHtml, 'text/html')
	const iframe = dom.querySelector('iframe')

	return iframe ? iframe.src : ''
}

export function renderScore(score: number) {
	return (
		<>
			<i key={1} className={score >= 0.5 ? 'fas fa-star' : score > 0 ? 'fas fa-star-half-alt' : 'far fa-star'} style={{ color: 'rgb(240, 210, 117)' }}></i>
			<i key={2} className={score >= 1.5 ? 'fas fa-star' : score > 1 ? 'fas fa-star-half-alt' : 'far fa-star'} style={{ color: 'rgb(240, 210, 117)' }}></i>
			<i key={3} className={score >= 2.5 ? 'fas fa-star' : score > 2 ? 'fas fa-star-half-alt' : 'far fa-star'} style={{ color: 'rgb(240, 210, 117)' }}></i>
			<i key={4} className={score >= 3.5 ? 'fas fa-star' : score > 3 ? 'fas fa-star-half-alt' : 'far fa-star'} style={{ color: 'rgb(240, 210, 117)' }}></i>
			<i key={5} className={score >= 4.5 ? 'fas fa-star' : score > 4 ? 'fas fa-star-half-alt' : 'far fa-star'} style={{ color: 'rgb(240, 210, 117)' }}></i>
		</>
	)
}

export function removeAccents(str: string) {
	const map = {
		à: 'a',
		á: 'a',
		â: 'a',
		ã: 'a',
		ä: 'a',
		ç: 'c',
		è: 'e',
		é: 'e',
		ê: 'e',
		ë: 'e',
		ì: 'i',
		í: 'i',
		î: 'i',
		ï: 'i',
		ñ: 'n',
		ò: 'o',
		ó: 'o',
		ô: 'o',
		õ: 'o',
		ö: 'o',
		ù: 'u',
		ú: 'u',
		û: 'u',
		ü: 'u',
		ý: 'y',
		ÿ: 'y',
		À: 'A',
		Á: 'A',
		Â: 'A',
		Ã: 'A',
		Ä: 'A',
		Ç: 'C',
		È: 'E',
		É: 'E',
		Ê: 'E',
		Ë: 'E',
		Ì: 'I',
		Í: 'I',
		Î: 'I',
		Ï: 'I',
		Ñ: 'N',
		Ò: 'O',
		Ó: 'O',
		Ô: 'O',
		Õ: 'O',
		Ö: 'O',
		Ù: 'U',
		Ú: 'U',
		Û: 'U',
		Ü: 'U',
		Ý: 'Y',
	}

	let result = ''

	for (let i = 0; i < str.length; i++) {
		result += (map as any)[str[i]] || str[i]
	}

	return result
}

export function secondsToTime(secondsTotal: number) {
	const hours = Math.floor(secondsTotal / 3600)
	const minutes = Math.floor((secondsTotal - hours * 3600) / 60)
	const seconds = secondsTotal - hours * 3600 - minutes * 60

	const hoursStr = hours === 0 ? '00' : hours < 10 ? '0' + hours.toString() : hours.toString()

	const minutesStr = minutes === 0 ? '00' : minutes < 10 ? '0' + minutes.toString() : minutes.toString()

	const secondsStr = seconds === 0 ? '00' : seconds < 10 ? '0' + seconds.toString() : seconds.toString()

	return hoursStr + ':' + minutesStr + ':' + secondsStr
}

export function secondsToTimeMMSS(secondsTotal: number) {
	const [hours, minutes, seconds] = secondsToTime(secondsTotal).split(':')

	return (parseInt(hours) > 0 ? parseInt(hours) * 60 + parseInt(minutes) : minutes) + ':' + seconds
}

export function timeToSeconds(timeStr: string) {
	if (typeof timeStr !== 'string') {
		return 0
	}

	const [hours, minutes, seconds] = timeStr.split(':')

	return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds)
}

export function secondsToTimeVimeo(secondsTotal: number) {
	if (!secondsTotal) {
		return ''
	}

	const hours = Math.floor(secondsTotal / 3600)
	const minutes = Math.floor((secondsTotal - hours * 3600) / 60)
	const seconds = secondsTotal - hours * 3600 - minutes * 60

	return '#t' + (hours ? hours + 'h' : '') + (minutes ? minutes + 'm' : '') + (seconds ? seconds + 's' : '')
}

export function uniqueArrayObjId<T extends { id: any }>(arr: T[]) {
	const objTemp: any = {}

	return arr.filter(item => {
		if (!objTemp[item.id]) {
			objTemp[item.id] = true
			return true
		} else {
			return false
		}
	})
}

export function navigateToLogin(history: any, query: string = '') {
	let match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers_concrete_case))

	// from /teachers/concrete-case/:id to /teachers/concrete-case/:id/login
	if (match) {
		return history.push(appPath(consts.app_paths.teachers_concrete_case_login, { id: (match.params as any).id }) + query)
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers))

	// from /teachers to /teachers/login
	if (match) {
		return history.push(appPath(consts.app_paths.teachers_login) + query)
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.concrete_case))

	// from /concrete-case/:id to /concrete-case/:id/login
	if (match) {
		return history.push(appPath(consts.app_paths.concrete_case_login, { id: (match.params as any).id }) + query)
	}

	// to /login
	history.push(appPath(consts.app_paths.login) + query)
}

export function navigateToParamlessModal(history: any, modalId: ModalId) {
	if (modalId === 'login') {
		return navigateToLogin(history)
	}

	const matchTeacher = matchPath(window.location.pathname, { exact: false, path: appPath(consts.app_paths.teachers) })

	if (matchTeacher) {
		switch (modalId) {
			case 'about':
				return history.push(appPath(consts.app_paths.teachers_about))
			case 'lives':
				return history.push(appPath(consts.app_paths.teachers_lives))
			case 'recover':
				return history.push(appPath(consts.app_paths.teachers_recover))
			case 'signup':
				return history.push(appPath(consts.app_paths.teachers_signup))
			case 'signup-hotmart':
				return history.push(appPath(consts.app_paths.teachers_signup_hotmart))
			case 'support':
				return history.push(appPath(consts.app_paths.teachers_support))
			case 'profile':
				return history.push(appPath(consts.app_paths.teachers_profile))
			default:
				return
		}
	}

	switch (modalId) {
		case 'about':
			return history.push(appPath(consts.app_paths.about))
		case 'lives':
			return history.push(appPath(consts.app_paths.lives))
		case 'recover':
			return history.push(appPath(consts.app_paths.recover))
		case 'signup':
			return history.push(appPath(consts.app_paths.signup))
		case 'signup-hotmart':
			return history.push(appPath(consts.app_paths.signup_hotmart))
		case 'support':
			return history.push(appPath(consts.app_paths.support))
		case 'profile':
			return history.push(appPath(consts.app_paths.profile))
		default:
			return
	}
}

export function navigateBackFromModal(history: any) {
	let match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers_concrete_case_login))

	// from /teachers/concrete-case/:id/login to /teachers/concrete-case/:id
	if (match) {
		return history.push(appPath(consts.app_paths.teachers_concrete_case, { id: (match.params as any).id }))
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers_concrete_case))

	// from /teachers/concrete-case/:id to /teachers
	if (match) {
		return history.push(appPath(consts.app_paths.teachers))
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers_login))

	// from /teachers/login to /teachers
	if (match) {
		return history.push(appPath(consts.app_paths.teachers))
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.teachers_id))

	// from /teachers/:id to /teachers
	if (match) {
		return history.push(appPath(consts.app_paths.teachers))
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.concrete_case_login))

	// from /concrete-case/:id/login to /concrete-case/:id
	if (match) {
		return history.push(appPath(consts.app_paths.concrete_case, { id: (match.params as any).id }))
	}

	match = matchPath(window.location.pathname, appPath(consts.app_paths.concrete_case))

	// from /concrete-case/:id to /
	if (match) {
		return history.push(appPath(consts.app_paths.root))
	}

	match = matchPath(window.location.pathname, [
		appPath(consts.app_paths.teachers_about),
		appPath(consts.app_paths.teachers_lives),
		appPath(consts.app_paths.teachers_support),
		appPath(consts.app_paths.teachers_login),
		appPath(consts.app_paths.teachers_signup),
		appPath(consts.app_paths.teachers_signup_hotmart),
		appPath(consts.app_paths.teachers_recover),
		appPath(consts.app_paths.teachers_profile),
	])

	if (match) {
		return history.push(appPath(consts.app_paths.teachers))
	}

	match = matchPath(window.location.pathname, [
		appPath(consts.app_paths.about),
		appPath(consts.app_paths.lives),
		appPath(consts.app_paths.support),
		appPath(consts.app_paths.login),
		appPath(consts.app_paths.signup),
		appPath(consts.app_paths.signup_hotmart),
		appPath(consts.app_paths.recover),
		appPath(consts.app_paths.bonus),
		appPath(consts.app_paths.profile),
	])

	if (match) {
		return history.push(appPath(consts.app_paths.root))
	}
}
