import { MainBodyWindowType } from "./types"

// type MainBodyWindowTypeObserverKey = { [key in MainBodyWindowType]?: Function[] }

type MainBodyWindowTypeObserverFunction = (type: MainBodyWindowType) => void

class MainBodyWindowTypeObserver {
	private observers: MainBodyWindowTypeObserverFunction[] = []

	subscribe (fn: MainBodyWindowTypeObserverFunction) {
		this.observers.push(fn)
	}

	unsubscribe (callback: MainBodyWindowTypeObserverFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fire(type: MainBodyWindowType) {
		this.observers.forEach(fn => {
			fn(type)
		})
	}
}

const observer = new MainBodyWindowTypeObserver()

export default observer
