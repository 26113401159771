import { useContext, useEffect, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { useHistory } from 'react-router-dom'
import consts from '../../consts'
import { fetchPost } from '../../fetch'
import { navigateBackFromModal, removeAccents } from '../../utils'
import { GlobalContext } from '../GlobalContext'
import { LoadingIcon } from '../LoadingIcon'
import { LiveForHomePage } from '../MainBodyTypes'
import { Modal } from './Modal'

export function LivesModal() {
	const [lives, setLives] = useState<LiveForHomePage[]>([])
	const [loadingFilter, setLoadingFilter] = useState(false)
	// const [livesFilter, setLivesFilter] = useState(props.startingFilter || '')
	const [filterTimeout, setFilterTimeout] = useState<NodeJS.Timeout>()

	const searchInputRef = useRef<HTMLInputElement>(null)
	const history = useHistory()

	const { user, livesMasterList, loadingAll, filter } = useContext(GlobalContext)

	useEffect(() => {
		setFilteredLives(livesMasterList, filter)

		// fetchGet<LiveForHomePage[]>(consts.api_paths.home_lives)
		// 	.then(json => {
		// 		setAllLives(json)
		// 		setFilteredLives(json, searchInputRef.current?.value || '')
		// 	})
		// 	.catch(err => alertsObserver.fire('error', 'Ao carregar lives: ' + err))
		// 	.finally(() => setLoading(false))
	}, [livesMasterList])

	useEffect(() => {
		if (searchInputRef.current && searchInputRef.current.value === '') {
			searchInputRef.current.value = filter
		}
	}, [searchInputRef.current])

	function setFilteredLives(originalLives: LiveForHomePage[], filter: string) {
		if (!filter) {
			setLives([...originalLives])
			return
		}

		const filterUpperCase = removeAccents(filter.trim().toUpperCase())

		setLives(
			originalLives.filter(
				live =>
					removeAccents(live.title.toUpperCase()).includes(filterUpperCase) ||
					removeAccents(live.keywords.toUpperCase()).includes(filterUpperCase) ||
					removeAccents(live.teachersNames.toUpperCase()).includes(filterUpperCase)
			)
		)
	}

	function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
		filterTimeout && clearTimeout(filterTimeout)

		setLoadingFilter(true)

		setFilterTimeout(
			setTimeout(() => {
				setFilteredLives(livesMasterList, e.target.value)
				setLoadingFilter(false)
			}, 500)
		)
	}
	// {userId: number, liveId: number, liveDuration: number, liveProgress: number}
	function handlePlayerProgress(state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }, live: LiveForHomePage | undefined) {
		if (!live || !user || state.playedSeconds < 2) {
			return
		}

		const playedSeconds = Math.floor(state.playedSeconds)

		setLives(
			lives.map(l => {
				if (l.id == live.id) {
					return { ...l, progress: playedSeconds }
				} else {
					return { ...l }
				}
			})
		)
		// live.progress = playedSeconds;
		setWatchHistory(live.id, playedSeconds)
	}

	function setWatchHistory(liveId: number, liveProgress: number) {
		fetchPost(consts.api_paths.update_live_progress, {
			body: JSON.stringify({
				liveId,
				liveProgress,
			}),
		})
	}

	function handlePlayerReady(player: VimeoPlayer, progress: number | undefined) {
		if (!progress) {
			return
		}
		player.seekTo(progress)
	}

	// const listsCount = Math.floor((window.innerWidth * 0.9) / 300)
	// const liveLists: LiveForHomePage[][] = []

	// for (let i = 0; i < listsCount; i++) {
	// 	const slice = lives.slice(listsCount * i, listsCount * i + listsCount)

	// 	if (slice.length === 0) {
	// 		break
	// 	}

	// 	liveLists.push(slice)
	// }

	return (
		<Modal id="lives" style={{ padding: 10 }} visible width="90%">
			<i className="fas fa-times cursor-pointer" style={{ margin: '10px', position: 'absolute', right: 0, top: 0 }} onClick={() => navigateBackFromModal(history)}></i>

			<div style={{ display: 'flex', margin: '10px 20px 30px 10px' }}>
				<h1>{loadingAll ? <LoadingIcon /> : <i className="fab fa-instagram-square"></i>} LIVES DO INSTAGRAM @evaedorg</h1>

				<div style={{ marginRight: 0, marginLeft: 'auto' }} className="input-group">
					<input type="text" className="form-control form-control-compact" ref={searchInputRef} onChange={handleFilterChange} />
					{loadingFilter ? <LoadingIcon className="input-addon" /> : <i className="fa fa-search input-addon" onClick={() => searchInputRef.current?.focus()} />}
				</div>
			</div>
			<div style={{ overflow: 'auto', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-around', padding: '0 20px', height: window.innerHeight - 220 }}>
				{lives.map(live => (
					<div key={live.id} style={{ height: 'min-content', marginBottom: 30, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
						<div key={live.videoUrl} style={{ width: 228 + 40, textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', flexGrow: 2, alignItems: 'center', minHeight: '11ch' }}>
								<h4 style={{ lineHeight: 1.2, marginBottom: 15 }}>{live.title}</h4>
							</div>
							<div style={{ justifyContent: 'left' }}>
								<VimeoPlayer
									progressInterval={30000}
									controls
									width={228}
									height={416}
									url={live.videoUrl}
									onProgress={state => handlePlayerProgress(state, live)}
									onReady={p => handlePlayerReady(p as VimeoPlayer, live.userWatchInfo?.duration || 0)}
								/>
								<div
									className="live-progress-bar"
									style={{
										height: '3px',
										backgroundColor: 'red',
										bottom: 0,
										left: 0,
										position: 'relative',
										width:
											live.userWatchInfo && live.userWatchInfo.duration
												? (live.userWatchInfo.duration / live.duration) * 100 < 90
													? `${(live.userWatchInfo.duration / live.duration) * 100}%`
													: '100%'
												: '0%',
									}}
								></div>
							</div>
						</div>
					</div>
				))}
			</div>
		</Modal>
	)
	// (live.progress / live.duration) * 100).toString()
	// return (
	// 	<Modal id="lives" style={{ padding: 10 }} visible width="90%">
	// 		<i className="fas fa-times cursor-pointer" style={{ margin: '10px', position: 'absolute', right: 0, top: 0 }} onClick={() => navigateBackFromModal(history)}></i>

	// 		<div style={{ display: 'flex', margin: '10px 20px 30px 10px' }}>
	// 			<h1>{loading ? <LoadingIcon /> : <i className="fab fa-instagram-square"></i>} LIVES DO INSTAGRAM @evaedorg</h1>

	// 			<div style={{ marginRight: 0, marginLeft: 'auto' }} className="input-group">
	// 				<input type="text" className="form-control form-control-compact" ref={searchInputRef} onChange={handleFilterChange} />
	// 				{loadingFilter ? <LoadingIcon className="input-addon" /> : <i className="fa fa-search input-addon" onClick={() => searchInputRef.current?.focus()} />}
	// 			</div>
	// 		</div>
	// 		<div style={{ overflow: 'auto', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', padding: '0 20px', height: window.innerHeight - 220 }}>
	// 			{liveLists.map(ll => (
	// 				<div style={{ height: 'min-content', width: '100%', marginBottom: 30, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
	// 					{ll.map(live => (
	// 						<div key={live.videoUrl} style={{ width: 228 + 40, textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between' }}>
	// 							<div style={{ display: 'flex', flexGrow: 2, alignItems: 'center' }}>
	// 								<h3 style={{ lineHeight: 1.1, marginBottom: 15 }}>{live.title}</h3>
	// 							</div>
	// 							<div style={{ justifyContent: 'left' }}>
	// 								<VimeoPlayer controls width={228} height={416} url={live.videoUrl} />
	// 								<div style={{ height: 3, width: `${'50'}%`, backgroundColor: 'red', left: 0, bottom: 0 }}></div>
	// 							</div>
	// 						</div>
	// 					))}
	// 				</div>
	// 			))}
	// 		</div>
	// 	</Modal>
	// )
}
