import { ConcreteCaseForCarousel } from "../components/MainBodyTypes"

export enum ConcreteCaseChangeObserverShooter {
	Carousel,
	ConcreteCaseModal,
	VideoPreview,
}

export type ConcreteCaseChangeObserverFunction = (concreteCase: ConcreteCaseForCarousel, shooter: ConcreteCaseChangeObserverShooter) => void

class ConcreteCaseChangeObserver {
	private observers: ConcreteCaseChangeObserverFunction[] = []

	subscribe(fn: ConcreteCaseChangeObserverFunction) {
		this.observers.push(fn)
	}

	unsubscribe(callback: ConcreteCaseChangeObserverFunction) {
		this.observers = this.observers.filter(fn => {
			return fn !== callback
		})
	}

	fire(concreteCase: ConcreteCaseForCarousel, shooter: ConcreteCaseChangeObserverShooter) {
		this.observers.forEach(fn => {
			fn(concreteCase, shooter)
		})
	}
}

const concreteCaseChangeObserver = new ConcreteCaseChangeObserver()

export default concreteCaseChangeObserver
