import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import consts from '../../consts'
import { fetchPost } from '../../fetch'
import { navigateToLogin } from '../../utils'
import { LoadingIcon } from '../LoadingIcon'
import { Modal } from './Modal'

export function RecoverModal() {
	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [emailRepeat, setEmailRepeat] = useState<string>('')
	const [recoverSent, setRecoverSent] = useState(false)
	const [recoverError, setRecoverError] = useState('')
	const [showFillEmailError, setShowFillEmailError] = useState<boolean>(false)
	// const [showSameEmailError, setShowSameEmailError] = useState<boolean>(false)

	const history = useHistory()

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		if (!email.trim()) {
			return setShowFillEmailError(true)
		}

		// if (email !== emailRepeat) {
		// 	return setShowSameEmailError(true)
		// }

		setLoading(true)

		fetchPost(consts.api_paths.recover, {
			body: JSON.stringify({
				email,
			}),
		})
			.then(data => {
				setRecoverSent(true)
			})
			.catch(err => {
				setRecoverError(err)
			})
			.finally(() => setLoading(false))
	}

	return (
		<Modal id="recover" visible calculateWidth={false} width={500} style={{ padding: 15 }}>
			<main className="flex flex-column gap-md">
				<h1 className="m-auto" style={{ fontSize: 30 }}>
					Recuperação de senha
				</h1>
				{recoverSent ? (
					<div style={{ marginTop: 20 }}>
						<h1 className="text-center lh-13">SUA SENHA DE ACESSO FOI ENVIADA PRA SEU E-MAIL</h1>
					</div>
				) : (
					<form className="login-form login-form flex flex-column gap-md" onSubmit={handleSubmit}>
						<h3 className="m-auto text-center lh-13">Informe o e-mail que você cadastrou para usar a Biblioteca de Casos Concretos</h3>
						<section className="form-group form-group-big-input">
							<label htmlFor="email">E-mail</label>
							<input
								id="email"
								name="email"
								type="email"
								className="form-control form-control-compact"
								value={email}
								required
								autoFocus
								disabled={loading}
								onChange={event => setEmail(event.target.value)}
								aria-describedby="email-help"
								placeholder="Digite seu e-mail"
							/>
							<small id="email-help" className={showFillEmailError && !email ? 'text-danger' : 'hidden'}>
								E-mail para recuperação de senha
							</small>
						</section>

						{recoverError && <h1 className="text-danger">{recoverError}</h1>}

						{/* <section className="form-group form-group-big-input">
						<label htmlFor="emailRepeat">E-mail (repetir)</label>
						<input
							id="emailRepeat"
							name="emailRepeat"
							type="emailRepeat"
							className="form-control form-control-compact"
							value={emailRepeat}
							required
							autoComplete="false"
							disabled={loading}
							onChange={event => setEmailRepeat(event.target.value)}
							aria-describedby="emailRepeat-help"
							placeholder="Repita seu e-mail"
						/>
					</section>
					<small id="email-help" className={showSameEmailError && email ? 'text-danger' : 'hidden'} style={{ fontSize: 16 }}>
						E-mails devem ser iguais
					</small> */}

						<button type="submit" disabled={loading} className="btn btn-danger btn-bg">
							Solicitar recuperação {loading ? <LoadingIcon /> : null}
						</button>
					</form>
				)}
				<div className="flex space-between">
					<button type="button" className="btn btn-link" onClick={() => navigateToLogin(history)}>
						Entrar
					</button>
					{/* <button type="button" className="btn btn-link" onClick={() => { modalsObserver.fire('recover', 'close'); modalsObserver.fire('signup', 'open'); }}>Criar Conta</button> */}
				</div>
			</main>
		</Modal>
	)
}
