import consts from "./consts"

export function appPath(path: string, params: any = {}) {
	// path.split('/')
	// 	.reduce((agg: string[], el) => agg.concat(el.split('?')), [])
	// 	.reduce((agg: string[], el) => agg.concat(el.split('&')), [])
	// 	.reduce((agg: string[], el) => agg.concat(el.split('=')), [])
	// 	// filters only the params and makes the array unique
	// 	.filter((el, idx, self) => el.startsWith(':') && self.indexOf(el) === idx)

	let pathReplaced: string = path

	Object.keys(params).forEach(k => {
		// pathReplaced = pathReplaced.replaceAll(`:${k}`, params[k])

		pathReplaced = pathReplaced.replace(`:${k}`, params[k])
		pathReplaced = pathReplaced.replace(`:${k}`, params[k])
	})

	return consts.APP_URL + pathReplaced
}

export function apiPath(path: string, params: any = {}) {
	let pathReplaced: string = path

	Object.keys(params).forEach(k => {
		// pathReplaced = pathReplaced.replaceAll(`:${k}`, params[k])

		pathReplaced = pathReplaced.replace(`:${k}`, params[k])
		pathReplaced = pathReplaced.replace(`:${k}`, params[k])
	})

	return consts.API_URL + pathReplaced
}
