class GlobalsObserver {
	private draggingCarousel: boolean = false

	setDraggingCarousel(value: boolean) {
		this.draggingCarousel = value
	}

	getDraggingCarousel() {
		return this.draggingCarousel
	}
}

const globalsObserver = new GlobalsObserver()

export default globalsObserver
