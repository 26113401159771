import { CSSProperties, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import consts from '../consts'
import { fetchPost } from '../fetch'
import { navigateBackFromModal } from '../utils'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { Modal } from './modals/Modal'

// const platform = require('platform')

enum ContactMehod {
	Phone,
	Email,
	Whatsapp,
	None,
}

export function SuportModal() {
	const [name, setName] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [message, setMessage] = useState<string>('')

	const [contactMethod, setContactMethod] = useState<ContactMehod>(ContactMehod.None)

	const { systemConfig, setSystemConfig } = useContext(GlobalContext)

	const history = useHistory()

	function renderContact() {
		switch (contactMethod) {
			case ContactMehod.Phone:
				return <PhoneContact phoneNumber={systemConfig.phoneNumber} openingHoursMessage={systemConfig.openingHoursMessage} />
			case ContactMehod.Email:
				return <EmailContact name={name} setName={setName} phone={phone} setPhone={setPhone} email={email} setEmail={setEmail} message={message} setMessage={setMessage} />
			case ContactMehod.Whatsapp:
				return <WhatsappContact whatsappNumber={systemConfig.whatsappNumber} openingHoursMessage={systemConfig.openingHoursMessage} />
			default:
				return null
		}
	}

	const contactToRender = renderContact()

	return (
		<Modal id="support" calculateWidth visible>
			<div className="modal-close-button">
				<i className="fas fa-times cursor-pointer" style={{ margin: '10px' }} onClick={() => navigateBackFromModal(history)}></i>
			</div>
			<div className="modal-title-holder" style={{ textAlign: 'center' }}>
				<h1 style={{ marginBottom: 20 }}>CENTRAL DE AJUDA</h1>
				<h3>Como você gostaria de receber ajuda?</h3>
			</div>
			<div style={{ padding: '0 5% 50px' }}>
				<div className="icons-holder">
					<button
						onClick={() => setContactMethod(ContactMehod.Phone)}
						style={contactMethod === ContactMehod.Phone ? { border: '1px solid white', borderBottom: 'none', ...styleButtonContactType } : styleButtonContactType}
					>
						<i className="fas fa-phone-alt fa-5x" style={styleIconContactType}></i>
					</button>
					<button
						onClick={() => setContactMethod(ContactMehod.Email)}
						style={contactMethod === ContactMehod.Email ? { border: '1px solid white', borderBottom: 'none', ...styleButtonContactType } : styleButtonContactType}
					>
						<i className="fas fa-envelope fa-5x" style={styleIconContactType}></i>
					</button>
					<button
						onClick={() => setContactMethod(ContactMehod.Whatsapp)}
						style={contactMethod === ContactMehod.Whatsapp ? { border: '1px solid white', borderBottom: 'none', ...styleButtonContactType } : styleButtonContactType}
					>
						<i className="fab fa-whatsapp fa-5x" style={styleIconContactType}></i>
					</button>
				</div>
				{contactToRender && (
					<div className="contact-form" style={{ padding: '0 10% 20px' }}>
						{contactToRender}
					</div>
				)}
			</div>
		</Modal>
	)
}

function PhoneContact(props: { phoneNumber: string; openingHoursMessage: string }) {
	const history = useHistory()

	return (
		<div className="phone-contact" style={{ paddingBottom: 20 }}>
			<div className="phone-contact-title">
				<h1 style={{ fontSize: '3rem' }}>Ligue para nós</h1>
			</div>
			<div className="phone-contact-number">
				<h3>{props.phoneNumber}</h3>
			</div>
			<div>
				<p style={{ fontSize: '1.3rem' }}>{props.openingHoursMessage}</p>
			</div>

			<button type="button" style={{ padding: '10px 30px', marginTop: 20 }} className="btn btn-danger" onClick={() => navigateBackFromModal(history)}>
				FECHAR
			</button>
		</div>
	)
}

function EmailContact(props: {
	name: string
	setName: React.Dispatch<React.SetStateAction<string>>
	phone: string
	setPhone: React.Dispatch<React.SetStateAction<string>>
	email: string
	setEmail: React.Dispatch<React.SetStateAction<string>>
	message: string
	setMessage: React.Dispatch<React.SetStateAction<string>>
}) {
	const [sendingEmail, setSendingEmail] = useState(false)
	const [emailSuccessMessage, setEmailSuccessMessage] = useState('')
	const [emailErrorMessage, setEmailErrorMessage] = useState('')

	const { user } = useContext(GlobalContext)
	const history = useHistory()

	useEffect(() => {
		let isMounted = true

		props.setName(prev => (prev ? prev : user?.name || ''))
		props.setEmail(prev => (prev ? prev : user?.email || ''))

		return () => {
			isMounted = false
		}
	}, [user])

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()

		setSendingEmail(true)
		setEmailSuccessMessage('')
		setEmailErrorMessage('')

		fetchPost(consts.api_paths.send_email, {
			body: JSON.stringify({ name: props.name, phone: props.phone, email: props.email, message: props.message }),
		})
			.then(res => setEmailSuccessMessage(res.message))
			.catch(err => setEmailErrorMessage('Erro ao enviar, tente novamente mais tarde'))
			.finally(() => setSendingEmail(false))
	}

	if (emailSuccessMessage) {
		return (
			<div style={{ textAlign: 'center' }}>
				<h1 style={{ padding: 15, fontSize: '2rem', lineHeight: 1.2 }}>MENSAGEM ENVIADA COM ÊXITO</h1>
				<h2 style={{ padding: 15 }}>{emailSuccessMessage}</h2>
				<button type="button" style={{ padding: '10px 30px' }} className="btn btn-danger" onClick={() => navigateBackFromModal(history)}>
					FECHAR
				</button>
			</div>
		)
	}

	return (
		<form onSubmit={handleSubmit}>
			<div className="form-control" style={{ background: 'none', color: 'white', border: 'none' }}>
				<label htmlFor="name">Nome</label>
				<input type="text" className="form-control" value={props.name} required onChange={e => props.setName(e.target.value)} />
			</div>
			<div className="form-control" style={{ background: 'none', color: 'white', border: 'none' }}>
				<label htmlFor="phone">Telefone</label>
				<input type="text" className="form-control" value={props.phone} onChange={e => props.setPhone(e.target.value)} />
			</div>
			<div className="form-control" style={{ background: 'none', color: 'white', border: 'none' }}>
				<label htmlFor="email">E-mail</label>
				<input type="email" className="form-control" value={props.email} required onChange={e => props.setEmail(e.target.value)} />
			</div>
			<div className="form-control" style={{ background: 'none', color: 'white', border: 'none' }}>
				<label htmlFor="message">Mensagem</label>
				<textarea className="form-control form-control-sm" value={props.message} required onChange={e => props.setMessage(e.target.value)} />
			</div>
			{emailErrorMessage && (
				<h3 className="text-danger" style={{ padding: 15 }}>
					Erro ao enviar
				</h3>
			)}
			<div className="form-control" style={{ background: 'none', border: 'none' }}>
				<button type="submit" className="btn btn-primary" disabled={sendingEmail}>
					Enviar {sendingEmail && <LoadingIcon />}
				</button>
			</div>
		</form>
	)
}

function WhatsappContact(props: { whatsappNumber: string; openingHoursMessage: string }) {
	const history = useHistory()

	return (
		<div className="whatsapp-contact" style={{ position: 'relative' }}>
			<div className="whatsapp-contact-title">
				<h1>Converse conosco por Whatsapp</h1>
			</div>
			<a href={`https://web.whatsapp.com/send?phone=${(props.whatsappNumber || '').split(' ').join('')}`} target="_blank" rel="noopener noreferrer">
				<div className="whatsapp-contact-number">
					<h3>
						{props.whatsappNumber} <i className="fab fa-whatsapp"></i>
					</h3>
				</div>
			</a>
			<div>
				<p>{props.openingHoursMessage}</p>
			</div>

			<button type="button" style={{ padding: '10px 30px', position: 'absolute', bottom: 0 }} className="btn btn-danger" onClick={() => navigateBackFromModal(history)}>
				FECHAR
			</button>
		</div>
	)
}

const styleButtonContactType: CSSProperties = {
	width: '33.33333%',
}

const styleIconContactType: CSSProperties = {
	padding: 25,
	fontSize: '5rem',
}
