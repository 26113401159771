import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import consts from '../../consts'
import { fetchDelete, fetchGet, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { defaultSelectStyles, extractSrcFromIframe } from '../../utils'
import { FormRowTitle } from '../FormRowTitle'
import { LoadingIcon } from '../LoadingIcon'
import { BonusAccess } from './BonusAccessTypes'
import { ConcreteCasesSelectType } from './ConcreteCaseTypes'

export function BonusAccessForm() {
	const [link, setLink] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [expirationDate, setExpirationDate] = useState<string>('')
	const [videoUrl, setVideoUrl] = useState<string>('https://player.vimeo.com/video/489022392')
	const [videoUrlAcquired, setVideoUrlAcquired] = useState<string>('https://player.vimeo.com/video/489188512')
	// const [thumbnail, setThumbnail] = useState<string>('')
	// const [thumbnailAcquired, setThumbnailAcquired] = useState<string>('')
	const [concreteCaseId, setConcreteCaseId] = useState<number | undefined>(undefined)
	const [machineCode, setMachineCode] = useState(0)
	const [emailSequenceCode, setEmailSequenceCode] = useState(0)
	const [sequenceLevelCode, setSequenceLevelCode] = useState(0)

	const [loading, setLoading] = useState<boolean>(false)
	const [loadingConcreteCases, setLoadingConcreteCases] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)
	const [concreteCasesSelect, setConcreteCasesSelect] = useState<ConcreteCasesSelectType[]>([])
	const [selectedConcreteCase, setSelectedConcreteCase] = useState<ConcreteCasesSelectType | undefined>(undefined)

	// const thumbnailImgRef = useRef<HTMLImageElement>(null)
	// const thumbnailInputRef = useRef<HTMLInputElement>(null)
	// const thumbnailAcquiredImgRef = useRef<HTMLImageElement>(null)
	// const thumbnailAcquiredInputRef = useRef<HTMLInputElement>(null)
	// const [thumbnailChanged, setThumbnailChanged] = useState<boolean>(false)
	// const [thumbnailAcquiredChanged, setThumbnailAcquiredChanged] = useState<boolean>(false)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	useEffect(() => {
		let isMounted = true

		function loadSelectConcreteCase(concreteCaseId?: number) {
			setLoadingConcreteCases(true)

			fetchGet<ConcreteCasesSelectType[]>(consts.api_paths.concrete_cases_for_select)
				.then(json => {
					if (!isMounted) return
					setConcreteCasesSelect(json)

					if (concreteCaseId) {
						setSelectedConcreteCase(json.find(el => el.value === concreteCaseId))
					}
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar select de Casos Concretos: ${err}`)
				})
				.finally(() => {
					if (!isMounted) return
					setLoadingConcreteCases(false)
				})
		}

		if (id) {
			setLoading(true)

			fetchGet<BonusAccess>(consts.api_paths.bonus_accesses + '/' + id)
				.then(json => {
					if (!isMounted) return
					setTitle(json.title)
					setExpirationDate(json.expirationDate)
					setVideoUrl(json.videoUrl)
					setVideoUrlAcquired(json.videoUrlAcquired)
					setLink(consts.HOST + consts.APP_URL + json.link)
					// setThumbnail(json.thumbnail)
					// setThumbnailAcquired(json.thumbnailAcquired)
					setConcreteCaseId(json.concreteCaseId)
					setMachineCode(json.machineCode)
					setEmailSequenceCode(json.emailSequenceCode)
					setSequenceLevelCode(json.sequenceLevelCode)

					// if (thumbnailImgRef.current && json.thumbnail) {
					// 	thumbnailImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.thumbnail
					// }

					// if (thumbnailAcquiredImgRef.current && json.thumbnailAcquired) {
					// 	thumbnailAcquiredImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.thumbnailAcquired
					// }

					setTimeout(() => loadSelectConcreteCase(json.concreteCaseId), 0)
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Acesso Bônus ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		} else {
			loadSelectConcreteCase()
		}

		return () => {
			isMounted = false
		}
	}, [])

	function handleDeleteClick() {
		setLoading(true)
		setShowDelete(false)

		fetchDelete(consts.api_paths.bonus_accesses + '/' + id)
			.then(() => {
				alertsObserver.fire('success', `Acesso Bônus ${id} removido com sucesso`)
				setReadOnly(true)
			})
			.catch(err => {
				alertsObserver.fire('error', `Ao deletar Acesso Bônus ${id}: ${err}`)
				setShowDelete(true)
			})
			.finally(() => setLoading(false))
	}

	async function handleSaveClick() {
		setLoading(true)

		// let thumbnailToSave = thumbnail
		// let thumbnailAcquiredToSave = thumbnailAcquired

		// if (thumbnailChanged && thumbnailInputRef.current && thumbnailInputRef.current.files && thumbnailInputRef.current.files[0]) {
		// 	const formData = new FormData()

		// 	formData.append('image', thumbnailInputRef.current.files[0])

		// 	try {
		// 		const json = await fetchPostFile('/files/image/bonus-access/0', { body: formData })

		// 		if (json.error) {
		// 			setLoading(false)
		// 			alertsObserver.fire('error', `Ao salvar capa do video de apresentação: ${json.message}`)
		// 			return
		// 		}

		// 		thumbnailToSave = json.filename
		// 		setThumbnailChanged(false)
		// 	} catch (err) {
		// 		setLoading(false)
		// 		alertsObserver.fire('error', `Ao salvar capa do video de apresentação: ${err}`)
		// 		return
		// 	}
		// }

		// if (thumbnailAcquiredChanged && thumbnailAcquiredInputRef.current && thumbnailAcquiredInputRef.current.files && thumbnailAcquiredInputRef.current.files[0]) {
		// 	const formData = new FormData()

		// 	formData.append('image', thumbnailAcquiredInputRef.current.files[0])

		// 	try {
		// 		const json = await fetchPostFile('/files/image/bonus-access/0', { body: formData })

		// 		if (json.error) {
		// 			setLoading(false)
		// 			alertsObserver.fire('error', `Ao salvar capa do video de senha enviada: ${json.message}`)
		// 			return
		// 		}

		// 		thumbnailAcquiredToSave = json.filename
		// 		setThumbnailAcquiredChanged(false)
		// 	} catch (err) {
		// 		setLoading(false)
		// 		alertsObserver.fire('error', `Ao salvar capa do video de senha enviada: ${err}`)
		// 		return
		// 	}
		// }

		fetchPostOrPut(consts.api_paths.bonus_accesses, id, {
			body: JSON.stringify({
				title,
				expirationDate,
				videoUrl,
				videoUrlAcquired,
				machineCode,
				emailSequenceCode,
				sequenceLevelCode,
				// thumbnail: thumbnailToSave,
				// thumbnailAcquired: thumbnailAcquiredToSave,
				concreteCaseId,
			}),
		})
			.then(data => {
				alertsObserver.fire('success', `Acesso Bônus ${data.id} salvo com sucesso`)

				if (data.link) {
					setLink(consts.HOST + consts.APP_URL + data.link)
				}
			})
			.catch(err => alertsObserver.fire('error', `Ao salvar Acesso Bônus ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	function handleModuleImageChange(input: HTMLInputElement | null, img: HTMLImageElement | null, changeState: Function) {
		if (!input || !img) {
			return
		}

		if (input.files && input.files[0]) {
			const file = new FileReader()

			file.onload = e => {
				img.src = e.target?.result as any

				changeState(true)
			}

			file.readAsDataURL(input.files[0])
		}
	}

	function handleConcreteCasesSelectChange(value: ConcreteCasesSelectType | undefined) {
		setSelectedConcreteCase(value)
		setConcreteCaseId(value?.value)
		setTitle(prev => value?.title || prev)

		// if (value?.thumbnail && thumbnailImgRef.current) {
		// 	thumbnailImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + value.thumbnail
		// 	setThumbnail(value.thumbnail)
		// 	setThumbnailChanged(false)
		// }

		if (value?.introVideo) {
			setVideoUrl(value?.introVideo)
		}
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin_bonus_access)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle id={id} label="Acesso Bônus" loading={loading} allowDeletingButton={!readOnly && showDelete && !loading} onDeleteConfirmationClick={handleDeleteClick} />

			<div className="form-group">
				<label htmlFor="concreteCase">Caso Concreto {loadingConcreteCases ? <LoadingIcon /> : null}</label>
				<Select
					options={concreteCasesSelect}
					value={selectedConcreteCase}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingConcreteCases}
					onChange={value => handleConcreteCasesSelectChange(value || undefined)}
					name="concreteCase"
					id="concreteCase"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="title">Título</label>
				<input
					autoFocus
					type="text"
					className="form-control"
					value={title || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setTitle(event.target.value)}
					id="title"
					name="title"
					aria-describedby="title-help"
				/>
				{/* <small id="title-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="link">Link</label>
				<input type="text" className="form-control" value={link || ''} required id="link" name="link" aria-describedby="link-help" placeholder="Será gerado após salvar" />
				{/* <small id="link-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="expirationDate">Data de validade</label>
				<input
					type="date"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={expirationDate}
					onChange={event => setExpirationDate(event.target.value)}
					id="expirationDate"
					name="expirationDate"
					aria-describedby="expirationDate-help"
					placeholder=""
				/>
				<small id="expirationDate-help" className="form-text text-muted"></small>
			</div>

			{/* <div className="form-group">
				<img ref={thumbnailImgRef} style={consts.imgThumbnailStyle} onClick={() => thumbnailInputRef.current?.click()} />
				<label htmlFor="thumbnail">Capa do vídeo de Apresentação</label>
				<input
					ref={thumbnailInputRef}
					type="file"
					accept="image/*"
					className="form-control"
					style={{ display: 'none' }}
					required
					disabled={readOnly || loading}
					onChange={event => handleModuleImageChange(thumbnailInputRef.current, thumbnailImgRef.current, setThumbnailChanged)}
					id="thumbnail"
					name="thumbnail"
					aria-describedby="thumbnail-help"
				/>
				<small id="thumbnail-help" className="form-text text-muted"></small>
			</div> */}

			<div className="form-group">
				<label htmlFor="videoUrl">Url do vídeo de Apresentação</label>
				<label id="videoUrl-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={videoUrl || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setVideoUrl(extractSrcFromIframe(event.target.value) || event.target.value)}
					id="videoUrl"
					name="videoUrl"
					aria-describedby="videoUrl-help"
				/>
			</div>

			{/* <div className="form-group">
				<img ref={thumbnailAcquiredImgRef} style={consts.imgThumbnailStyle} onClick={() => thumbnailAcquiredInputRef.current?.click()} />
				<label htmlFor="thumbnailAcquired">Capa do vídeo de Senha Enviada</label>
				<input
					ref={thumbnailAcquiredInputRef}
					type="file"
					accept="image/*"
					className="form-control"
					style={{ display: 'none' }}
					required
					disabled={readOnly || loading}
					onChange={event => handleModuleImageChange(thumbnailAcquiredInputRef.current, thumbnailAcquiredImgRef.current, setThumbnailAcquiredChanged)}
					id="thumbnailAcquired"
					name="thumbnailAcquired"
					aria-describedby="thumbnailAcquired-help"
				/>
				<small id="thumbnailAcquired-help" className="form-text text-muted"></small>
			</div> */}

			<div className="form-group">
				<label htmlFor="videoUrlAcquired">Url do vídeo Senha Enviada</label>
				<label id="videoUrlAcquired-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={videoUrlAcquired || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setVideoUrlAcquired(extractSrcFromIframe(event.target.value) || event.target.value)}
					id="videoUrlAcquired"
					name="videoUrlAcquired"
					aria-describedby="videoUrlAcquired-help"
				/>
			</div>

			<hr />
			<h3 className="m-auto">Informações da máquina LeadLovers</h3>
			<hr />

			<div className="form-group">
				<label htmlFor="machineCode">Identificador da Máquina: (ID)</label>
				<input
					type="number"
					className="form-control"
					value={machineCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setMachineCode(event.target.value as any)}
					id="machineCode"
					name="machineCode"
					aria-describedby="machineCode-help"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="emailSequenceCode">Identificador do funil:</label>
				<label id="emailSequenceCode-help" style={{ display: 'block' }} className="form-text text-danger">
					Código extraído ao executar o seguinte código na edição do formulário:
					<br />
					document.querySelector('#cbo-mchstr-funnel').value
					<br />
					<a className="btn text-center text-success" href={consts.DYNAMIC_FILES_URL + '/form-console.png'} target="_blank">
						CLIQUE AQUI PARA VER AS INSTRUÇÕES
					</a>
				</label>
				<input
					type="number"
					className="form-control"
					value={emailSequenceCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmailSequenceCode(event.target.value as any)}
					id="emailSequenceCode"
					name="emailSequenceCode"
					aria-describedby="emailSequenceCode-help"
				/>
			</div>

			<div className="form-group">
				<label htmlFor="sequenceLevelCode">Sequência do funil:</label>
				<label id="sequenceLevelCode-help" style={{ display: 'block' }} className="form-text text-danger">
					Número da sequência do funíl na edição do formulário
				</label>
				<input
					type="number"
					className="form-control"
					value={sequenceLevelCode || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setSequenceLevelCode(event.target.value as any)}
					id="sequenceLevelCode"
					name="sequenceLevelCode"
					aria-describedby="sequenceLevelCode-help"
				/>
			</div>

			<div>
				<button
					type="button"
					className="btn btn-success"
					disabled={readOnly || loading || !title || !concreteCaseId || !expirationDate}
					onClick={() => {
						handleSaveClick()
					}}
				>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
