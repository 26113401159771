import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import consts from '../../consts'
import { fetchDelete, fetchGet, fetchPostFile, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { defaultSelectStyles, extractSrcFromIframe } from '../../utils'
import { FormRowTitle } from '../FormRowTitle'
import { LoadingIcon } from '../LoadingIcon'
import { Live } from './LiveTypes'

export function LiveForm() {
	const [title, setTitle] = useState<string>('')
	const [date, setDate] = useState<string>('')
	const [thumbnail, setThumbnail] = useState<string>('')
	const [keywords, setKeywords] = useState<string>('')
	const [videoUrl, setVideoUrl] = useState<string>('')
	const [durationMask, setDurationMask] = useState<string>('')
	const [teachersIds, setTeachersIds] = useState<number[]>([])

	const [teachersSelect, setTeachersSelect] = useState<{ value: number; label: string }[]>([])
	const [selectedTeachers, setSelectedTeachers] = useState<{ value: number; label: string }[]>([])

	const [loading, setLoading] = useState<boolean>(false)
	const [loadingTeachers, setLoadingTeachers] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)

	const thumbnailImgRef = useRef<HTMLImageElement>(null)
	const thumbnailInputRef = useRef<HTMLInputElement>(null)

	const [imageChanged, setImageChanged] = useState<boolean>(false)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	useEffect(() => {
		let isMounted = true

		function loadSelectTeachers(teachersIds?: number[]) {
			setLoadingTeachers(true)
			fetchGet<[]>(consts.api_paths.teachers_for_select)
				.then(json => {
					if (!isMounted) return
					setTeachersSelect(json)

					if (teachersIds && teachersIds.length > 0) {
						setSelectedTeachers(json.filter((el: any) => teachersIds.find(elId => elId === el.value)))
					}
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar select de Professores: ${err}`)
				})
				.finally(() => {
					if (!isMounted) return
					setLoadingTeachers(false)
				})
		}

		if (id) {
			setLoading(true)

			fetchGet<Live>(consts.api_paths.lives + '/' + id)
				.then(json => {
					let hours = convertDuration(json.duration.toString(), 'hours')
					if (!isMounted) return
					setTitle(json.title)
					setDate(json.date)
					setThumbnail(json.thumbnail)
					setKeywords(json.keywords)
					setVideoUrl(json.videoUrl)
					setDurationMask(hours as string)
					// setDuration(json.duration.toString())
					setTeachersIds(json.teachersIds)

					if (json.thumbnail && thumbnailImgRef.current) {
						thumbnailImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.thumbnail
					}

					setTimeout(() => loadSelectTeachers(json.teachersIds), 0)
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Live ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		} else {
			loadSelectTeachers()
		}

		return () => {
			isMounted = false
		}
	}, [])

	function convertDuration(duration: string, to: string) {
		if (!duration && to == 'seconds') {
			return 0
		}
		if (!duration && to == 'hours') {
			return '00:00'
		}
		let hours
		if (to == 'hours') {
			hours = new Date(parseInt(duration) * 1000).toISOString().substr(11, 8).split(':')

			if (hours[0] == '00') {
				hours.shift()
			}

			return hours.join(':')
		}
		if (to == 'seconds') {
			let totalSeconds = 0
			hours = duration.split(':')

			if (hours.length == 3) {
				totalSeconds += parseInt(hours[0]) * 60 * 60
				totalSeconds += parseInt(hours[1]) * 60
				totalSeconds += parseInt(hours[2])
			}
			if (hours.length == 2) {
				totalSeconds += parseInt(hours[0]) * 60
				totalSeconds += parseInt(hours[1])
			}

			return totalSeconds
		}
	}

	function handleDeleteClick() {
		setLoading(true)
		setShowDelete(false)

		fetchDelete('/lives/' + id)
			.then(() => {
				alertsObserver.fire('success', `Live ${id} removida com sucesso`)
				setReadOnly(true)
			})
			.catch(err => {
				alertsObserver.fire('error', `Ao deletar live ${id}: ${err}`)
				setShowDelete(true)
			})
			.finally(() => setLoading(false))
	}

	async function handleSaveClick() {
		setLoading(true)

		let newThumbnail = thumbnail

		if (imageChanged && thumbnailInputRef.current && thumbnailInputRef.current.files) {
			const formData = new FormData()

			formData.append('image', thumbnailInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/image/user/0', {
					body: formData,
				})

				alertsObserver.fire('success', 'Imagem enviada com sucesso')

				setImageChanged(false)

				newThumbnail = json.filename

				setThumbnail(newThumbnail)
			} catch (err) {
				alertsObserver.fire('error', `Ao fazer upload da imagem: ${err}`)
				setLoading(false)
				return
			}
		}
		let durationInSeconds = convertDuration(durationMask, 'seconds')

		fetchPostOrPut('/lives', id, {
			body: JSON.stringify({
				title,
				date,
				thumbnail: newThumbnail,
				keywords,
				videoUrl,
				duration: durationInSeconds,
				teachersIds,
			}),
		})
			.then(data => alertsObserver.fire('success', `Live ${data.id} salva com sucesso`))
			.catch(err => alertsObserver.fire('error', `Ao salvar live ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files[0]) {
			const file = new FileReader()

			file.onload = function (e) {
				if (thumbnailImgRef.current) {
					thumbnailImgRef.current.src = e.target?.result as any
				}
			}

			file.readAsDataURL(event.target.files[0])

			setImageChanged(true)
		}
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin_lives)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle id={id} label="Live" loading={loading} allowDeletingButton={!readOnly && showDelete && !loading} onDeleteConfirmationClick={handleDeleteClick} />

			<div className="form-group">
				<label htmlFor="title">Título</label>
				<input
					type="text"
					className="form-control"
					value={title || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setTitle(event.target.value)}
					id="title"
					name="title"
					aria-describedby="title-help"
					placeholder="Deixe vazio para que seja o último"
				/>
				{/* <small id="title-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="date">Data</label>
				<input
					type="date"
					className="form-control"
					value={date || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setDate(event.target.value)}
					id="date"
					name="date"
					aria-describedby="date-help"
					placeholder="Deixe vazio para que seja o último"
				/>
				{/* <small id="date-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="keywords">Palavras-chave (separadas por vírgula)</label>
				<input
					type="text"
					className="form-control"
					value={keywords || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setKeywords(event.target.value)}
					id="keywords"
					name="keywords"
					aria-describedby="keywords-help"
					placeholder="Deixe vazio para que seja o último"
				/>
				{/* <small id="keywords-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<img ref={thumbnailImgRef} style={consts.imgThumbnailStyle} onClick={() => thumbnailInputRef.current?.click()} />
				<label htmlFor="thumbnail">Capa</label>
				<input
					ref={thumbnailInputRef}
					type="file"
					accept="image/*"
					className="form-control"
					style={{ display: 'none' }}
					required
					disabled={readOnly || loading}
					onChange={handleImageChange}
					id="thumbnail"
					name="thumbnail"
					aria-describedby="thumbnail-help"
				/>
				{/* <small id="thumbnail-help" className="form-text text-muted">{!id ? 'Necessário salvar antes de conseguir editar imagem' : ''}</small> */}
			</div>

			<div className="form-group">
				<label htmlFor="videoUrl">Url de vídeo</label>
				<label id="videoUrl-help" style={{ display: 'block' }} className="form-text text-danger">
					Atenção: deve ser o link dentro do "Código de incorporação" do Vimeo<br></br>Ex: https://player.vimeo.com/video/497311859<br></br>Cole o "Código de incorporação" que será extraído o link
				</label>
				<input
					type="text"
					className="form-control"
					value={videoUrl || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setVideoUrl(extractSrcFromIframe(event.target?.value) || event.target?.value)}
					id="videoUrl"
					name="videoUrl"
					aria-describedby="videoUrl-help"
				/>
			</div>
			<div className="form-group">
				<label htmlFor="videoUrl">Duração do video</label>
				<label id="videoUrl-help" style={{ display: 'block' }} className="form-text text-danger">
					Minutos e segundos separados por dois pontos<br></br>Ex: 49:59, 1:12:21
				</label>
				<input
					type="text"
					className="form-control"
					value={durationMask || ''}
					required
					disabled={readOnly || loading}
					id="videoUrl"
					name="videoUrl"
					aria-describedby="videoUrl-help"
					onChange={e => setDurationMask(e.target.value)}
				/>
			</div>

			<div className="form-group">
				<label htmlFor="teachers">Professores {loadingTeachers ? <LoadingIcon /> : null}</label>
				<Select
					options={teachersSelect}
					isMulti={true}
					value={selectedTeachers}
					styles={{ option: defaultSelectStyles }}
					isDisabled={loading || loadingTeachers}
					onChange={value => {
						setSelectedTeachers(value as any)
						setTeachersIds(((value || []) as unknown as { value: number }[]).map(el => el.value))
					}}
					name="teachers"
					id="teachers"
				/>
			</div>

			<div>
				<button
					type="button"
					className="btn btn-success"
					disabled={readOnly || loading || !title}
					onClick={() => {
						handleSaveClick()
					}}
				>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
