import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import consts from '../../consts'
import { fetchGet, fetchPostFile, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { FormRowTitle } from '../FormRowTitle'
import { LoadingIcon } from '../LoadingIcon'
import { User, UserType, UserTypeDesc } from './UserTypes'

export function UserForm() {
	const [loading, setLoading] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)
	const [recoveringPassword, setRecoveringPassword] = useState<boolean>(false)
	const [recoverPasswordMessage, setRecoverPasswordMessage] = useState<string | JSX.Element>('')

	const [address, setAddress] = useState<string>('')
	const [photo, setPhoto] = useState<string>('')
	const [cancellationRequestDate, setCancellationRequestDate] = useState<string>('')
	const [cep, setCep] = useState<string>('')
	const [city, setCity] = useState<string>('')
	const [complement, setComplement] = useState<string>('')
	const [country, setCountry] = useState<string>('')
	const [cpf, setCpf] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [emailHotmart, setEmailHotmart] = useState<string>('')
	const [idHotmart, setIdHotmart] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [number, setNumber] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [passwordPlainText, setPasswordPlainText] = useState<boolean>(false)
	const [phone, setPhone] = useState<string>('')
	const [type, setType] = useState<UserType>(UserType.FreeAccess)
	const [typeExpirationDate, setTypeExpirationDate] = useState<string>('')

	const [myList, setMyList] = useState<string[]>([])
	const [keepWatchingList, setKeepWatchingList] = useState<string[]>([])
	const [concludedList, setConcludedList] = useState<string[]>([])
	const [historyList, setHistoryList] = useState<string[]>([])

	const [redefinePassword, setRedefinePassword] = useState<boolean>(false)

	const [selectType, setSelectType] = useState<{ value: number; label: string }[]>([
		{ value: UserType.Admin, label: UserTypeDesc[UserType.Admin] },
		{ value: UserType.BonusAccess, label: UserTypeDesc[UserType.BonusAccess] },
		{ value: UserType.Canceled, label: UserTypeDesc[UserType.Canceled] },
		{ value: UserType.FreeAccess, label: UserTypeDesc[UserType.FreeAccess] },
		{ value: UserType.Subscriber, label: UserTypeDesc[UserType.Subscriber] },
		{ value: UserType.Suspended, label: UserTypeDesc[UserType.Suspended] },
		{ value: UserType.Teacher, label: UserTypeDesc[UserType.Teacher] },
	])
	const [selectedType, setSelectedType] = useState<{ value: number; label: string } | undefined>(selectType[3])

	const [imageChanged, setImageChanged] = useState<boolean>(false)

	const photoImgRef = useRef<HTMLImageElement>(null)
	const photoInputRef = useRef<HTMLInputElement>(null)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	useEffect(() => {
		let isMounted = true

		if (id) {
			setLoading(true)

			fetchGet<User>(consts.api_paths.users + '/' + id)
				.then(json => {
					if (!isMounted) return

					setName(json.name)
					setAddress(json.address)
					setCancellationRequestDate(json.cancellationRequestDate)
					setCep(json.cep)
					setCity(json.city)
					setComplement(json.complement)
					setCountry(json.country)
					setCpf(json.cpf)
					setEmail(json.email)
					setEmailHotmart(json.emailHotmart)
					setIdHotmart(json.idHotmart)
					setName(json.name)
					setNumber(json.number)
					setPhone(json.phone)
					setPhoto(json.photo)
					setType(json.type)
					setTypeExpirationDate(json.typeExpirationDate)
					setSelectedType(selectType.find(el => el.value === json.type))

					if (json.recoveryCode && new Date(json.recoveryCodeExpirationDate) > new Date()) {
						setRecoverPasswordMessage('Código de recuperação: ' + json.recoveryCode)
					}

					setHistoryList(json.historyList || [])
					setKeepWatchingList(json.keepWatchingList || [])
					setConcludedList(json.concludedList || [])
					setHistoryList(json.historyList || [])

					if (photoImgRef.current && json.photo) {
						photoImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + json.photo
					}
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Usuário ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		}

		return () => {
			isMounted = false
		}
	}, [])

	async function handleSaveClick() {
		setLoading(true)

		let newPhoto = ''

		if (imageChanged && photoInputRef.current && photoInputRef.current.files) {
			const formData = new FormData()

			formData.append('image', photoInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/image/user/0', {
					body: formData,
				})

				alertsObserver.fire('success', 'Imagem enviada com sucesso')

				setImageChanged(false)

				newPhoto = json.filename

				setPhoto(newPhoto)
			} catch (err) {
				alertsObserver.fire('error', `Ao fazer upload da imagem: ${err}`)
				setLoading(false)
				return
			}
		}

		fetchPostOrPut(consts.api_paths.users, id, {
			body: JSON.stringify({
				address,
				photo: newPhoto || photo,
				cep,
				city,
				complement,
				country,
				cpf,
				email,
				emailHotmart,
				idHotmart,
				passwordPlainText,
				name,
				number,
				password,
				phone,
				type,
				typeExpirationDate,
			}),
		})
			.then(data => alertsObserver.fire('success', `Usuário ${data.id} salvo com sucesso`))
			.catch(err => alertsObserver.fire('error', `Ao salvar Usuário ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	function handleDeleteClick() {
		//
	}

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files[0]) {
			const file = new FileReader()

			file.onload = function (e) {
				if (photoImgRef.current) {
					photoImgRef.current.src = e.target?.result as any
				}
			}

			file.readAsDataURL(event.target.files[0])

			setImageChanged(true)
		}
	}

	function handleResendAccessClick() {
		setRecoveringPassword(true)
		setRecoverPasswordMessage(<LoadingIcon />)

		fetchGet<{ password: string }>(consts.api_paths.users_set_random_password + '/' + id)
			.then(res => {
				setRecoverPasswordMessage(res.password)
				setPasswordPlainText(true)
			})
			.catch(err => setRecoverPasswordMessage(err))
			.finally(() => setRecoveringPassword(false))
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin_users)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle id={id} label="Usuário" loading={loading} allowDeletingButton={!readOnly && showDelete && !loading} onDeleteConfirmationClick={handleDeleteClick} />

			<div className="form-group">
				<img ref={photoImgRef} style={{ height: '100px', width: '100px', display: 'block', border: '1px solid white', cursor: 'pointer' }} onClick={() => photoInputRef.current?.click()} />
				<label htmlFor="photo">Foto</label>
				<input
					ref={photoInputRef}
					type="file"
					accept="image/*"
					className="form-control"
					style={{ display: 'none' }}
					required
					disabled={readOnly || loading}
					onChange={handleImageChange}
					id="photo"
					name="photo"
					aria-describedby="photo-help"
				/>
				{/* <small id="photo-help" className="form-text text-muted">{!id ? 'Necessário salvar antes de conseguir editar imagem' : ''}</small> */}
			</div>

			<div className="form-group">
				<label htmlFor="idHotmart">Id Hortmart</label>
				<input
					type="text"
					className="form-control"
					value={idHotmart || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setIdHotmart(event.target.value)}
					id="idHotmart"
					name="idHotmart"
					aria-describedby="idHotmart-help"
				/>
				{/* <small id="idHotmart-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="name">Nome</label>
				<input
					type="text"
					className="form-control"
					value={name || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setName(event.target.value)}
					id="name"
					name="name"
					aria-describedby="name-help"
				/>
				{/* <small id="name-help" className="form-text text-muted"></small> */}
			</div>
			<div className="double-input-inline">
				<div className="form-group">
					<label htmlFor="cpf">CPF</label>
					<input
						type="text"
						className="form-control"
						value={cpf || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setCpf(event.target.value)}
						id="cpf"
						name="cpf"
						aria-describedby="cpf-help"
					/>
					{/* <small id="cpf-help" className="form-text text-muted"></small> */}
				</div>

				<div className="form-group">
					<label htmlFor="phone">Telefone</label>
					<input
						type="text"
						className="form-control"
						value={phone || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setPhone(event.target.value)}
						id="phone"
						name="phone"
						aria-describedby="phone-help"
					/>
					{/* <small id="phone-help" className="form-text text-muted"></small> */}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="emailHotmart">Email Hotmart</label>
				<input
					type="text"
					className="form-control"
					value={emailHotmart || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmailHotmart(event.target.value)}
					id="emailHotmart"
					name="emailHotmart"
					aria-describedby="emailHotmart-help"
				/>
				{/* <small id="emailHotmart-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="email">Email Sistema</label>
				<input
					type="text"
					className="form-control"
					value={email || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setEmail(event.target.value)}
					id="email"
					name="email"
					aria-describedby="email-help"
				/>
				{/* <small id="email-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="country">País</label>
				<input
					type="text"
					className="form-control"
					value={country || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setCountry(event.target.value)}
					id="country"
					name="country"
					aria-describedby="country-help"
				/>
				{/* <small id="country-help" className="form-text text-muted"></small> */}
			</div>

			<div className="double-input-inline">
				<div className="form-group">
					<label htmlFor="cep">CEP</label>
					<input
						type="text"
						className="form-control"
						value={cep || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setCep(event.target.value)}
						id="cep"
						name="cep"
						aria-describedby="cep-help"
					/>
					{/* <small id="cep-help" className="form-text text-muted"></small> */}
				</div>

				<div className="form-group">
					<label htmlFor="city">Cidade</label>
					<input
						type="text"
						className="form-control"
						value={city || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setCity(event.target.value)}
						id="city"
						name="city"
						aria-describedby="city-help"
					/>
					{/* <small id="city-hFelp" className="form-text text-muted"></small> */}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="address">Endereço</label>
				<input
					type="text"
					className="form-control"
					value={address || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setAddress(event.target.value)}
					id="address"
					name="address"
					aria-describedby="address-help"
				/>
				{/* <small id="address-help" className="form-text text-muted"></small> */}
			</div>

			<div className="double-input-inline">
				<div className="form-group">
					<label htmlFor="number">Número</label>
					<input
						type="text"
						className="form-control"
						value={number || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setNumber(event.target.value)}
						id="number"
						name="number"
						aria-describedby="number-help"
					/>
					{/* <small id="number-help" className="form-text text-muted"></small> */}
				</div>

				<div className="form-group">
					<label htmlFor="complement">Complemento</label>
					<input
						type="text"
						className="form-control"
						value={complement || ''}
						required
						disabled={readOnly || loading}
						onChange={event => setComplement(event.target.value)}
						id="complement"
						name="complement"
						aria-describedby="complement-help"
					/>
					{/* <small id="complement-help" className="form-text text-muted"></small> */}
				</div>
			</div>

			<div className="form-group">
				<label htmlFor="password">Senha</label>
				<label htmlFor="password" style={{ display: 'block' }} className="text-danger">
					Altere manualmente a senha APENAS em últimos casos, dê preferência a segurança e privacidade do usuário!
				</label>
				<input
					type="text"
					className="form-control"
					value={password || ''}
					required
					disabled={readOnly || loading || !redefinePassword}
					onChange={event => setPassword(event.target.value)}
					id="password"
					name="password"
					aria-describedby="password-help"
					placeholder={!redefinePassword ? 'Para desbloquear o campo, clique em REDEFINIR SENHA' : ''}
				/>
				<div className="flex gap-lg" style={{ marginTop: '10px' }}>
					<button type="button" className="btn btn-danger" disabled={readOnly || loading || redefinePassword || !id} onClick={() => setRedefinePassword(true)}>
						REDEFINIR SENHA
					</button>
					<button type="button" className="btn btn-success" disabled={readOnly || loading || !id || recoveringPassword} onClick={handleResendAccessClick}>
						REENVIAR ACESSO
					</button>
				</div>
				{recoverPasswordMessage ? (
					<p style={{ marginTop: '10px' }} className="alert alert-warning">
						{recoverPasswordMessage}
					</p>
				) : null}
				{/* <small id="password-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="type">Status</label>
				<Select
					id="type"
					name="type"
					options={selectType}
					value={selectedType}
					styles={{ option: (styles, info) => ({ ...styles, color: info.data.value === UserType.Admin ? 'black' : 'black' }) }}
					onChange={opt => {
						setSelectedType(opt || undefined)
						setType(prev => opt?.value || prev)
					}}
				></Select>
				{/* <small id="type-help" className="form-text text-muted"></small> */}
				<button type="button" className="btn btn-danger" disabled={readOnly || loading || redefinePassword || !id} style={{ marginTop: '10px' }}>
					CANCELAR ASSINATURA NO HOTMART
				</button>
			</div>

			<div className="form-group">
				<label htmlFor="typeExpirationDate">Data de expiração do Status</label>
				<input
					type="date"
					className="form-control"
					value={typeExpirationDate || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setTypeExpirationDate(event.target.value)}
					id="typeExpirationDate"
					name="typeExpirationDate"
					aria-describedby="typeExpirationDate-help"
				/>
				{/* <small id="typeExpirationDate-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="cancellationRequestDate">Data de requisição de cancelamento</label>
				<input
					type="date"
					className="form-control"
					value={cancellationRequestDate || ''}
					required
					disabled={true}
					onChange={event => setCancellationRequestDate(event.target.value)}
					id="cancellationRequestDate"
					name="cancellationRequestDate"
					aria-describedby="cancellationRequestDate-help"
				/>
				{/* <small id="cancellationRequestDate-help" className="form-text text-muted"></small> */}
			</div>

			<hr />

			<div className="form-group">
				<label htmlFor="myList">Minha Lista</label>
				<textarea className="form-control form-control-sm" value={myList.join(', ')} required disabled rows={2} id="myList" name="myList" aria-describedby="myList-help" />
				{/* <small id="myList-help" className="form-text text-muted"></small> */}
			</div>

			<div className="form-group">
				<label htmlFor="keepWatchingList">Continuar Assistindo</label>
				<textarea
					className="form-control form-control-sm"
					value={keepWatchingList.join(', ')}
					required
					disabled
					rows={2}
					id="keepWatchingList"
					name="keepWatchingList"
					aria-describedby="keepWatchingList-help"
				/>
				{/* <small id="keepWatchingList-help" className="form-text text-muted"></small> */}
			</div>

			<h3 className="m-auto">Histórico do Status na EVAED</h3>

			<div className="form-group">
				{/* <label htmlFor="historyList">Continuar Assistindo</label> */}
				<textarea className="form-control" value={historyList.join('\n')} required disabled rows={5} id="historyList" name="historyList" aria-describedby="historyList-help" />
				{/* <small id="historyList-help" className="form-text text-muted"></small> */}
			</div>

			<div>
				<button type="button" className="btn btn-success" disabled={readOnly || loading || !name} onClick={() => handleSaveClick()}>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
