import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import consts from '../consts'
import { fetchGet } from '../fetch'
import { appPath } from '../routes'
import { GlobalContext } from './GlobalContext'

export function Footer() {
	const { systemConfig } = useContext(GlobalContext)
	const [version, setVersion] = useState('')

	useEffect(() => {
		fetchGet<{ version: number }>('/version')
			.then(res => {
				setVersion(`s${res.version}.h${getScriptVersion()}.f${consts.version}`)
			})
			.catch(err => {
				setVersion(`err.h${getScriptVersion()}.f${consts.version}`)
			})
	}, [])

	return (
		<footer className="footer flex content-center items-center wrap flex-column">
			<img className="evaed-logo evaed-logo-footer" style={{ width: 'auto' }} src={`${consts.APP_URL}/logo.png`} alt="EVAED" />
			<section className="flex wrap space-around content-center items-center wrap">
				<a className="text-center mr-10 mb-10" href="https://evaed.lgpd.express/app/policy" target="_blank">
					Política de Privacidade
				</a>
				<Link to={appPath(consts.app_paths.support)} className="text-center mr-10 mb-10">
					Entre em contato
				</Link>
				<div className="flex wrap space-around content-center items-center mr-10 mb-10">
					<a className="text-center mr-10" href={systemConfig.facebookLink} target="_blank" style={!systemConfig.facebookLink ? { opacity: 0.5 } : {}}>
						<i className="fab fa-facebook-square"></i>
					</a>
					<a className="text-center mr-10" href={systemConfig.instragramLink} target="_blank" style={!systemConfig.instragramLink ? { opacity: 0.5 } : {}}>
						<i className="fab fa-instagram-square"></i>
					</a>
					<a className="text-center mr-10" href={systemConfig.twitterLink} target="_blank" style={!systemConfig.twitterLink ? { opacity: 0.5 } : {}}>
						<i className="fab fa-twitter-square"></i>
					</a>
					<a className="text-center" href={systemConfig.youtubeLink} target="_blank" style={!systemConfig.youtubeLink ? { opacity: 0.5 } : {}}>
						<i className="fab fa-youtube-square"></i>
					</a>
				</div>
			</section>
			<p className="text-center">EVAED.ORG {new Date().getFullYear()} © | Todos os direitos reservados</p>
			{version && (
				<p className="text-center text-muted" style={{ marginTop: 5 }}>
					versão {version}
				</p>
			)}
		</footer>
	)
}

function getScriptVersion() {
	try {
		return (
			Array.from(document.querySelectorAll<HTMLScriptElement>('body script'))
				?.find(r => r?.src?.includes('/evaed-app/app'))
				?.src?.split('?version=')[1] || '0'
		)
	} catch (e) {
		return 'err'
	}
}
