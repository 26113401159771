import { ChangeEvent, KeyboardEvent, MouseEvent, useReducer, useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import consts from '../../consts'
import { useEffectAsync } from '../../custom-hooks'
import { fetchGet } from '../../fetch'
import { appPath } from '../../routes'
import { PaginationState, SortType } from '../../types'
import { defaultCatch, getNextSortOrder, getSortOrderForRequest } from '../../utils'
import { LoadingButton } from '../LoadingButton'
import { TableSortArrow } from '../TableSortArrow'
import { Teacher } from './TeacherTypes'

export type TeacherTableSortFields = {
	order: SortType
	name: SortType
	phone: SortType
	email: SortType
}

type SortField = keyof TeacherTableSortFields

export type TeacherResponse = [Teacher[], number]

export function TeacherGrid() {
	const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0)
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationState>(consts.paginationStateDefault)
	const [sortOrder, setSortOrder] = useState<TeacherTableSortFields>({ order: 'asc', name: 'none', phone: 'none', email: 'none' })
	const [inputFilter, setInputFilter] = useState<string>('')

	const [teachers, setTeachers] = useState<Teacher[]>([])
	const [originalTeachers, setOriginalTeachers] = useState<Teacher[]>([])

	useEffectAsync<TeacherResponse>(
		() => {
			setLoading(true)
			return fetchGet(consts.api_paths.teachers, {
				_page: pagination.activePage,
				_filter: pagination.filter,
				_order: getSortOrderForRequest(sortOrder),
			})
		},
		handleResponse,
		defaultCatch,
		setLoadingFalse,
		[forceUpdate, pagination.activePage, pagination.filter, sortOrder]
	)

	function setLoadingFalse() {
		setLoading(false)
	}

	function handleReloadClick() {
		setForceUpdate()
	}

	function handlePaginationChange(page: number) {
		setPagination(prev => ({ ...prev, activePage: page }))
	}

	function handleResponse(data: TeacherResponse) {
		setStateTeachers(data[0], pagination.filter)
		setPagination(prev => ({
			...prev,
			totalCount: data[1],
		}))
	}

	function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
		const filter = event.target.value

		setInputFilter(filter)

		setStateTeachers(originalTeachers, filter)
	}

	function handleSortOrderClick(event: MouseEvent<HTMLTableHeaderCellElement>, field: SortField) {
		if (event.currentTarget.style.cursor === 'not-allowed') {
			return
		}

		const nextSort = getNextSortOrder(sortOrder[field])

		const newSortOrder = { ...sortOrder }
		newSortOrder[field] = nextSort

		setSortOrder(newSortOrder)
	}

	function handleSearchKeyPress(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			setPagination(prev => ({ ...prev, activePage: 1, filter: inputFilter }))
			setForceUpdate()
		}
	}

	function setStateTeachers(newTeachers: Teacher[], filter: string) {
		setOriginalTeachers(newTeachers)
		setTeachers(getFilteredTeachers(newTeachers, filter))
	}

	function getFilteredTeachers(teachers: Teacher[], filter: string) {
		if (filter.trim() === '') {
			return teachers.slice()
		}

		filter = filter.toUpperCase()

		return teachers.filter(
			teacher =>
				teacher.email?.toString().toUpperCase().includes(filter) ||
				teacher.order?.toString().toUpperCase().includes(filter) ||
				teacher.name.toString().toUpperCase().includes(filter) ||
				teacher.phone?.toString().toUpperCase().includes(filter)
		)
	}

	return (
		<section className="w-100 flex flex-column gap-sm">
			<h1 className="text-primary font-md">Cadastro de Professores</h1>

			<div className="flex wrap gap-sm space-between">
				<div className="flex gap-sm align-items-center">
					<LoadingButton animated={loading} disabled={loading} onClick={handleReloadClick} />
					<span>|</span>
					<Link to={appPath(consts.app_paths.admin_teachers_new)}>
						<button className="btn btn-success">
							<i className="fas fa-plus"></i> Adicionar
						</button>
					</Link>
				</div>
				<div>
					<div className="input-group">
						<input type="text" className="form-control" disabled={loading} value={inputFilter} onChange={handleSearchChange} onKeyPress={handleSearchKeyPress} />
						<i className="fa fa-search input-addon" />
					</div>
				</div>
			</div>

			<table className="table w-100">
				<thead>
					<tr>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'order')}>
							<div className="flex space-between">
								<span>Ordem</span>
								<TableSortArrow order={sortOrder.order} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'name')}>
							<div className="flex space-between">
								<span>Nome</span>
								<TableSortArrow order={sortOrder.name} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'phone')}>
							<div className="flex space-between">
								<span>Telefone</span>
								<TableSortArrow order={sortOrder.phone} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'email')}>
							<div className="flex space-between">
								<span>Email</span>
								<TableSortArrow order={sortOrder.email} />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{teachers.map(teacher => (
						<tr key={teacher.id}>
							<td>
								<Link to={appPath(consts.app_paths.admin_teachers_id, { id: teacher.id })}>{teacher.order}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_teachers_id, { id: teacher.id })}>{teacher.name}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_teachers_id, { id: teacher.id })}>{teacher.phone}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_teachers_id, { id: teacher.id })}>{teacher.email}</Link>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				activePage={pagination.activePage}
				itemsCountPerPage={pagination.countPerPage}
				totalItemsCount={pagination.totalCount}
				pageRangeDisplayed={pagination.range}
				onChange={handlePaginationChange}
			/>
		</section>
	)
}
