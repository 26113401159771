import { useEffect, useRef, useState } from 'react'

export function InstallButton() {
	const [visible, setVisible] = useState(false)

	const installEventRef = useRef<(Event & { prompt: () => void; userChoice: Promise<any> }) | null>(null)

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', (e: { preventDefault: () => void }) => {
			e.preventDefault()

			setVisible(true)

			installEventRef.current = e as any
		})
	}, [])

	return (
		<div style={{ display: visible ? 'flex' : 'none', justifyContent: 'space-between', width: '100%' }}>
			<button
				className="btn btn-success"
				style={{ position: 'absolute', top: 1, left: 1 }}
				onClick={() => {
					setVisible(false)

					installEventRef.current?.prompt()

					installEventRef.current?.userChoice.then(choiceResult => {}).catch(() => {})
				}}
			>
				Instalar
			</button>

			<button
				className="btn btn-warning"
				style={{ position: 'absolute', top: 1, right: 1 }}
				onClick={() => {
					setVisible(false)
				}}
			>
				Usar navegador
			</button>
		</div>
	)
}
