import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import consts from '../consts'
import { fetchGet } from '../fetch'
import { navigateToParamlessModal, removeAccents } from '../utils'
import { CarouselConcreteCases } from './CarouselConcreteCases'
import { CategorySortType } from './cruds/CategoryTypes'
import { UserType } from './cruds/UserTypes'
import { GlobalContext, LoggedUserInfo, SystemConfigInfo } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { CategoryForHomePage, ConcreteCaseForCarousel, UserWatchListType } from './MainBodyTypes'

type ConcreteCaseWithFlagUsed = ConcreteCaseForCarousel & { used: boolean }

export function MainBodyConcreteCases() {
	const { user, rawCategories, rawBonuses, concreteCasesMasterList, livesMasterList, loadingAll, filter, setFilter, systemConfig } = useContext(GlobalContext)
	const history = useHistory()
	const location = useLocation()
	const [focusedCC, setFocusedCC] = useState(() => ({
		extId: Number(new URLSearchParams(location.search).get('ext')),
		id: null,
		urlIsExternal: null,
		introVideo: null,
		thumbnail: null,
	}))

	useEffect(() => {
		if (!focusedCC.extId) return

		fetchGet<typeof focusedCC>('/concrete-cases/external-cc/' + focusedCC.extId).then(cc => {
			setFocusedCC(prev => ({ ...prev, ...cc }))
		})
	}, [focusedCC.extId])

	const categoriesToDisplay =
		// user?.type === UserType.BonusAccess
		// ? rawCategories.filter(c => c.id !== consts.categorySpotlightId)
		// :
		user ? rawCategories : rawCategories.filter(cat => !consts.categoriesForUser.find(id => id === cat.id))

	// uses the used flag so if it belongs to another category it isn't displayed anymore
	let concreteCases: ConcreteCaseWithFlagUsed[] = concreteCasesMasterList.map(cc => ({ ...cc, used: false }))

	const filterUpperCase = removeAccents(filter.trim().toUpperCase())

	if (filterUpperCase) {
		concreteCases = concreteCases.filter(
			cc =>
				removeAccents(cc.title.toUpperCase()).includes(filterUpperCase) ||
				removeAccents(cc.description.toUpperCase()).includes(filterUpperCase) ||
				cc.modules.find(m => removeAccents(m.title.toUpperCase()).includes(filterUpperCase) || removeAccents(m.description.toUpperCase()).includes(filterUpperCase)) ||
				removeAccents(cc.teacher.name.toUpperCase()).includes(filterUpperCase) ||
				removeAccents(cc.category.label.toUpperCase()).includes(filterUpperCase)
			// ||
			// cc.categoryConcreteCases.find(ccc => removeAccents(rawCategories.find(c => c.id === ccc.categoryId)?.label?.toUpperCase() || '').includes(filterUpperCase))
		)
	}

	const areLivesFiltered =
		filterUpperCase &&
		livesMasterList.find(
			live =>
				removeAccents(live.title.toUpperCase()).includes(filterUpperCase) ||
				removeAccents(live.keywords.toUpperCase()).includes(filterUpperCase) ||
				removeAccents(live.teachersNames.toUpperCase()).includes(filterUpperCase)
		)

	const allPendingCommentsCount = (user?.type === UserType.Admin && concreteCasesMasterList.reduce((agg, cc) => agg + cc.pendingCommentsCount, 0)) || 0

	return (
		<main className="main">
			<section className="flex-column gap-lg">
				{loadingAll && rawCategories.length === 0 ? <LoadingIcon style={{ color: 'gray' }} /> : null}
				{focusedCC.extId > 0 && focusedCC.id > 0 && (
					<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<a style={{ maxHeight: 700, height: '100%' }} href={focusedCC.introVideo} rel="noopener noreferrer" target="_blank">
							<img src={consts.DYNAMIC_FILES_URL + '/' + focusedCC.thumbnail} alt="" />
						</a>
					</div>
				)}
				{categoriesToDisplay.map(cat => (
					<CarouselConcreteCases
						key={cat.id}
						id={cat.id}
						label={cat.label}
						labelFontSize={24}
						size={cat.id === consts.categorySpotlightId ? 'lg' : 'md'}
						concreteCases={filterConcreteCasesByCategory(user, concreteCases, cat.id, rawCategories, rawBonuses, filter, systemConfig)}
						allPendingCommentsCount={allPendingCommentsCount}
					/>
				))}

				{filterUpperCase && !areLivesFiltered && concreteCases.length === 0 && <h2 className="ml-10 mt-10">Nenhum resultado para a pesquisa "{filter.trim()}"</h2>}

				{filterUpperCase && (
					<div style={{ margin: 20, maxWidth: '40ch' }}>
						{areLivesFiltered && (
							<button
								type="button"
								className="btn btn-success"
								style={{ display: 'block', width: '100%', marginTop: 30, padding: '10px 20px' }}
								onClick={() => navigateToParamlessModal(history, 'lives')}
							>
								VER RESULTADO NAS LIVES
							</button>
						)}
						<button type="button" className="btn btn-danger" style={{ display: 'block', width: '100%', marginTop: 30, padding: '10px 20px' }} onClick={() => setFilter('')}>
							LIMPAR PESQUISA
						</button>
					</div>
				)}
			</section>
		</main>
	)
}

function filterConcreteCasesByCategory(
	user: LoggedUserInfo | null,
	concreteCases: ConcreteCaseWithFlagUsed[],
	categoryId: number,
	categories: CategoryForHomePage[],
	bonuses: number[],
	filter: string,
	systemConfig: SystemConfigInfo
): ConcreteCaseForCarousel[] {
	let ccs =
		categoryId === consts.categorySpotlightId
			? concreteCases.filter(cc => cc.categoryConcreteCases.find(ccc => ccc.categoryId === consts.categorySpotlightId))
			: categoryId === consts.categoryPopularId
			? concreteCases
			: categoryId === consts.categoryBonusesId
			? user?.type === UserType.BonusAccess
				? concreteCases.filter(cc => bonuses.find(b => b === cc.id))
				: []
			: categoryId === consts.categoryPendingCommentsId && (user?.type === UserType.Admin || user?.type === UserType.Teacher)
			? concreteCases.filter(cc => cc.pendingCommentsCount > 0 && (user?.type === UserType.Admin || (user?.type === UserType.Teacher && cc.teacher.id === user.teacherId)))
			: categoryId === consts.categoryNewReleasesId
			? concreteCases
			: categoryId === consts.categoryKeepWatchingId
			? concreteCases.filter(cc => cc.userWatchInfo && cc.userWatchInfo.duration > 0 && cc.userWatchInfo.concluded === false)
			: categoryId === consts.categoryMyListId
			? concreteCases.filter(cc => cc.userWatchInfo?.type === UserWatchListType.MyList).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
			: categoryId === consts.categoryConcludedId
			? concreteCases.filter(cc => cc.userWatchInfo?.concluded === true)
			: filter
			? concreteCases.filter(cc => cc.category.id === categoryId)
			: concreteCases.filter(cc => cc.category.id === categoryId || cc.categoryConcreteCases.find(ccc => ccc.categoryId === categoryId))

	ccs = sortConcreteCases(ccs, categoryId, categories.find(c => c.id === categoryId)?.sortType)

	if (filter) {
		ccs = ccs.filter(cc => !cc.used)
	}

	if (categoryId === consts.categoryNewReleasesId) {
		ccs = ccs.slice(0, 20)
	}

	if (filter && consts.categoriesFixed.findIndex(catId => catId === categoryId) < 0) {
		ccs.forEach(cc => (cc.used = true))
	}

	if (!filter && categoryId === consts.categorySpotlightId && systemConfig.spotlightVideoUrl) {
		ccs = [{ id: -666999, introVideo: systemConfig.spotlightVideoUrl, modules: [], description: systemConfig.aboutText, title: '', thumbnail: 'logo16x9.png' } as any, ...ccs]
	}

	return ccs
}

function sortConcreteCases(ccs: ConcreteCaseWithFlagUsed[], categoryId: number, sortType?: CategorySortType) {
	switch (sortType) {
		case CategorySortType.DateCreatedAsc:
			return ccs.sort((a, b) => {
				if (a.category.id === categoryId && b.category.id !== categoryId) return -1
				if (a.category.id !== categoryId && b.category.id === categoryId) return 1

				const dateA = categoryId === consts.categoryMyListId ? a.userWatchInfo?.date || '2000-01-01' : a.date
				const dateB = categoryId === consts.categoryMyListId ? b.userWatchInfo?.date || '2000-01-01' : b.date

				return new Date(dateA).getTime() - new Date(dateB).getTime()
			})
		case CategorySortType.DateCreatedDesc:
			return ccs.sort((a, b) => {
				if (a.category.id === categoryId && b.category.id !== categoryId) return -1
				if (a.category.id !== categoryId && b.category.id === categoryId) return 1

				const dateA = categoryId === consts.categoryMyListId ? a.userWatchInfo?.date || '2000-01-01' : a.date
				const dateB = categoryId === consts.categoryMyListId ? b.userWatchInfo?.date || '2000-01-01' : b.date

				return new Date(dateB).getTime() - new Date(dateA).getTime()
			})
		case CategorySortType.MostViewedAsc:
			return ccs.sort((a, b) => {
				if (a.category.id === categoryId && b.category.id !== categoryId) return -1
				if (a.category.id !== categoryId && b.category.id === categoryId) return 1

				return a.viewCount - b.viewCount
			})
		case CategorySortType.MostViewedDesc:
			return ccs.sort((a, b) => {
				if (a.category.id === categoryId && b.category.id !== categoryId) return -1
				if (a.category.id !== categoryId && b.category.id === categoryId) return 1

				return b.viewCount - a.viewCount
			})
		default:
			return ccs
	}
}
