import { marked } from 'marked'
import { CSSProperties, useContext, useEffect, useReducer, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { usePopperTooltip } from 'react-popper-tooltip'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory, useParams } from 'react-router-dom'
import consts from '../consts'
import { fetchGet, fetchPost } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import concreteCaseChangeObserver, { ConcreteCaseChangeObserverShooter } from '../observers/concrete-case-change-observer'
import videoPreviewObserver from '../observers/video-preview-observer'
import { navigateBackFromModal, navigateToLogin, renderScore, secondsToTimeMMSS } from '../utils'
import { UserType } from './cruds/UserTypes'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { Comment, CommentStatus, ConcreteCaseForCarousel, UserWatchInfo, UserWatchListType } from './MainBodyTypes'
import { Modal } from './modals/Modal'
import { Score } from './Score'
import { Video } from './Video'

export function ConcreteCaseModal() {
	const history = useHistory<{ t: number; comments: boolean }>()

	const [forceCommentsUpdate, setForceCommentsUpdate] = useReducer(x => x + 1, 0)
	const [concreteCase, setConcreteCase] = useState<ConcreteCaseForCarousel | null>(null)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [playing, setPlaying] = useState(true)
	const [muted, setMuted] = useState(false)
	const [loadingAddRemoveMyList, setLoadingAddRemoveMyList] = useState(false)
	const [loadingModules, setLoadingModules] = useState(false)
	const [updatingScore, setUpdatingScore] = useState(false)
	const [showingComments, setShowingComments] = useState(false)
	const [showingCommentsMessage, setShowingCommentsMessage] = useState(false)
	// const [showingScoreForm, setShowingScoreForm] = useState(false)
	const [loadingComments, setLoadingComments] = useState(false)
	const [comments, setComments] = useState<Comment[]>([])
	const [videoUrl, setVideoUrl] = useState('')
	const [commentToAdd, setCommentToAdd] = useState('')
	const [showCommentToAdd, setShowCommentToAdd] = useState(false)
	const [submitingComment, setSubmitingComment] = useState(false)
	const [volume, setVolume] = useState(1)
	const [videoOutsideView, setVideoOutsideView] = useState(false)
	const [showingPreview, setShowingPreview] = useState(false)
	const [showingLogin, setShowingLogin] = useState(false)
	// const [isFullscreen, setIsFullscreen] = useState(false)
	const [videoSubtitle, setVideoSubtitle] = useState('Introdução')
	const [updatingCommentStatus, setUpdatingCommentStatus] = useState(false)
	const [showScoreNotSet, setShowScoreNotSet] = useState(false)
	const [similarCases, setSimilarCases] = useState<ConcreteCaseForCarousel[]>([])

	const playerRef = useRef<VimeoPlayer>(null)
	const playerWrapperRef = useRef<HTMLDivElement>(null)
	const timeToSkipToRef = useRef(0)
	const lastUpdateWatchInfo = useRef<{ userWatchConcreteCase: UserWatchInfo; userWatchModule?: UserWatchInfo; ccId: number } | null>(null)
	const lastTimeUpdateRef = useRef(new Date())
	const currentModuleIdRef = useRef<number | null>(null)
	const modalRef = useRef<HTMLElement>(null)
	const requestFullscreenOnReadyRef = useRef(false)

	const params = useParams<{ id?: string }>()
	const id = parseInt(params?.id as any)
	const [ccId, setCcId] = useState(parseInt(params?.id as any) || 0)
	const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	const {
		getArrowProps: getArrowPropsForMyListTooltip,
		getTooltipProps: getTooltipPropsForMyListTooltip,
		setTooltipRef: setTooltipRefForMyListTooltip,
		setTriggerRef: setTriggerRefForMyListTooltip,
		visible: visibleForMyListTooltip,
	} = usePopperTooltip()

	const topElementRef = useRef<HTMLElement>(null)
	const { user, concreteCasesMasterList, setConcreteCasesMasterList, loadingAll } = useContext(GlobalContext)

	// useEffect(() => {
	// 	function handleKeyDown(e: KeyboardEvent) {
	// 		if (e.key === 'Escape' && showingScoreForm) {
	// 			e.stopPropagation()

	// 			setShowingScoreForm(false)
	// 		}
	// 	}

	// 	modalRef.current?.addEventListener('keydown', handleKeyDown)

	// 	return () => {
	// 		modalRef.current?.removeEventListener('keydown', handleKeyDown)
	// 	}
	// }, [showingScoreForm])

	// useEffect(() => {
	// 	if (filter) setPlaying(false)
	// }, [filter])

	useEffect(() => {
		function watchFunction(cc: any) {
			setShowingPreview(!!cc)

			// if (!cc) setPlaying(false)
		}

		videoPreviewObserver.subscribeConcreteCase(watchFunction)

		const unregister = history.listen(() => {
			setCcId(parseInt(window.location.pathname.split('concrete-case/')[1]) || 0)

			setShowingLogin(window.location.pathname.endsWith(consts.app_paths.login) || window.location.pathname.endsWith(consts.app_paths.login + '/'))
		})

		return () => {
			videoPreviewObserver.unsubscribeConcreteCase(watchFunction)
			unregister()
		}
	}, [])

	useEffect(() => {
		function handleKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape' && showingCommentsMessage) {
				e.stopPropagation()

				setShowingCommentsMessage(false)
			}
		}

		modalRef.current?.addEventListener('keydown', handleKeyDown)

		return () => {
			modalRef.current?.removeEventListener('keydown', handleKeyDown)
		}
	}, [showingCommentsMessage])

	useEffect(() => {
		function handleScroll() {
			scrollTimeoutRef.current && clearTimeout(scrollTimeoutRef.current)

			scrollTimeoutRef.current = setTimeout(() => {
				if (modalRef.current) {
					setVideoOutsideView(modalRef.current.scrollTop > 200)
				}
			}, 0)
		}

		modalRef.current?.addEventListener('scroll', handleScroll)

		return () => {
			modalRef.current?.removeEventListener('scroll', handleScroll)
		}
	}, [modalRef.current, playing, videoOutsideView])

	useEffect(() => {
		if (!playerWrapperRef.current) {
			return
		}

		function handleFullScreenChange(e: Event) {
			if (playerWrapperRef.current) {
				if (document.fullscreenElement) {
					playerWrapperRef.current?.classList.add('fullscreen')

					if (window.screen?.orientation?.lock) window.screen?.orientation?.lock('landscape').then(console.log).catch(console.log)
				} else {
					playerWrapperRef.current?.classList.remove('fullscreen')

					if (window.screen?.orientation?.unlock) window.screen?.orientation?.unlock()
				}

				// setIsFullscreen(!!document.fullscreenElement)
			}
		}

		playerWrapperRef.current.addEventListener('fullscreenchange', handleFullScreenChange)

		return () => {
			playerWrapperRef.current?.removeEventListener('fullscreenchange', handleFullScreenChange)
		}
	}, [playerWrapperRef.current])

	useEffect(() => {
		let isMounted = true

		const newConcreteCase = concreteCasesMasterList.find(cc => cc.id === id)

		if (newConcreteCase) {
			if (concreteCase?.id !== newConcreteCase.id) {
				setVideoUrl(newConcreteCase.introVideo)

				if (lastUpdateWatchInfo.current) {
					setConcreteCasesMasterList(prev =>
						prev.map(cc =>
							cc.id === lastUpdateWatchInfo.current?.ccId
								? {
										...cc,
										userWatchInfo: lastUpdateWatchInfo.current?.userWatchConcreteCase,
										modules: lastUpdateWatchInfo.current?.userWatchModule
											? cc.modules.map(mod => (mod.id === currentModuleIdRef.current ? { ...mod, userWatchInfo: lastUpdateWatchInfo.current?.userWatchModule } : mod))
											: cc.modules,
								  }
								: cc
						)
					)

					lastUpdateWatchInfo.current = null
				}
			}

			if (history.location?.state?.comments) setShowingComments(true)
			if (history.location?.state?.comments) setPlaying(false)
			setConcreteCase(newConcreteCase)
			setSimilarCases(concreteCasesMasterList.filter(cc => cc.category.id === newConcreteCase.category.id && cc.id !== newConcreteCase.id))

			// playerWrapperRef.current?.scrollIntoView()
			topElementRef.current?.scrollIntoView()
		}

		if (history.location.state?.t) {
			timeToSkipToRef.current = history.location.state.t
		}

		videoPreviewObserver.fireConcreteCase(null, null)

		return () => {
			isMounted = false
		}
	}, [concreteCasesMasterList, ccId])

	useEffect(() => {
		if (!concreteCase || !topElementRef.current) {
			return
		}

		setLoadingComments(true)

		fetchGet<Comment[]>(consts.api_paths.comments + '/' + concreteCase.id)
			.then(data => {
				setComments(data.map(c => ({ ...c, commentToAdd: '', showCommentToAdd: false })))
			})
			.catch(err => alertsObserver.fire('error', 'Ao consultar comentários: ' + err))
			.finally(() => setLoadingComments(false))
	}, [forceCommentsUpdate])

	function handleMyListClick(operation: 0 | 1) {
		if (!user) {
			return navigateToLogin(history)
		}

		if (!topElementRef.current) {
			return
		}

		setLoadingAddRemoveMyList(true)

		fetchPost(consts.api_paths.add_remove_from_my_list, {
			body: JSON.stringify({
				concreteCaseId: concreteCase?.id,
				operation: operation,
			}),
		})
			.then((data: { obj: UserWatchInfo }) => {
				setConcreteCase(prev => {
					if (!prev) {
						return prev
					}

					concreteCaseChangeObserver.fire({ ...prev, userWatchInfo: data.obj }, ConcreteCaseChangeObserverShooter.ConcreteCaseModal)

					return {
						...prev,
						userWatchInfo: data.obj,
					}
				})
			})
			.catch(err => alertsObserver.fire('error', `Ao ${operation === 0 ? 'remover' : 'inserir'} na sua lista: ${err}`))
			.finally(() => setLoadingAddRemoveMyList(false))
	}

	function handleCommentsClick() {
		if (!topElementRef.current) {
			return
		}

		if (!showingComments && !loadingComments && comments.length === 0) {
			setForceCommentsUpdate()
		}

		setPlaying(false)

		// if (!showingComments && !user) {
		// 	return navigateToLogin(history)
		// }

		setShowingComments(prev => !prev)
	}

	function handleScoreChangeClick(newScore: number) {
		if (!topElementRef.current) {
			return
		}

		if (!user) {
			return navigateToLogin(history)
		}

		setUpdatingScore(true)

		fetchPost(consts.api_paths.update_score, {
			body: JSON.stringify({
				concreteCaseId: concreteCase?.id,
				score: newScore,
			}),
		})
			.then((data: { obj: UserWatchInfo; score: number }) => {
				setConcreteCasesMasterList(prev => prev.map(cc => (cc.id === concreteCase?.id ? { ...cc, score: data.score, userWatchInfo: data.obj } : cc)))
				setConcreteCase(prev => (!prev ? prev : { ...prev, score: data.score, userWatchInfo: data.obj }))
				setComments(prev => prev.map(c => (c.user.id === user.id ? { ...c, user: { ...c.user, score: newScore } } : c)))

				// this doesn't work for some reason
				// setComments(prev => prev.map(c => c.user.name === user.name && c.user.photo === user.photo ? { ...c, user: { ...c.user, score: newScore } } : c))

				// if (concreteCase) {
				// 	concreteCaseChangeObserver.fire({ ...concreteCase, userWatchInfo: data.obj }, ConcreteCaseChangeObserverShooter.ConcreteCaseModal)
				// }
			})
			.catch(err => alertsObserver.fire('error', 'Ao trocar nota: ' + err))
			.finally(() => {
				setUpdatingScore(false)
				// setShowingScoreForm(false)
			})
	}

	function handleModuleClick(moduleId: number) {
		if (!topElementRef.current) {
			return
		}

		if (lastUpdateWatchInfo.current) {
			setConcreteCasesMasterList(prev =>
				prev.map(cc =>
					cc.id === lastUpdateWatchInfo.current?.ccId
						? {
								...cc,
								userWatchInfo: lastUpdateWatchInfo.current?.userWatchConcreteCase,
								modules: lastUpdateWatchInfo.current?.userWatchModule
									? cc.modules.map(mod => (mod.id === currentModuleIdRef.current ? { ...mod, userWatchInfo: lastUpdateWatchInfo.current?.userWatchModule } : mod))
									: cc.modules,
						  }
						: cc
				)
			)

			lastUpdateWatchInfo.current = null
		}

		handlePlayClick(moduleId)
	}

	function handleSubmitComment(e: React.FormEvent<HTMLFormElement>, commentFatherId: number | null, comment: string) {
		e.preventDefault()

		if (!concreteCase || !topElementRef.current) {
			return
		}

		if (!user) {
			return navigateToLogin(history)
		}

		if (!concreteCase.userWatchInfo?.score) {
			return setShowScoreNotSet(true)
		}

		setSubmitingComment(true)

		fetchPost<Comment[]>(consts.api_paths.comments_add, {
			body: JSON.stringify({
				concreteCaseId: concreteCase.id,
				commentFatherId,
				comment,
			}),
		})
			.then((data: Comment[]) => {
				if (commentFatherId) {
					setComments(data.map((c: Comment) => (c.id === commentFatherId ? { ...c, commentToAdd: '' } : c)))
				} else {
					setCommentToAdd('')
					setComments(data.map(c => ({ ...c, commentToAdd: '' })))
				}

				setShowingCommentsMessage(user.type !== UserType.Admin && user.type !== UserType.Teacher)
				setShowScoreNotSet(false)
			})
			.catch(err => alertsObserver.fire('error', 'Ao adicionar comentário: ' + err))
			.finally(() => setSubmitingComment(false))
	}

	function handlePlayClick(moduleId: number) {
		if (!concreteCase || !topElementRef.current) {
			return
		}

		if (!user) {
			setTimeout(() => {
				if (!topElementRef.current) return

				setPlaying(false)
			}, 1000)

			return navigateToLogin(history)
		}

		const newModuleToPlay = concreteCase.modules.find(mod => mod.id === moduleId)
		const newVideoUrl = newModuleToPlay?.videoUrl

		currentModuleIdRef.current = moduleId

		if (newVideoUrl) {
			setLoadingModules(true)

			setTimeout(() => topElementRef.current && setLoadingModules(false), 500)

			setConcreteCase(prev =>
				!prev
					? prev
					: {
							...prev,
							modules: prev.modules.map(mod =>
								mod.id === moduleId
									? { ...mod, userWatchInfo: mod.userWatchInfo || { concluded: false, duration: 0, durationTotal: 0, score: 0, type: UserWatchListType.Watched, date: '2000-01-01 00:00:00' } }
									: mod
							),
					  }
			)

			// setVideoUrl(newVideoUrl + (newModuleToPlay?.userWatchInfo ? secondsToTimeVimeo(newModuleToPlay.userWatchInfo.duration) : ''))
			setVideoUrl(newVideoUrl)
			timeToSkipToRef.current = newModuleToPlay?.userWatchInfo?.duration || 0

			setVideoSubtitle('Módulo ' + newModuleToPlay?.number || '')

			requestFullscreenOnReadyRef.current = true

			// playerWrapperRef.current
			// 	?.requestFullscreen()
			// 	.then(() => {})
			// 	.catch(() => topElementRef.current?.scrollIntoView())
			// 	.finally(() => setPlaying(true))

			// const player = playerRef.current?.getInternalPlayer()

			// if (player && player.requestFullscreen) {
			// 	player
			// 		.requestFullscreen()
			// 		.then(() => {})
			// 		.catch(() => topElementRef.current?.scrollIntoView())
			// 		.finally(() => setPlaying(true))
			// }

			return
		}

		setLoadingModules(true)

		fetchGet<{ blocked: boolean; modules: { id: number; videoUrl: string }[] }>(consts.api_paths.concrete_case_modules_urls + '/' + concreteCase.id)
			.then(data => {
				if (data.blocked) {
					return navigateToLogin(history, '?blocked=1')
				}

				setConcreteCase(prev =>
					!prev
						? prev
						: {
								...prev,
								modules: prev.modules.map(mod => ({ ...mod, videoUrl: data.modules.find(m => m.id === mod.id)?.videoUrl })),
						  }
				)

				setVideoUrl(prev => {
					const newModuleToPlay = concreteCase.modules.find(mod => mod.id === moduleId)
					const newVideoUrl = data.modules.find(d => d.id === moduleId)?.videoUrl

					if (newVideoUrl) {
						timeToSkipToRef.current = newModuleToPlay?.userWatchInfo?.duration || 0
						// return newVideoUrl + (newModuleToPlay?.userWatchInfo ? secondsToTimeVimeo(newModuleToPlay.userWatchInfo.duration) : '')
						return newVideoUrl
					}

					return prev
				})

				setVideoSubtitle('Módulo ' + newModuleToPlay?.number || '')

				// playerWrapperRef.current
				// 	?.requestFullscreen()
				// 	.then(() => {})
				// 	.catch(() => topElementRef.current?.scrollIntoView())
				// 	.finally(() => setPlaying(true))

				requestFullscreenOnReadyRef.current = true

				// const player = playerRef.current?.getInternalPlayer()

				// if (player && player.requestFullscreen) {
				// 	player
				// 		.requestFullscreen()
				// 		.then(() => {})
				// 		.catch(() => topElementRef.current?.scrollIntoView())
				// 		.finally(() => setPlaying(true))
				// }
			})
			.catch(err => alertsObserver.fire('error', 'Ao consultar detalhes dos módulos: ' + err))
			.finally(() => setLoadingModules(false))
	}

	function handlePlayerProgress(state: { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }) {
		console.log('handlePlayerProgress')
		if (!user || !concreteCase || !topElementRef.current) {
			return
		}

		const dateDelay = new Date()
		dateDelay.setSeconds(dateDelay.getSeconds() - 15)

		if (lastTimeUpdateRef.current > dateDelay) {
			return
		}

		dateDelay.setHours(dateDelay.getHours() + 100)
		// increases the date so it waits for the previous one to complete before sending another one
		lastTimeUpdateRef.current = dateDelay

		fetchPost(consts.api_paths.concrete_case_update_watch_time, {
			body: JSON.stringify({
				concreteCaseId: concreteCase.id,
				moduleId: currentModuleIdRef.current,
				time: state.playedSeconds,
			}),
		})
			.then((data: { userWatchConcreteCase: UserWatchInfo; userWatchModule?: UserWatchInfo }) => {
				lastUpdateWatchInfo.current = { ...data, ccId: concreteCase.id }
			})
			.catch(err => alertsObserver.fire('error', err))
			.finally(() => {
				if (!topElementRef.current) {
					return
				}

				lastTimeUpdateRef.current = new Date()
			})
	}

	function handleCommentUpdateStatus(commentId: number, status: CommentStatus) {
		setUpdatingCommentStatus(true)

		fetchPost(consts.api_paths.update_comment_status, {
			body: JSON.stringify({
				concreteCaseId: concreteCase?.id,
				commentId,
				status,
			}),
		})
			.then((data: Comment[]) => {
				setComments(data)
				setConcreteCasesMasterList(prev =>
					prev.map(cc =>
						cc.id === concreteCase?.id
							? {
									...cc,
									pendingCommentsCount: data.reduce(
										(aggCommentFather, commentFather) =>
											aggCommentFather +
											(commentFather.status === CommentStatus.Pending ? 1 : 0) +
											commentFather.comments.reduce((aggChild, commentChild) => aggChild + (commentChild.status === CommentStatus.Pending ? 1 : 0), 0),
										0
									),
							  }
							: cc
					)
				)
				alertsObserver.fire('success', 'Atualizado com sucesso')
			})
			.catch(err => alertsObserver.fire('error', err))
			.finally(() => setUpdatingCommentStatus(false))
	}

	// function renderScoreForm(cc: ConcreteCaseForCarousel) {
	// 	return (
	// 		<div style={{ margin: 15, opacity: 0.9, backgroundColor: 'black', textAlign: 'center', paddingBottom: 20 }}>
	// 			<div className="modal-close-button">
	// 				<i className="fas fa-times cursor-pointer" style={{ marginRight: 20, marginTop: 10 }} onClick={() => setShowingScoreForm(false)}></i>
	// 			</div>
	// 			<h1 style={{ marginTop: 5, marginBottom: 10 }}>Quantas estrelas você dá para esse caso?</h1>
	// 			<h3 style={{ marginBottom: 10 }}>De uma nota para postar seu comentário</h3>
	// 			<h1>
	// 				<Score key={cc.userWatchInfo?.score || 0} value={cc.userWatchInfo?.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} />
	// 			</h1>
	// 		</div>
	// 	)
	// }

	function renderComment(comment: Comment, commentFatherIsPending: boolean) {
		if (comment.user.type === UserType.Teacher) {
			return (
				<>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img src={consts.DYNAMIC_FILES_URL + '/' + comment.user.photo} style={{ height: 50, width: 50, marginBottom: 10 }} />
						<div style={{ width: '100%' }}>
							<h4>
								Resposta {comment.user.name} - {comment.date}
							</h4>
							<textarea
								defaultValue={comment.text}
								rows={comment.text.split('\n').length + 1}
								readOnly
								style={{ width: '100%', border: 'none', background: 'none', outline: 'none', color: 'white', lineHeight: 1.2, zIndex: -1, resize: 'none' }}
							></textarea>
							{/* <p>{comment.text}</p> */}
						</div>
					</div>
				</>
			)
		}

		return (
			<>
				{comment.status === CommentStatus.Pending ? (
					<h4 style={{ color: 'red', fontWeight: 'bold', lineHeight: 1.2 }}>
						O comentário abaixo está pendente, {/* don't have and don't need the user.teacher.id because the server doesn't return pending comments the teacher can't see */}
						{user?.type === UserType.Admin || user?.type === UserType.Teacher ? (
							<>
								{commentFatherIsPending ? (
									'necessário aceitar o comentário pai primeiro'
								) : (
									<>
										<button
											type="button"
											onClick={() => handleCommentUpdateStatus(comment.id, CommentStatus.Accepted)}
											disabled={updatingCommentStatus || commentFatherIsPending}
											className="btn btn-success"
											style={{ marginRight: 10 }}
										>
											Aceitar {updatingCommentStatus && <LoadingIcon />}
										</button>
										|
										<button
											type="button"
											onClick={() => handleCommentUpdateStatus(comment.id, CommentStatus.Rejected)}
											disabled={updatingCommentStatus || commentFatherIsPending}
											className="btn btn-danger"
											style={{ marginLeft: 10 }}
										>
											Rejeitar {updatingCommentStatus && <LoadingIcon />}
										</button>
									</>
								)}
							</>
						) : (
							'aguarde que ele será revisado em breve'
						)}
					</h4>
				) : null}

				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img src={consts.DYNAMIC_FILES_URL + '/' + comment.user.photo} style={{ height: 50, width: 50, borderRadius: '50%', marginRight: 10 }} />
					<h4>{comment.user.name}</h4>
				</div>
				<h5>
					{comment.date} {renderScore(comment.user.score || 0)}
				</h5>
				<textarea
					defaultValue={comment.text}
					rows={Math.min(Math.max(comment.text.split('\n').length + 1, Math.floor(comment.text.length / 120)), 8)}
					readOnly
					style={{ width: '100%', border: 'none', background: 'none', outline: 'none', color: 'white', lineHeight: 1.2, zIndex: -1, resize: 'none' }}
				></textarea>
				{/* <p>{comment.text}</p> */}
			</>
		)
	}

	const videoWidth = Math.min(900, window.innerWidth * 0.95)

	if (!concreteCase) {
		return (
			<Modal id="concrete-case" calculateWidth visible style={{ maxWidth: 900, overflowY: 'auto', overflowX: 'hidden', scrollBehavior: 'smooth' }}>
				<div className="modal-close-button">
					<i className="fas fa-times cursor-pointer" style={{ margin: '10px' }} onClick={() => navigateBackFromModal(history)}></i>
				</div>
				<div style={{ width: videoWidth, height: (videoWidth / 16) * 9, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{loadingAll ? <LoadingIcon style={{ fontSize: 50, opacity: 0.7 }} /> : <h1>Este caso concreto não existe</h1>}
				</div>
			</Modal>
		)
	}

	const modulesToContinue = concreteCase.modules.filter(mod => mod.userWatchInfo && !mod.userWatchInfo.concluded)
	const moduleToContinue = modulesToContinue.length === 0 ? null : modulesToContinue[modulesToContinue.length - 1]

	const similarCasesProcessed: { cc1: ConcreteCaseForCarousel; cc2?: ConcreteCaseForCarousel }[] = []

	for (let i = 0; i < similarCases.length; i += 2) {
		similarCasesProcessed.push({ cc1: similarCases[i], cc2: similarCases[i + 1] })
	}

	return (
		<Modal id="concrete-case" modalRef={modalRef} calculateWidth visible style={{ maxWidth: 900, overflowY: 'auto', overflowX: 'hidden', scrollBehavior: 'smooth', bottom: 20 }}>
			<span ref={topElementRef}></span>
			<div
				className="concrete-case-video-wrapper"
				ref={playerWrapperRef}
				style={{ display: 'flex', width: '100%', height: Math.round((videoWidth / 16) * 9), justifyContent: 'space-around', position: 'relative' }}
			>
				<div className="concrete-case-video-title" style={{ position: 'absolute', left: 5, top: 5, backgroundColor: 'black', opacity: 0.7, padding: 7, width: 'calc(100% - 10px)' }}>
					{/* <h3 style={{ lineHeight: 1.2 }}>{concreteCase.title}</h3> */}
					<h4 style={{ lineHeight: 1.2 }}>{videoSubtitle}</h4>
				</div>
				<img src={consts.DYNAMIC_FILES_URL + '/' + concreteCase.thumbnail} style={{ width: videoWidth, height: (videoWidth / 16) * 9, borderRadius: 2, display: videoLoaded ? 'none' : '' }} />
				{!videoLoaded ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10 }} /> : null}
				<VimeoPlayer
					controls
					volume={muted ? 0 : volume}
					ref={playerRef}
					playing={playing && !videoOutsideView && !showingPreview && !showingLogin}
					// onPlay={() => topElementRef.current && setPlaying(true)}
					// onPause={() => topElementRef.current && setPlaying(false)}
					width="100%"
					height="100%"
					style={{ display: videoLoaded ? '' : 'none' }}
					url={videoUrl}
					onReady={() => {
						if (!topElementRef.current) {
							return
						}

						// topElementRef.current.requestFullscreen().then(console.log).catch(console.log)

						setVideoLoaded(true)

						if (timeToSkipToRef.current > 0) {
							playerRef.current?.seekTo(timeToSkipToRef.current)
						}

						if (requestFullscreenOnReadyRef.current) {
							const player = playerRef.current?.getInternalPlayer()

							if (player && player.requestFullscreen) {
								player
									.requestFullscreen()
									.then(() => {})
									.catch(() => topElementRef.current?.scrollIntoView())
									.finally(() => setPlaying(true))
							}

							requestFullscreenOnReadyRef.current = false
						}
					}}
					onStart={() => {
						if (videoOutsideView || showingPreview) {
							setTimeout(() => {
								if (!topElementRef.current) return

								setPlaying(false)
							}, 500)

							return
						}

						if (requestFullscreenOnReadyRef.current) {
							const player = playerRef.current?.getInternalPlayer()
							if (player && player.requestFullscreen) {
								player
									.requestFullscreen()
									.then(() => {})
									.catch(() => topElementRef.current?.scrollIntoView())
									.finally(() => setPlaying(true))
							}

							requestFullscreenOnReadyRef.current = false
						}
					}}
					onError={(err: any) => console.log(err)}
					onProgress={handlePlayerProgress}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10 }}>
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					<h3 style={styles.play} onClick={() => handlePlayClick(moduleToContinue?.id || concreteCase.modules[0]?.id)}>
						{/* {loadingModules ? <LoadingIcon /> : <i style={{ display: 'flex', fontSize: 30 }} className={`fas ${!playing ? 'fa-play' : 'fa-pause'}`} ></i>} */}
						{loadingModules ? <LoadingIcon style={{ marginRight: 13 }} /> : <i style={{ display: 'flex', fontSize: 30, marginRight: 13 }} className="fas fa-play"></i>}
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<h3>{moduleToContinue ? 'Continuar' : 'Iniciar'}</h3>
							{<h5>Módulo {moduleToContinue?.number || 1}</h5>}
						</div>
					</h3>
				</div>
				{concreteCase.modules.length > 0 && concreteCase.userWatchInfo?.certificateFile && (
					<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
						<h3 style={styles.cert}>
							{/* {loadingModules ? <LoadingIcon /> : <i style={{ display: 'flex', fontSize: 30 }} className={`fas ${!playing ? 'fa-play' : 'fa-pause'}`} ></i>} */}
							{loadingModules ? <LoadingIcon style={{ marginRight: 13 }} /> : <i style={{ display: 'flex', fontSize: 30, marginRight: 13 }} className="fas fa-certificate"></i>}
							<a href={consts.DYNAMIC_FILES_URL + '/' + concreteCase.userWatchInfo.certificateFile} target="_blank" className="btn btn-danger" style={{ marginRight: 15 }}>
								Certificado
							</a>
						</h3>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					{loadingAddRemoveMyList ? (
						<LoadingIcon />
					) : (
						<i
							ref={setTriggerRefForMyListTooltip}
							onClick={() => handleMyListClick(concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 0 : 1)}
							className={`cursor-pointer fas ${concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 'fa-check-circle' : 'fa-plus-circle'}`}
							style={{ fontSize: 30 }}
						></i>
					)}

					{visibleForMyListTooltip && (
						<div ref={setTooltipRefForMyListTooltip} {...getTooltipPropsForMyListTooltip({ className: 'tooltip-container' })}>
							<div {...getArrowPropsForMyListTooltip({ className: 'tooltip-arrow' })} />
							{concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 'Remover da minha lista' : 'Adicionar a minha lista'}
						</div>
					)}
				</div>
				{!isTabletOrMobile && (
					<div
						style={{
							position: 'relative',
							border: '1px solid gray',
							borderRadius: 5,
							paddingBottom: 5,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'space-around',
							flexGrow: 2,
							cursor: 'pointer',
						}}
						onClick={handleCommentsClick}
					>
						<div key={concreteCase.score || 0} style={{ position: 'absolute', top: -10, backgroundColor: 'black' }}>
							{renderScore(concreteCase.score || 0)}
							{/* <Score value={concreteCase.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} /> */}
						</div>
						<h3 style={{ paddingTop: 15 }}>Comentários e Dúvidas</h3>
					</div>
				)}

				{window.navigator.share && (
					<div style={{ paddingLeft: isTabletOrMobile ? 0 : 10 }}>
						<i
							style={{ fontSize: 25 }}
							className="fas fa-share-alt"
							onClick={() => {
								window.navigator
									.share({
										text: concreteCase.title,
										title: concreteCase.title,
										url: window.location.href,
									})
									.then(() => {})
									.catch(() => {})
							}}
						></i>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					<i
						className={`fas ${muted ? 'fa-volume-mute' : 'fa-volume-up'}`}
						style={{ fontSize: 20, border: '1px solid white', borderRadius: '50%', padding: 10 }}
						onClick={() => {
							const player: any = playerRef.current?.getInternalPlayer()

							if (player && typeof player.getVolume === 'function') {
								player.getVolume().then((vol: number) => {
									if (vol) {
										setVolume(vol)
									}

									setMuted(prev => !prev)
								})
							} else {
								setMuted(prev => !prev)
							}
						}}
					></i>
				</div>
			</div>
			{isTabletOrMobile ? (
				<div
					style={{
						marginTop: 20,
						position: 'relative',
						border: '1px solid gray',
						borderRadius: 5,
						paddingBottom: 5,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-around',
						flexGrow: 2,
						cursor: 'pointer',
					}}
					onClick={handleCommentsClick}
				>
					<div key={concreteCase.score || 0} style={{ position: 'absolute', top: -10, backgroundColor: 'black' }}>
						{renderScore(concreteCase.score || 0)}
						{/* <Score value={concreteCase.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} /> */}
					</div>
					<h3 style={{ paddingTop: 15 }}>Comentários e Dúvidas</h3>
				</div>
			) : (
				<></>
			)}
			{showingComments ? (
				<div style={{ padding: 10, marginBottom: 5, position: 'relative' }}>
					{showingCommentsMessage && (
						<div style={{ position: 'absolute', height: '100%', width: '100%', opacity: 0.9, backgroundColor: 'black', padding: 15 }}>
							{/* <div className="modal-close-button"> */}
							{/* <i className="fas fa-times cursor-pointer" style={{ marginRight: 20, marginTop: 10 }} onClick={() => setShowingCommentsMessage(false)}></i> */}
							{/* </div> */}
							<h3 style={{ marginBottom: 15, lineHeight: 1.2 }}>OBRIGADO POR SEU REGISTRO</h3>
							<h3 style={{ marginBottom: 15, lineHeight: 1.2 }}>Os comentários ou dúvidas que você registrou estarão disponíveis deste caso concreto em até 48 horas.</h3>
							<h3 style={{ marginBottom: 15, lineHeight: 1.2 }}>
								Qualquer dúvida entre em contato conosco no e-mail{' '}
								<a className="btn-link" href="mailto:suporte@escolavirtual.info" target="_blank">
									suporte@escolavirtual.info
								</a>
							</h3>

							<div style={{ display: 'flex', justifyContent: 'space-around' }}>
								<button type="button" className="btn btn-danger" onClick={() => setShowingCommentsMessage(false)}>
									VOLTAR
								</button>
							</div>
						</div>
					)}
					{
						<>
							<Link to={window.location.pathname.split('/comments')[0]} className="btn btn-warning" style={{ marginBottom: 20, display: 'inline-block' }} onClick={() => setShowingComments(false)}>
								<i className="fas fa-arrow-left"></i> Voltar aos módulos
							</Link>

							{loadingComments ? (
								<h3>
									Comentários <LoadingIcon />
								</h3>
							) : comments.length === 0 ? (
								<>
									<h3 style={{ marginBottom: 10 }}>Esse caso concreto ainda não possui comentários.</h3>
									<h3>Seja o primeiro a comentar ou deixar suas dúvidas.</h3>
								</>
							) : null}
							{
								<>
									{/* {!concreteCase.userWatchInfo?.score && (
										<div>
											<h3 style={{ marginTop: 5, marginBottom: 10 }}>Quantas estrelas você dá para esse caso?</h3>
											<h2>
												<Score key={concreteCase.userWatchInfo?.score || 0} value={concreteCase.userWatchInfo?.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} />
												{showScoreNotSet && <h6 style={{ display: 'inline', marginLeft: 10, color: 'red' }}>Dê uma nota para postar seu comentário</h6>}
											</h2>
										</div>
									)} */}
									<form style={{ marginTop: 30 }} onSubmit={e => handleSubmitComment(e, null, commentToAdd)}>
										{/* {user ? (
											<>
												<textarea
													name="comment"
													id="comment"
													disabled={submitingComment}
													placeholder="Adicione um comentário público..."
													required={showCommentToAdd}
													rows={Math.max(commentToAdd.split('\n').length, 1)}
													value={commentToAdd}
													onChange={e => setCommentToAdd(e.target.value || '')}
													onFocus={() => setShowCommentToAdd(true)}
													style={{
														display: 'block',
														width: '100%',
														resize: 'none',
														backgroundColor: '#141414',
														color: 'white',
														marginBottom: 15,
														borderTop: 'none',
														borderLeft: 'none',
														borderRight: 'none',
														borderBottom: '1px solid white',
														padding: 5,
														// amount of lines times line-height + padding-top + padding-bottom
														height: `calc(${Math.max(commentToAdd.split('\n').length, 1) * 1.25}em + 10px)`,
													}}
												></textarea>

												{showCommentToAdd && (
													<div style={{ display: 'flex' }}>
														<button
															type="button"
															className="btn btn-link"
															disabled={submitingComment}
															style={{ color: 'white', marginLeft: 'auto' }}
															onClick={() => {
																setCommentToAdd('')
																setShowCommentToAdd(false)
															}}
														>
															Cancelar
														</button>

														<button type="submit" className="btn" disabled={submitingComment} style={{ color: 'white', backgroundColor: 'black', border: '1px solid white', marginLeft: 20 }}>
															Salvar
														</button>
													</div>
												)}
											</>
										) : (
											<button
												type="button"
												className="btn"
												disabled={submitingComment}
												style={{ color: 'white', backgroundColor: 'black', border: '1px solid white' }}
												onClick={() => navigateToLogin(history)}
											>
												Adicionar comentário ou Dúvida
											</button>
										)} */}
										{showCommentToAdd ? (
											<>
												{!concreteCase.userWatchInfo?.score && (
													<div style={{ marginBottom: 15 }}>
														<h3 style={{ marginTop: 5, marginBottom: 10 }}>Quantas estrelas você dá para esse caso?</h3>
														<h2>
															<Score key={concreteCase.userWatchInfo?.score || 0} value={concreteCase.userWatchInfo?.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} />
															{showScoreNotSet && <h6 style={{ display: 'inline', marginLeft: 10, color: 'red' }}>Dê uma nota para postar seu comentário</h6>}
														</h2>
													</div>
												)}
												<textarea
													name="comment"
													id="comment"
													disabled={submitingComment}
													placeholder="Adicione um comentário público..."
													required={showCommentToAdd}
													rows={Math.max(commentToAdd.split('\n').length, 1)}
													value={commentToAdd}
													onChange={e => setCommentToAdd(e.target.value || '')}
													onFocus={() => setShowCommentToAdd(true)}
													style={{
														display: 'block',
														width: '100%',
														resize: 'none',
														backgroundColor: '#141414',
														color: 'white',
														marginBottom: 15,
														borderTop: 'none',
														borderLeft: 'none',
														borderRight: 'none',
														borderBottom: '1px solid white',
														padding: 5,
														// amount of lines times line-height + padding-top + padding-bottom
														height: `calc(${Math.max(commentToAdd.split('\n').length, 1) * 1.25}em + 10px)`,
													}}
												></textarea>

												<div style={{ display: 'flex' }}>
													<button
														type="button"
														className="btn btn-link"
														disabled={submitingComment}
														style={{ color: 'white', marginLeft: 'auto' }}
														onClick={() => {
															setCommentToAdd('')
															setShowCommentToAdd(false)
														}}
													>
														Cancelar
													</button>

													<button type="submit" className="btn" disabled={submitingComment} style={{ color: 'white', backgroundColor: 'black', border: '1px solid white', marginLeft: 20 }}>
														Salvar
													</button>
												</div>
											</>
										) : (
											<button
												type="button"
												className="btn"
												disabled={submitingComment}
												style={{ color: 'white', backgroundColor: 'black', border: '1px solid white', marginBottom: 20 }}
												onClick={() => (user ? setShowCommentToAdd(true) : navigateToLogin(history))}
											>
												Adicionar comentário ou Dúvida
											</button>
										)}
									</form>
									{comments.map((commentFather: Comment) => (
										<div key={commentFather.id + '_' + commentFather.showCommentToAdd}>
											<div style={{ marginBottom: 10 }}>{renderComment(commentFather, false)}</div>
											<div style={{ paddingLeft: 20 }}>
												{commentFather.comments.map(commentChild => (
													<div key={commentChild.id} style={{ marginBottom: 10 }}>
														{renderComment(commentChild, commentFather.status === CommentStatus.Pending)}
													</div>
												))}
											</div>

											{commentFather.showCommentToAdd ? (
												<>
													{!concreteCase.userWatchInfo?.score && (
														<div>
															<h3 style={{ marginTop: 5, marginBottom: 10 }}>Quantas estrelas você dá para esse caso?</h3>
															<h2>
																<Score key={concreteCase.userWatchInfo?.score || 0} value={concreteCase.userWatchInfo?.score || 0} onScoreChange={handleScoreChangeClick} loading={updatingScore} />
																{showScoreNotSet && <h6 style={{ display: 'inline', marginLeft: 10, color: 'red' }}>Dê uma nota para postar sua resposta</h6>}
															</h2>
														</div>
													)}
													<form style={{ marginTop: 10, marginBottom: 15, paddingLeft: 20 }} onSubmit={e => handleSubmitComment(e, commentFather.id, commentFather.commentToAdd)}>
														<textarea
															name="comment"
															id="comment"
															disabled={submitingComment}
															placeholder="Adicione uma resposta pública..."
															required
															// rows={Math.max(commentFather.commentToAdd.split('\n').length, 1)}
															value={commentFather.commentToAdd || ''}
															onChange={e => setComments(prev => prev.map(c => (c.id === commentFather.id ? { ...c, commentToAdd: e.target.value } : c)))}
															style={{
																display: 'block',
																width: '100%',
																resize: 'none',
																backgroundColor: '#141414',
																color: 'white',
																marginBottom: 15,
																borderTop: 'none',
																borderLeft: 'none',
																borderRight: 'none',
																borderBottom: '1px solid white',
																padding: 5,
																lineHeight: 1.25,
																// amount of lines times line-height + padding-top + padding-bottom
																height: `calc(${Math.max(commentFather.commentToAdd.split('\n').length, 1) * 1.25}em + 10px)`,
															}}
														></textarea>
														{/* <button type="submit" className="btn" disabled={submitingComment} style={{ color: 'white', backgroundColor: 'black', border: '1px solid white' }}>
														Salvar
													</button> */}

														<div style={{ display: 'flex' }}>
															<button
																type="button"
																className="btn btn-link"
																disabled={submitingComment}
																style={{ color: 'white', marginLeft: 'auto' }}
																onClick={() => setComments(prev => prev.map(c => (c.id === commentFather.id ? { ...c, showCommentToAdd: false, commentToAdd: '' } : c)))}
															>
																Cancelar
															</button>

															<button type="submit" className="btn" disabled={submitingComment} style={{ color: 'white', backgroundColor: 'black', border: '1px solid white', marginLeft: 20 }}>
																Salvar
															</button>
														</div>
													</form>
												</>
											) : (
												<button
													type="submit"
													className="btn"
													disabled={submitingComment}
													style={{ color: 'white', backgroundColor: 'black', border: '1px solid white', marginBottom: 10, marginLeft: 20 }}
													onClick={() => {
														if (!user) {
															return navigateToLogin(history)
														}

														// if (!concreteCase.userWatchInfo?.score) {
														// 	return setShowingScoreForm(true)
														// }

														setComments(prev => prev.map(c => (c.id === commentFather.id ? { ...c, commentToAdd: '', showCommentToAdd: true } : c)))
													}}
												>
													Responder comentário
												</button>
											)}
										</div>
									))}
								</>
							}
						</>
					}
				</div>
			) : (
				<>
					<div style={{ padding: 10, display: 'flex', flexDirection: 'row' }}>
						<div style={{ width: '58%', marginRight: '2%' }}>
							{/* <h2 style={{ marginBottom: 15 }}>{new Date(concreteCase.date).getFullYear() || concreteCase.date.substring(0, 4)}</h2> */}
							<h3 style={{ marginBottom: 15, lineHeight: 1.1 }}>{concreteCase.title}</h3>
							<p style={{ marginBottom: 15 }} className="oh-hi-mark oh-hi-mark-concrete-case" dangerouslySetInnerHTML={{ __html: marked(concreteCase.description) }}></p>
						</div>
						<div style={{ width: '40%' }}>
							{/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
								<div style={consts.imgProfileSizeStyle}>
									<img style={consts.imgProfileStyle} src={consts.DYNAMIC_FILES_URL + '/' + concreteCase.teacher.photo} />
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 10 }}>
									<h3 style={{ marginBottom: 10 }}>{concreteCase.teacher.name}</h3>
									<h4 style={{ fontSize: 18, lineHeight: 1.3 }}>{concreteCase.teacher.description}</h4>
								</div>
							</div> */}
							<div style={{ marginBottom: 10 }}>
								<img style={{ ...consts.imgProfileStyle, float: 'left', marginRight: 20 }} src={consts.DYNAMIC_FILES_URL + '/' + concreteCase.teacher.photo} />
								<h3 style={{ marginBottom: 10 }}>{concreteCase.teacher.name}</h3>
								<h4 style={{ fontSize: 18, lineHeight: 1.3 }}>{concreteCase.teacher.description}</h4>
							</div>
							{/* <div className="oh-hi-mark oh-hi-mark-teacher"> */}
							<p className="oh-hi-mark oh-hi-mark-teacher" dangerouslySetInnerHTML={{ __html: marked(concreteCase.teacher.curriculum) }}></p>
							{/* </div> */}
							<div className="flex wrap space-around content-center items-center" style={{ fontSize: 40 }}>
								{concreteCase.teacher.facebookLink && (
									<a className="text-center mb-10 mr-10" href={concreteCase.teacher.facebookLink} target="_blank">
										<i className="fab fa-facebook-square"></i>
									</a>
								)}
								{concreteCase.teacher.instragramLink && (
									<a className="text-center mb-10 mr-10" href={concreteCase.teacher.instragramLink} target="_blank">
										<i className="fab fa-instagram-square"></i>
									</a>
								)}
								{concreteCase.teacher.twitterLink && (
									<a className="text-center mb-10 mr-10" href={concreteCase.teacher.twitterLink} target="_blank">
										<i className="fab fa-twitter-square"></i>
									</a>
								)}
								{concreteCase.teacher.youtubeLink && (
									<a className="text-center mb-10 mr-10" href={concreteCase.teacher.youtubeLink} target="_blank">
										<i className="fab fa-youtube-square"></i>
									</a>
								)}
								{concreteCase.teacher.linkedinLink && (
									<a className="text-center mb-10 mr-10" href={concreteCase.teacher.linkedinLink} target="_blank">
										<i className="fab fa-linkedin-in"></i>
									</a>
								)}
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<h2 style={{ paddingLeft: 15 }}>Módulos {loadingModules && <LoadingIcon />}</h2>
					</div>
					<div style={{ margin: 15 }}>
						{concreteCase.modules.map((module, i) => (
							<div key={i} style={{ backgroundColor: '#3c3c3c', borderRadius: 2, marginBottom: 15 }}>
								<div style={{ display: 'flex', alignItems: 'center', gap: 15, padding: 15 }}>
									<h1>{module.number}</h1>
									<div style={{ position: 'relative' }}>
										<img style={consts.imgThumbnailStyle} src={consts.DYNAMIC_FILES_URL + '/' + (module.thumbnailSm || module.thumbnail)} onClick={() => handleModuleClick(module.id)} />
										<div className="oh-hi-mark">
											{module.userWatchInfo?.duration && module.duration > 0 ? (
												<div style={{ height: 3, width: `${(module.userWatchInfo.duration / module.duration) * 100}%`, backgroundColor: 'red', position: 'absolute', left: 0, bottom: 0 }}></div>
											) : null}
										</div>
									</div>
									{!isTabletOrMobile && (
										<div>
											<h3 className="cursor-pointer" style={{ lineHeight: 1.3 }} onClick={() => handleModuleClick(module.id)}>
												{module.title}
											</h3>
											<div
												onClick={() => handleModuleClick(module.id)}
												style={{ marginTop: 10, lineHeight: 1.2 }}
												className="oh-hi-mark cursor-pointer"
												dangerouslySetInnerHTML={{ __html: marked(module.description || '') }}
											></div>
										</div>
									)}
									<p style={{ marginRight: 0, marginLeft: 'auto' }}>{secondsToTimeMMSS(module.duration)}</p>
								</div>
								{isTabletOrMobile && (
									<div style={{ padding: 15 }}>
										<h3 className="cursor-pointer" style={{ lineHeight: 1.3 }} onClick={() => handleModuleClick(module.id)}>
											{module.title}
										</h3>
										<div
											onClick={() => handleModuleClick(module.id)}
											style={{ marginTop: 10, lineHeight: 1.2 }}
											className="oh-hi-mark cursor-pointer"
											dangerouslySetInnerHTML={{ __html: marked(module.description || '') }}
										></div>
									</div>
								)}
							</div>
						))}
					</div>

					{similarCasesProcessed.length > 0 && <h1 style={{ marginBottom: 15, marginLeft: '1%' }}>Casos semelhantes</h1>}

					{similarCasesProcessed.length > 0 && (
						<div>
							{similarCasesProcessed.map(({ cc1, cc2 }) => (
								<div key={cc1.id} style={{ marginBottom: 15 }}>
									<Video concreteCase={cc1} showThumbnail key={cc1.id} user={user} style={{ width: '48%', marginRight: '2%', marginLeft: '1%', display: 'inline-block' }} />
									{cc2 && <Video concreteCase={cc2} showThumbnail key={cc2.id} user={user} style={{ width: '48%', marginRight: '1%', display: 'inline-block' }} />}
								</div>
							))}
						</div>
					)}
				</>
			)}
		</Modal>
	)
}

type CSSObjects = {
	[_: string]: CSSProperties
}

const styles: CSSObjects = {
	wrapper: {
		position: 'fixed',
		top: 50,
		padding: 5,
		left: '50%',
		height: '90%',
		transform: 'translateX(-50%)',
		// padding: 50,
		margin: 0,
		zIndex: 150,
		backgroundColor: '#141414',
	},
	play: {
		backgroundColor: 'white',
		color: 'black',
		padding: '5px 30px 5px 21px',
		borderRadius: '3px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '13px',
		cursor: 'pointer',
	},
	cert: {
		backgroundColor: '#dc3545',
		color: 'white',
		padding: '5px 30px 5px 21px',
		borderRadius: '3px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: '13px',
		cursor: 'pointer',
	},
}
