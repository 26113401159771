import { ChangeEvent, KeyboardEvent, MouseEvent, useReducer, useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import consts from '../../consts'
import { useEffectAsync } from '../../custom-hooks'
import { fetchGet } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { PaginationState, SortType } from '../../types'
import { defaultCatch, getNextSortOrder, getSortOrderForRequest } from '../../utils'
import { LoadingButton } from '../LoadingButton'
import { TableSortArrow } from '../TableSortArrow'
import { BonusAccess, BonusAccessStatus } from './BonusAccessTypes'

export type BonusAccessTableSortFields = {
	concreteCaseCode: SortType
	title: SortType
	expirationDate: SortType
	link: SortType
}

type SortField = keyof BonusAccessTableSortFields

export type BonusAccessResponse = [BonusAccess[], number]

export function BonusAccessGrid() {
	const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0)
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationState>(consts.paginationStateDefault)
	const [sortOrder, setSortOrder] = useState<BonusAccessTableSortFields>({ concreteCaseCode: 'none', title: 'asc', expirationDate: 'none', link: 'none' })
	const [inputFilter, setInputFilter] = useState<string>('')

	const [status, setStatus] = useState<BonusAccessStatus | undefined>(undefined)
	const [bonusAccesses, setBonusAccesses] = useState<BonusAccess[]>([])
	const [originalBonusAccesses, setOriginalBonusAccesses] = useState<BonusAccess[]>([])

	useEffectAsync<BonusAccessResponse>(
		() => {
			setLoading(true)
			return fetchGet(consts.api_paths.bonus_accesses, {
				status: status,
				_page: pagination.activePage,
				_filter: pagination.filter,
				_order: getSortOrderForRequest(sortOrder),
			})
		},
		handlebonusAccessesResponse,
		defaultCatch,
		setLoadingFalse,
		[forceUpdate, status, pagination.activePage, pagination.filter, sortOrder]
	)

	function setLoadingFalse() {
		setLoading(false)
	}

	function handlebonusAccessesResponse(data: BonusAccessResponse) {
		setStatebonusAccesses(data[0], pagination.filter)
		setPagination(prev => ({
			...prev,
			totalCount: data[1],
		}))
	}

	function setStatebonusAccesses(newbonusAccesses: BonusAccess[], filter: string) {
		setOriginalBonusAccesses(newbonusAccesses)
		setBonusAccesses(getFilteredbonusAccesses(newbonusAccesses, filter))
	}

	function getFilteredbonusAccesses(bonusAccesses: BonusAccess[], filter: string) {
		if (filter.trim() === '') {
			return bonusAccesses.slice()
		}

		filter = filter.toUpperCase()

		return bonusAccesses.filter(
			cat => cat.title.toString().toUpperCase().includes(filter) || cat.expirationDate.toString().toUpperCase().includes(filter) || cat.link.toString().toUpperCase().includes(filter)
		)
	}

	function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
		const filter = event.target.value

		setInputFilter(filter)

		setStatebonusAccesses(originalBonusAccesses, filter)
	}

	function handleSearchKeyPress(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			setPagination(prev => ({ ...prev, activePage: 1, filter: inputFilter }))
			setForceUpdate()
		}
	}

	function handleReloadClick() {
		setForceUpdate()
	}

	function handlePaginationChange(page: number) {
		setPagination(prev => ({ ...prev, activePage: page }))
	}

	function handleSortOrderClick(event: MouseEvent<HTMLTableHeaderCellElement>, field: SortField) {
		if (event.currentTarget.style.cursor === 'not-allowed') {
			return
		}

		const nextSort = getNextSortOrder(sortOrder[field])

		const newSortOrder = { ...sortOrder }
		newSortOrder[field] = nextSort

		setSortOrder(newSortOrder)
	}

	function handleCopyClick(link: string) {
		const url = consts.HOST + consts.APP_URL + link

		if (navigator.clipboard) {
			navigator.clipboard
				.writeText(consts.HOST + consts.APP_URL + link)
				.then(() => {
					alertsObserver.fire('success', 'Copiado com sucesso')
					alertsObserver.fire('success', url)
				})
				.catch(err => {
					alertsObserver.fire('error', url)
					alertsObserver.fire('error', err)
				})
		} else {
			alertsObserver.fire('warning', 'Navegador não suporta recurso de copia. Copie manualmente')
			alertsObserver.fire('warning', url)
		}
	}

	return (
		<section className="w-100 flex flex-column gap-sm">
			<div className="flex flex-between items-center wrap gap-sm">
				<h1 className="text-primary font-md">Cadastro de Acessos Bônus</h1>
				<div className="flex flex-center btn-group-toggle" data-toggle="buttons">
					<button disabled={loading} onClick={() => setStatus(BonusAccessStatus.Opened)} className={`btn btn-secondary w-12ch ${status === BonusAccessStatus.Opened ? 'active' : ''}`}>
						Abertas
					</button>
					<button disabled={loading} onClick={() => setStatus(undefined)} className={`btn btn-secondary w-12ch ${status === undefined ? 'active' : ''}`}>
						Todas
					</button>
					<button disabled={loading} onClick={() => setStatus(BonusAccessStatus.Closed)} className={`btn btn-secondary w-12ch ${status === BonusAccessStatus.Closed ? 'active' : ''}`}>
						Fechadas
					</button>
				</div>
			</div>
			<div className="flex wrap gap-sm space-between">
				<div className="flex gap-sm align-items-center">
					<LoadingButton animated={loading} disabled={loading} onClick={handleReloadClick} />
					<span>|</span>
					<Link to={appPath(consts.app_paths.admin_bonus_access_new)}>
						<button className="btn btn-success">
							<i className="fas fa-plus"></i> Adicionar
						</button>
					</Link>
				</div>
				<div>
					<div className="input-group">
						<input type="text" className="form-control" disabled={loading} value={inputFilter} onChange={handleSearchChange} onKeyPress={handleSearchKeyPress} />
						<i className="fa fa-search input-addon" />
					</div>
				</div>
			</div>

			<table className="table w-100">
				<thead>
					<tr>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'concreteCaseCode')}>
							<div className="flex space-between">
								<span>Código Caso</span>
								<TableSortArrow order={sortOrder.concreteCaseCode} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'title')}>
							<div className="flex space-between">
								<span>Título</span>
								<TableSortArrow order={sortOrder.title} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'expirationDate')}>
							<div className="flex space-between">
								<span>Validade</span>
								<TableSortArrow order={sortOrder.expirationDate} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'link')}>
							<div className="flex space-between">
								<span>Link</span>
								<TableSortArrow order={sortOrder.link} />
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{bonusAccesses.map(bonusAccess => (
						<tr key={bonusAccess.id}>
							<td>
								<Link to={appPath(consts.app_paths.admin_bonus_access_id, { id: bonusAccess.id })}>{bonusAccess.concreteCaseCode}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_bonus_access_id, { id: bonusAccess.id })}>{bonusAccess.title}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_bonus_access_id, { id: bonusAccess.id })}>{bonusAccess.expirationDate}</Link>
							</td>
							<td>
								<button className="btn btn-success" onClick={event => handleCopyClick(bonusAccess.link)}>
									Copiar <i className="far fa-copy"></i>
								</button>
							</td>
							{/* <Link style={{ display: 'inline' }} to={appPath(consts.app_paths.admin_bonus_access_id, { id: bonusAccess.id })}>{bonusAccess.link}</Link>  */}
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				activePage={pagination.activePage}
				itemsCountPerPage={pagination.countPerPage}
				totalItemsCount={pagination.totalCount}
				pageRangeDisplayed={pagination.range}
				onChange={handlePaginationChange}
			/>
		</section>
	)
}
