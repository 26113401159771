export enum BonusAccessStatus {
	Opened,
	Closed,
}

export enum BonusAccessStatusDesc {
	'Aberto',
	'Fechado',
}

export type BonusAccess = {
	id: number
	link: string
	title: string
	videoUrl: string
	videoUrlAcquired: string
	thumbnail: string
	thumbnailAcquired: string
	expirationDate: string
	concreteCaseId: number
	concreteCaseCode: string
	machineCode: number
	emailSequenceCode: number
	sequenceLevelCode: number
}
