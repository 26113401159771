import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import consts from '../../consts'
import { fetchDelete, fetchGet, fetchPostOrPut } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { FormRowTitle } from '../FormRowTitle'
import { Category, CategorySortType, CategorySortTypeDesc, CategoryType, CategoryTypeDesc } from './CategoryTypes'

export function CategoryForm() {
	const [label, setLabel] = useState<string>('')
	const [order, setOrder] = useState<number | undefined>(undefined)
	const [sortType, setSortType] = useState<CategorySortType>(CategorySortType.DateCreatedDesc)
	const [type, setType] = useState<CategoryType>(CategoryType.Dynamic)
	const [loading, setLoading] = useState<boolean>(false)
	const [showDelete, setShowDelete] = useState<boolean>(true)
	const [readOnly, setReadOnly] = useState<boolean>(false)

	const params = useParams<{ id: string | undefined }>()
	const id = params.id && parseInt(params.id) ? params.id || undefined : undefined

	useEffect(() => {
		let isMounted = true

		if (id) {
			setLoading(true)

			fetchGet<Category>(consts.api_paths.categories + '/' + id)
				.then(json => {
					if (!isMounted) return
					setLabel(json.label)
					setOrder(json.order)
					setSortType(json.sortType)
					setType(json.type)
				})
				.catch(err => {
					if (!isMounted) return
					alertsObserver.fire('error', `Ao consultar Categoria ${id}:${err}`)
					setReadOnly(true)
				})
				.finally(() => {
					if (!isMounted) return
					setLoading(false)
				})
		}

		return () => {
			isMounted = false
		}
	}, [])

	function handleDeleteClick() {
		setLoading(true)
		setShowDelete(false)

		fetchDelete('/categories/' + id)
			.then(() => {
				alertsObserver.fire('success', `Categoria ${id} removida com sucesso`)
				setReadOnly(true)
			})
			.catch(err => {
				alertsObserver.fire('error', `Ao deletar categoria ${id}: ${err}`)
				setShowDelete(true)
			})
			.finally(() => setLoading(false))
	}

	function handleSaveClick() {
		setLoading(true)

		fetchPostOrPut('/categories', id, {
			body: JSON.stringify({
				label: label,
				order: order,
				sortType: sortType,
			}),
		})
			.then(data => alertsObserver.fire('success', `Categoria ${data.id} salva com sucesso`))
			.catch(err => alertsObserver.fire('error', `Ao salvar categoria ${id}: ${err}`))
			.finally(() => setLoading(false))
	}

	return (
		<form className="w-100 flex flex-column wrap gap-sm">
			<div>
				<Link to={appPath(consts.app_paths.admin_categories)} className="btn btn-warning">
					<i className="fas fa-arrow-left"></i> VOLTAR
				</Link>
			</div>

			<FormRowTitle
				id={id}
				label="Categoria"
				loading={loading}
				allowDeletingButton={!readOnly && showDelete && !loading && type !== CategoryType.Fixed}
				onDeleteConfirmationClick={handleDeleteClick}
			/>

			<div className="form-group">
				<label htmlFor="order">Ordem de apresentação da categoria</label>
				<input
					type="text"
					className="form-control"
					value={order || ''}
					required
					disabled={readOnly || loading}
					onChange={event => setOrder(event.target.value ? parseInt(event.target.value) || undefined : undefined)}
					id="order"
					name="order"
					aria-describedby="ordem-help"
					placeholder="Deixe vazio para que seja o último"
				/>
				<small id="ordem-help" className="form-text text-muted">
					Este campo afetará a ordem que serão exibidas as categorias na página inicial
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="label">Título</label>
				<input
					type="text"
					className="form-control"
					required
					disabled={readOnly || loading}
					value={label}
					onChange={event => setLabel(event.target.value)}
					id="label"
					name="label"
					aria-describedby="label-help"
					placeholder=""
				/>
				<small id="label-help" className="form-text text-muted">
					Título da categoria, exibido acima da lista dos casos concretos
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="sortType">Ordem de exibição dos casos dentro da categoria</label>
				<select name="sortType" id="sortType" value={sortType} className="form-control" required disabled={readOnly || loading} onChange={event => setSortType(parseInt(event.target.value))}>
					<option value={CategorySortType.DateCreatedAsc}>{CategorySortTypeDesc[CategorySortType.DateCreatedAsc]}</option>
					<option value={CategorySortType.DateCreatedDesc}>{CategorySortTypeDesc[CategorySortType.DateCreatedDesc]}</option>
					<option value={CategorySortType.MostViewedAsc}>{CategorySortTypeDesc[CategorySortType.MostViewedAsc]}</option>
					<option value={CategorySortType.MostViewedDesc}>{CategorySortTypeDesc[CategorySortType.MostViewedDesc]}</option>
				</select>
				<small id="sortType-help" className="form-text text-muted">
					Ordenação dos casos concretos dentro do categoria na página inicial
				</small>
			</div>

			<div className="form-group">
				<label htmlFor="type">Tipo da categoria</label>
				<select name="type" id="type" value={type} className="form-control" required disabled={true}>
					<option value={CategoryType.Fixed}>{CategoryTypeDesc[CategoryType.Fixed]}</option>
					<option value={CategoryType.Dynamic}>{CategoryTypeDesc[CategoryType.Dynamic]}</option>
				</select>
				<small id="type-help" className="form-text text-muted">
					Categorias fixas possuem comportamentos únicos, este campo não é editável
				</small>
			</div>

			<div>
				<button
					type="button"
					className="btn btn-success"
					disabled={readOnly || loading || !label}
					onClick={() => {
						handleSaveClick()
					}}
				>
					<i className="fas fa-save"></i> SALVAR
				</button>
			</div>
		</form>
	)
}
