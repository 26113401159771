import { CSSProperties } from 'react'

export type LoadingIconProps = {
	animated?: boolean
	className?: string
	style?: CSSProperties
}

export function LoadingIcon(props: LoadingIconProps) {
	return <i style={props.style} className={`${props.animated || props.animated === undefined ? 'spinner' : ''} fas fa-sync ${props.className || ''}`}></i>
}
