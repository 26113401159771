import { Link } from 'react-router-dom'
import consts from '../consts'
import observer from '../MainBodyWindowTypeObserver'
import { appPath } from '../routes'
import { MainBodyWindowType } from '../types'

export type AdminPanelMenuListProps = {
	size?: 'sm' | undefined
	active?: MainBodyWindowType
}

export function AdminPanelMenuList(props: AdminPanelMenuListProps = { size: undefined }) {
	function getActiveClass(type: MainBodyWindowType) {
		return type === props.active ? 'active' : ''
	}
	return (
		<ul className={`admin-panel-list ${props.size === 'sm' ? 'admin-panel-list-sm' : ''} flex space-evenly items-center wrap gap-lg hover-children-select`}>
			<li onClick={() => observer.fire('crud-users')} className={getActiveClass('crud-users')}>
				<Link to={appPath(consts.app_paths.admin_users)}>
					<i className="fa fa-users"></i>
					<h3>Usuários</h3>
				</Link>
			</li>
			{/* <li onClick={() => observer.fire('crud-categories') } className={getActiveClass('crud-categories')}> */}
			<li className={getActiveClass('crud-categories')}>
				<Link to={appPath(consts.app_paths.admin_categories)}>
					<i className="fas fa-list"></i>
					<h3>Categorias</h3>
				</Link>
			</li>
			<li className={getActiveClass('crud-teachers')}>
				<Link to={appPath(consts.app_paths.admin_teachers)}>
					<i className="fas fa-user-graduate"></i>
					<h3>Professores</h3>
				</Link>
			</li>
			<li className={getActiveClass('crud-concrete-cases')}>
				<Link to={appPath(consts.app_paths.admin_concrete_cases)}>
					<i className="fas fa-video"></i>
					<h3>Casos Concretos</h3>
				</Link>
			</li>
			<li className={getActiveClass('crud-bonus-access')}>
				<Link to={appPath(consts.app_paths.admin_bonus_access)}>
					<i className="fas fa-award"></i>
					<h3>Casos Concretos Bônus</h3>
				</Link>
			</li>
			<li className={getActiveClass('crud-system-messages')}>
				<Link to={appPath(consts.app_paths.admin_system_messages)}>
					<i className="fas fa-cog"></i>
					<h3>Mensagens e E-mails do Sistema</h3>
				</Link>
			</li>
			<li className={getActiveClass('crud-lives')}>
				<Link to={appPath(consts.app_paths.admin_lives)}>
					<i className="fab fa-instagram-square"></i>
					<h3>Lives gravadas no instagram</h3>
				</Link>
			</li>
		</ul>
	)
}
