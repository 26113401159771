import { useState } from 'react'
import { Link } from 'react-router-dom'
import consts from '../consts'
import { fetchPost } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import { appPath } from '../routes'
import { LoadingIcon } from './LoadingIcon'

export function Reset() {
	const [loading, setLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [password1, setPassword1] = useState<string>('')
	const [password2, setPassword2] = useState<string>('')
	const [recoveryCode, setRecoveryCode] = useState<string>('')
	const [showErrorHint, setShowErrorHint] = useState<boolean>(false)

	function handleSubmitClick() {
		if (!email.trim()) {
			setShowErrorHint(true)
			return
		}

		setLoading(true)

		fetchPost(consts.api_paths.reset, {
			body: JSON.stringify({
				email,
				recoveryCode,
				password1,
				password2,
			}),
		})
			.then(data => alertsObserver.fire('success', 'Conta criada com sucesso'))
			.catch(err => alertsObserver.fire('error', err))
			.finally(() => setLoading(false))
	}

	return (
		<main className="flex flex-column gap-md">
			<h1 className="m-auto">Redefinição de senha</h1>
			<form className="login-form login-form flex flex-column gap-md m-auto">
				<section className="form-group">
					<label htmlFor="recoveryCode">Código de recuperação</label>
					<input
						id="recoveryCode"
						name="recoveryCode"
						type="password"
						className="form-control form-control-compact"
						value={recoveryCode}
						required
						disabled={loading}
						onChange={event => setRecoveryCode(event.target.value)}
						aria-describedby="recoveryCode-help"
						placeholder="Digite sua nova senha"
					/>
					<small id="recoveryCode-help">Código enviado ao seu e-mail</small>
				</section>

				<section className="form-group">
					<label htmlFor="email">E-mail</label>
					<input
						id="email"
						name="email"
						type="email"
						className="form-control form-control-compact"
						value={email}
						required
						autoFocus
						disabled={loading}
						onChange={event => setEmail(event.target.value)}
						aria-describedby="email-help"
						placeholder="Digite seu e-mail"
					/>
					<small id="email-help" className={showErrorHint && !email ? 'text-danger' : 'hidden'}>
						E-mail para recuperação de senha
					</small>
				</section>

				<section className="form-group">
					<label htmlFor="password1">Nova senha</label>
					<input
						id="password1"
						name="password1"
						type="password"
						className="form-control form-control-compact"
						value={password1}
						required
						disabled={loading}
						onChange={event => setPassword1(event.target.value)}
						aria-describedby="password1-help"
						placeholder="Digite sua nova senha"
					/>
					{/* <small id="password1-help" className={showErrorHint && !email ? 'text-danger' : 'hidden'}></small> */}
				</section>

				<section className="form-group">
					<label htmlFor="password2">Confirmar nova senha</label>
					<input
						id="password2"
						name="password2"
						type="password"
						className="form-control form-control-compact"
						value={password2}
						required
						disabled={loading}
						onChange={event => setPassword2(event.target.value)}
						aria-describedby="password2-help"
						placeholder="Digite sua nova senha"
					/>
					{/* <small id="password2-help" className={showErrorHint && !email ? 'text-danger' : 'hidden'}></small> */}
				</section>

				<button type="button" disabled={loading} className="btn btn-success" onClick={handleSubmitClick}>
					Redefinir senha {loading ? <LoadingIcon /> : null}
				</button>

				<div className="flex space-between">
					<Link to={appPath(consts.app_paths.login)} className="btn btn-link">
						Login
					</Link>
					<Link to={appPath(consts.app_paths.signup)} className="btn btn-link">
						Criar Conta
					</Link>
				</div>
			</form>
		</main>
	)
}
