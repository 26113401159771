import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import consts from '../consts'
import { fetchGet } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import { appPath } from '../routes'
import { navigateBackFromModal, navigateToLogin, navigateToParamlessModal } from '../utils'
import { UserType } from './cruds/UserTypes'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'

export function Navbar() {
	const [displayMenuHover, setDisplayMenuHover] = useState<boolean>(false)
	const [atHomePage, setAtHomePage] = useState(window.location.pathname.endsWith(consts.APP_URL) || window.location.pathname.endsWith(consts.APP_URL + '/'))
	const [searchFocused, setSearchFocused] = useState(false)
	const searchInputRef = useRef<HTMLInputElement>(null)
	const [loadingFilter, setLoadingFilter] = useState(false)

	const { user, setUser, filter, setFilter, setForceHomePageUpdate, loadingLoggedUser, setLoadingLoggedUser, systemConfig } = useContext(GlobalContext)
	const history = useHistory()
	const filterTimeout = useRef<NodeJS.Timeout>()
	const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

	useEffect(() => {
		if (!filter && searchInputRef.current) {
			searchInputRef.current.value = ''
		}
	}, [filter])

	useEffect(() => {
		const unregisterCallback = history.listen(listener => {
			setAtHomePage(history.location.pathname.endsWith(consts.APP_URL) || history.location.pathname.endsWith(consts.APP_URL + '/'))
		})

		return unregisterCallback
	}, [])

	function handleLogoutClick() {
		setLoadingLoggedUser(true)

		fetchGet(consts.api_paths.logout)
			.then(data => {
				history.push(appPath('/'))
				setUser(null)
			})
			.catch(err => alertsObserver.fire('error', JSON.stringify(err)))
			.finally(() => setLoadingLoggedUser(false))
	}

	function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
		filterTimeout.current && clearTimeout(filterTimeout.current)

		setLoadingFilter(true)

		filterTimeout.current = setTimeout(() => {
			setFilter(e.target.value)
			setLoadingFilter(false)

			if (e.target.value) {
				if (
					!(
						window.location.pathname.endsWith(consts.APP_URL) ||
						window.location.pathname.endsWith(consts.app_paths.teachers + '/') ||
						window.location.pathname.endsWith(consts.app_paths.teachers) ||
						window.location.pathname.endsWith(consts.APP_URL + '/')
					)
				) {
					history.push(appPath(consts.app_paths.root))
				}
			}
		}, 500)
	}

	return (
		<nav className="navbar" style={{ zIndex: 99999 }}>
			<Link
				to={appPath(consts.app_paths.root)}
				onClick={() => {
					if (!isMobile || atHomePage) {
						if (filter) {
							setFilter('')
						}

						return navigateBackFromModal(history)
					}

					window.scrollTo(0, 0)

					if (filter) {
						setFilter('')
					} else {
						setForceHomePageUpdate()
					}
				}}
			>
				{/* <img className="evaed-logo" src={`${process.env.PUBLIC_URL}/logo.jpeg`} alt="EVAED" onClick={() => observer.fire('index')} /> */}
				{!isMobile || atHomePage ? (
					<img className={'evaed-logo ' + (searchFocused ? 'evaed-logo-min' : '')} src={`${consts.APP_URL}/${searchFocused ? 'logo-small.png' : 'logo.png'}`} alt="EVAED" />
				) : (
					<i style={{ fontSize: 18 }} className="fas fa-arrow-left"></i>
				)}
				{/* {<img className="evaed-logo" src={`${consts.APP_URL}/${true ? 'logo-small.png' : 'logo.png'}`} alt="EVAED" />} */}
			</Link>
			<ul className="navbar__items hover-children-select gap-sm" style={{ paddingLeft: 20, paddingRight: 20 }}>
				<li className="flex gap-xs items-center menu-hover">
					<i className="fas fa-bars"></i>
					<span>Menu</span>
				</li>
				<li
					className="flex gap-xs items-center menu-item"
					onClick={() => {
						window.scrollTo(0, 0)

						if (filter) {
							setFilter('')
						} else {
							setForceHomePageUpdate()
						}
					}}
				>
					<Link to={appPath(consts.app_paths.root)} style={{ whiteSpace: 'nowrap' }}>
						<i className="fas fa-home" style={{ marginRight: 5 }}></i>
						<span>Início</span>
					</Link>
				</li>
				<li className="flex gap-xs items-center menu-item" onClick={() => navigateToParamlessModal(history, 'about')}>
					<i className="fas fa-users"></i>
					<span>Sobre</span>
				</li>
				<li className="flex gap-xs items-center menu-item" onClick={() => navigateToParamlessModal(history, 'lives')}>
					<i className="fas fa-play"></i>
					<span>Lives</span>
				</li>
				<li className="flex gap-xs items-center menu-item">
					<i className="fas fa-user-graduate"></i>
					<Link to={appPath(consts.app_paths.teachers)}>
						<span>Professores</span>
					</Link>
				</li>
				<li className="flex gap-xs items-center menu-item" onClick={() => navigateToParamlessModal(history, 'support')}>
					<i className="fas fa-headset"></i>
					<span>Suporte</span>
				</li>
				<li className="flex gap-xs items-center menu-item">
					<a className="text-center" href="https://evaed.lgpd.express/app/policy" target="_blank">
						Política de
						<br />
						Privacidade
					</a>
				</li>
				<li className="flex gap-xs items-center menu-item" style={{ color: '#FFDF00', border: '1px solid rgb(255, 223, 0)', borderRadius: '11px', padding: '8px 10px' }}>
					<i className="fas fa-crown"></i>
					<a className="text-center" href={systemConfig.hotmartCheckoutLink} target="_blank">
						Mês Grátis
					</a>
				</li>
			</ul>
			<ul className="flex items-center gap-sm">
				<li>
					<div className="input-group">
						<input
							type="text"
							className="form-control form-control-compact"
							ref={searchInputRef}
							onChange={handleFilterChange}
							onBlur={() => setSearchFocused(false)}
							style={{ display: searchFocused ? 'inline-block' : isMobile ? 'none' : 'inline-block' }}
						/>
						{loadingFilter ? (
							<LoadingIcon className="input-addon" />
						) : (
							<i
								className="fa fa-search input-addon"
								style={{ color: searchFocused ? 'black' : isMobile ? 'white' : 'black' }}
								onClick={() => {
									setTimeout(() => searchInputRef.current?.focus(), 0)
									setSearchFocused(true)
								}}
							/>
						)}
					</div>
				</li>
				{/* <li>
					<button type="button" className="bnt btn-success" onClick={() => {
						fetchGet(consts.api_paths.categories)
							.then(console.log)
							.catch(console.log)
					}}>CATS</button>
					<button type="button" className="bnt btn-success" onClick={() => {
						fetchGet(consts.api_paths.categories_for_select)
							.then(console.log)
							.catch(console.log)
					}}>CATS for select</button>
				</li> */}
				<li>
					<div style={styles.hoverMenuListWrapper} onMouseEnter={() => setDisplayMenuHover(true)} onMouseLeave={() => setDisplayMenuHover(false)}>
						{user ? (
							<>
								<img style={styles.imgUserLogo} src={consts.DYNAMIC_FILES_URL + '/' + (user.photo || 'default_user.png')} />
								{loadingLoggedUser ? <LoadingIcon style={styles.loadingSpinnerOnUser} /> : null}
							</>
						) : loadingLoggedUser ? (
							<>
								<img style={styles.imgUserLogo} src={consts.DYNAMIC_FILES_URL + '/default_user.png'} />
								<LoadingIcon style={styles.loadingSpinnerOnUser} />
							</>
						) : (
							// <Link to={appPath(consts.app_paths.login)} className="btn btn-login">Entrar</Link>
							<button type="button" className="btn btn-login" style={{ marginLeft: 10 }} onClick={() => navigateToLogin(history)}>
								Entrar
							</button>
						)}
						<ul style={{ ...styles.hoverMenuList, display: displayMenuHover && user ? '' : 'none' }}>
							<li style={styles.hoverMenuListItem}>
								<Link to={appPath(consts.app_paths.profile)}>
									<span style={{ cursor: 'pointer' }}>Perfil</span>
								</Link>
							</li>
							{user?.type === UserType.Admin ? (
								<li style={styles.hoverMenuListItem}>
									<Link to={appPath(consts.app_paths.admin)}>Admin</Link>
								</li>
							) : null}
							<li style={styles.hoverMenuListItem}>
								<span style={{ cursor: 'pointer' }} onClick={handleLogoutClick}>
									Sair
								</span>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</nav>
	)
}

type CSSObjects = {
	[_: string]: CSSProperties
}

const styles: CSSObjects = {
	imgUserLogo: {
		height: '50px',
		width: '50px',
		borderRadius: '50%',
	},

	loadingSpinnerOnUser: {
		position: 'absolute',
		left: '18px',
		top: '16px',
	},

	hoverMenuListWrapper: {
		position: 'relative',
		// paddingTop: '10px',
		paddingRight: '10px',
	},

	hoverMenuList: {
		position: 'absolute',
		zIndex: 5,
		padding: '0px 15px 15px 15px',
		right: '0',
		backgroundColor: 'black',
		borderBottomLeftRadius: '3px',
		borderBottomRightRadius: '3px',
	},

	hoverMenuListItem: {
		color: 'white',
		padding: '5px 0',
	},
}
