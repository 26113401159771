import { CSSProperties, MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { useHistory } from 'react-router-dom'
import consts from '../consts'
import alertsObserver from '../observers/alerts-observer'
import teacherDetailsObserver from '../observers/teacher-details-observer'
import videoIntroPreviewObserver from '../observers/video-intro-preview-observer'
import { appPath } from '../routes'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { TeacherForCarousel } from './MainBodyTypes'

export function VideoIntroPreview() {
	const [teacher, setTeacher] = useState<TeacherForCarousel | null>(null)
	const [style, setStyle] = useState<CSSProperties>(stylesWrapper)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [videoStarted, setVideoStarted] = useState(false)
	const [carouselVideoWrapper, setCarouselVideoWrapper] = useState<HTMLElement | null>(null)

	const animationSizeIncreaseRef = useRef(0)
	const videoWrapperRectRef = useRef<DOMRect>(new DOMRect())
	const requestRef = useRef(0)
	const previousTimeRef = useRef<number | null>(null)
	const playerRef = useRef<VimeoPlayer>(null)

	const history = useHistory()

	const {
		getArrowProps: getArrowPropsForDetailsTooltip,
		getTooltipProps: getTooltipPropsForDetailsTooltip,
		setTooltipRef: setTooltipRefForDetailsTooltip,
		setTriggerRef: setTriggerRefForDetailsTooltip,
		visible: visibleForDetailsTooltip,
	} = usePopperTooltip()

	const { user, setUser } = useContext(GlobalContext)

	function animateExpantion(time: number) {
		if (previousTimeRef.current) {
			const deltaTime = time - previousTimeRef.current

			animationSizeIncreaseRef.current = Math.min(0.4, animationSizeIncreaseRef.current + 5 * (deltaTime / 1000))
		}

		previousTimeRef.current = time

		positionPreview(videoWrapperRectRef.current, animationSizeIncreaseRef.current)

		if (animationSizeIncreaseRef.current <= 0.4) {
			requestRef.current = requestAnimationFrame(animateExpantion)
		}
	}

	useEffect(() => {
		function videoIntroPreviewCallback(teacher: TeacherForCarousel, videoWrapper: HTMLElement) {
			setCarouselVideoWrapper(videoWrapper)
			setTeacher(teacher)
			setVideoLoaded(false)
			setVideoStarted(false)

			videoWrapperRectRef.current = videoWrapper.getBoundingClientRect()
			previousTimeRef.current = null
			animationSizeIncreaseRef.current = 0

			if (videoWrapperRectRef.current.width * 1.4 < window.innerWidth - 20) {
				requestRef.current = window.requestAnimationFrame(animateExpantion)
			} else {
				positionPreview(videoWrapperRectRef.current, animationSizeIncreaseRef.current)
			}
		}

		videoIntroPreviewObserver.subscribe(videoIntroPreviewCallback)

		function handleScroll(e: Event) {
			// carouselVideoWrapper && positionPreview(carouselVideoWrapper)
		}

		// as it is, doesn't work...
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
			videoIntroPreviewObserver.unsubscribe(videoIntroPreviewCallback)
		}
	}, [])

	function positionPreview(rect: DOMRect, percentageBigger: number) {
		// const rect = wrapper.getBoundingClientRect()

		// const percentageBigger = rect.width * 1.4 > window.innerWidth - 20 ? 0 : 0.4

		// 40% bigger
		const height = rect.height * (percentageBigger + 1)
		const width = rect.width * (percentageBigger + 1)

		let left: number

		// if it goes beyond the left of the screen
		// starts exacly at the left
		if (rect.left - rect.width * percentageBigger < 0) {
			left = rect.left
			// if it goes beyond the right of the screen
			// ends exacly at the right
		} else if (rect.left + rect.width * (percentageBigger + 1) > window.innerWidth) {
			left = rect.left - rect.width * percentageBigger
			// otherwise starts 10% to the left (20% to the left, 20% to the right)
		} else {
			left = rect.left - rect.width * (percentageBigger / 2)
		}

		// wrapperRef.current.style.left = left.toString()+'px'
		// wrapperRef.current.style.top = (rect.y - height * 0.2 + 0).toString()+'px'
		// wrapperRef.current.style.height = height.toString()+'px'
		// wrapperRef.current.style.width = width.toString() + 'px'

		// 80 is the height if the navbar
		const top = Math.min(Math.max(rect.y - height * 0.2 + 0, 80), window.innerHeight - height - 150)

		setStyle(prev => ({
			...prev,
			left,
			top,
			height,
			width,
		}))
	}

	function handleDetailsClick(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		event.stopPropagation()

		teacherDetailsObserver.setTeacher(teacher)

		history.push(appPath(consts.app_paths.teachers_id, { id: teacher?.id }), { t: playerRef.current?.getCurrentTime() })
		setTimeout(() => {
			teacherDetailsObserver.setTeacher(teacher)
		}, 0)

		setTeacher(null)
	}

	if (!teacher) {
		return null
	}

	return (
		<div
			style={{ ...style, height: '', paddingBottom: '5px' }}
			onMouseLeave={() => {
				setTeacher(null)
				setCarouselVideoWrapper(null)
				setVideoLoaded(false)
				setVideoStarted(false)
			}}
		>
			<div style={{ display: 'flex', justifyContent: 'space-around' }}>
				{!videoLoaded ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10, zIndex: 202 }} /> : null}
				{!videoStarted ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10, zIndex: 201, color: 'gray' }} /> : null}
				<img
					src={consts.DYNAMIC_FILES_URL + '/' + teacher.photo}
					alt={teacher.name}
					style={{ height: style.height, objectFit: 'cover', borderRadius: '50%', display: videoStarted ? 'none' : '', textShadow: '0 0 7px black' }}
					onClick={handleDetailsClick}
				/>
				{/* Vimeo Player becomes small when there is no source image for some reason */}
				<VimeoPlayer
					ref={playerRef}
					width={style.width}
					height={style.height}
					allow="autoplay"
					muted={true}
					playing
					style={{ display: videoStarted ? '' : 'none', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
					url={teacher.introVideo}
					onStart={() => setVideoStarted(true)}
					onError={err => alertsObserver.fire('error', 'err: ' + JSON.stringify(err))}
					onReady={() => setVideoLoaded(true)}
				/>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 10, width: '100%' }}>
					<h3>{teacher?.name}</h3>
					<h4 style={{ fontSize: 18, lineHeight: 1.1 }}>{teacher?.description}</h4>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
				<i
					ref={setTriggerRefForDetailsTooltip}
					className="border-1px-white border-2px-hover cursor-pointer fas fa-chevron-down"
					style={{ fontSize: 20, borderRadius: '50%', padding: 10 }}
					onClick={handleDetailsClick}
				></i>
			</div>
			{visibleForDetailsTooltip && (
				<div ref={setTooltipRefForDetailsTooltip} {...getTooltipPropsForDetailsTooltip({ className: 'tooltip-container' })}>
					<div {...getArrowPropsForDetailsTooltip({ className: 'tooltip-arrow' })} />
					Detalhes
				</div>
			)}
		</div>
	)
}

const stylesWrapper: CSSProperties = {
	position: 'fixed',
	width: 600,
	height: 400,
	zIndex: 100,
	left: 50,
	top: 200,
	backgroundColor: '#141414',
	borderRadius: 5,
	boxShadow: '0px 3px 3px 3px rgba(0,0,0,0.15)',
}
