import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useHistory } from 'react-router-dom'
import consts from '../../consts'
import { fetchPost, fetchPostFile } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { GlobalContext, LoggedUserInfo } from '../GlobalContext'
import { LoadingIcon } from '../LoadingIcon'
import { Modal } from './Modal'

export function ProfileModal() {
	const [imageChanged, setImageChanged] = useState<boolean>(false)
	const [name, setName] = useState<string>('')
	const [cpf, setCpf] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [country, setCountry] = useState<string>('')
	const [cep, setCep] = useState<string>('')
	const [city, setCity] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [number, setNumber] = useState<string>('')
	const [complement, setComplement] = useState<string>('')
	const [newPassword, setNewPassword] = useState<string>('')
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('')
	const [isPasswordsEqual, setIsPasswordEqual] = useState<boolean>(true)
	const [minLenght, setMinLenght] = useState<boolean>(true)
	const [errorMessage, setErrorMessage] = useState('')
	const [successMessage, setSuccessMessage] = useState('')

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

	const [loading, setLoading] = useState<boolean>(false)

	const [passwordChange, setPasswordChange] = useState<boolean>(false)

	const [photo, setPhoto] = useState<string>('')

	const photoImgRef = useRef<HTMLImageElement>(null)
	const photoInputRef = useRef<HTMLInputElement>(null)

	const { user, setUser, loadingLoggedUser } = useContext(GlobalContext)
	const history = useHistory()

	// useEffect(() => {
	// 	let isMounted = true

	// 	function handleKeyDown(e: KeyboardEvent) {
	// 		if (e.key === 'Escape') {
	// 			e.preventDefault()

	// 			if (user?.passwordPlainText) {
	// 				setErrorMessage('Crie uma nova senha antes de continuar!')
	// 			} else {
	// 				navigateBackFromModal(history)
	// 			}
	// 		}
	// 	}

	// 	document.addEventListener('keydown', handleKeyDown)

	// 	return () => {
	// 		isMounted = false
	// 		document.removeEventListener('keydown', handleKeyDown)
	// 	}
	// }, [])

	useEffect(() => {
		if (!user) {
			return
		}

		// if (user.passwordPlainText) {
		// 	setErrorMessage('Cria uma nova senha antes de continuar!')
		// }

		if (photoImgRef.current) {
			photoImgRef.current.src = consts.DYNAMIC_FILES_URL + '/' + (user.photo || 'default_user.png')
		}

		setName(prev => prev || user.name)
		setCpf(prev => prev || user.cpf)
		setPhone(prev => prev || user.phone)
		setCountry(prev => prev || user.country)
		setCep(prev => prev || user.cep)
		setCity(prev => prev || user.city)
		setAddress(prev => prev || user.address)
		setNumber(prev => prev || user.number)
		setComplement(prev => prev || user.complement)
	}, [user])

	function handleImageChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.files && event.target.files[0]) {
			const file = new FileReader()

			file.onload = function (e) {
				if (photoImgRef.current) {
					photoImgRef.current.src = e.target?.result as any
				}
			}

			file.readAsDataURL(event.target.files[0])

			setImageChanged(true)
		}
	}

	async function handleSaveClick() {
		setLoading(true)
		setErrorMessage('')
		setSuccessMessage('')

		let newPhoto = ''

		if (imageChanged && photoInputRef.current && photoInputRef.current.files) {
			const formData = new FormData()

			formData.append('image', photoInputRef.current.files[0])

			try {
				const json = await fetchPostFile('/files/image/user/0', {
					body: formData,
				})

				alertsObserver.fire('success', 'Imagem enviada com sucesso')

				setImageChanged(false)

				newPhoto = json.filename

				setPhoto(newPhoto)

				// setUser(prev => (!prev ? prev : { ...prev, photo: newPhoto, passwordPlainText: false, name: name }))
			} catch (err) {
				alertsObserver.fire('error', `Ao fazer upload da imagem: ${err}`)
				setLoading(false)
				return
			}
		}

		fetchPost<LoggedUserInfo>(consts.api_paths.users_set_profile, {
			body: JSON.stringify({
				name,
				cpf,
				phone,
				country,
				cep,
				city,
				address,
				number,
				complement,
				password: newPassword,
				photo: newPhoto,
			}),
		})
			.then(data => {
				setUser(data)
				setSuccessMessage('Salvo com sucesso')
			})
			.catch(err => setErrorMessage('Ocorreu um erro ao salvar!'))
			.finally(() => setLoading(false))
	}

	function formValidation(e: any) {
		let validated = passwordChange ? newPassword.length > 4 && newPassword.length > 4 && newPassword == newPasswordConfirm : true

		if (validated) {
			handleSaveClick()
			return
		}

		return
	}

	const widthClassForFormGroup = isTabletOrMobile ? 'w-100' : 'w-49'

	return (
		<Modal
			id="profile"
			visible
			calculateWidth={false}
			width={500}
			style={{ padding: 15, height: '99%', maxWidth: '600px' }}
			// canClose={() => {
			// 	if (user?.passwordPlainText) {
			// 		setErrorMessage('Crie uma nova senha antes de continuar!')
			// 		return false
			// 	}

			// 	return true
			// }}
		>
			<main className="flex flex-column gap-md">
				<form className="login-form flex flex-row wrap space-around gap-1p">
					<div className="flex wrap gap-sm space-between">
						<h1 className="w-100">
							<span>Perfil</span> {loading || loadingLoggedUser ? <LoadingIcon /> : null}
						</h1>
					</div>

					{!loadingLoggedUser && !user && (
						<h1 style={{ marginTop: 30 }}>
							Para acessar seu perfil{' '}
							<Link className="btn-link" to={{ pathname: appPath(consts.app_paths.login), state: { redirectToProfile: true } }}>
								faça login clicando aqui
							</Link>
						</h1>
					)}

					{!loadingLoggedUser && user && (
						<>
							<section className="form-group w-100">
								<img ref={photoImgRef} style={{ height: '100px', width: '100px', display: 'block', border: '1px solid white', cursor: 'pointer' }} onClick={() => photoInputRef.current?.click()} />
								<label htmlFor="photo">Foto</label>
								<input
									ref={photoInputRef}
									type="file"
									accept="image/*"
									className="form-control"
									style={{ display: 'none' }}
									required
									onChange={handleImageChange}
									id="photo"
									name="photo"
									aria-describedby="photo-help"
								/>
								{/* <small id="photo-help" className="form-text text-muted">{!id ? 'Necessário salvar antes de conseguir editar imagem' : ''}</small> */}
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Nome</label>
								<input className="form-control" disabled={loading} value={name || ''} onChange={e => setName(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>CPF</label>
								<input className="form-control" disabled={loading} value={cpf || ''} onChange={e => setCpf(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Fone</label>
								<input className="form-control" disabled={loading} value={phone || ''} onChange={e => setPhone(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>País</label>
								<input className="form-control" disabled={loading} value={country || ''} onChange={e => setCountry(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>CEP</label>
								<input className="form-control" disabled={loading} value={cep || ''} onChange={e => setCep(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Cidade</label>
								<input className="form-control" disabled={loading} value={city || ''} onChange={e => setCity(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Número</label>
								<input className="form-control" disabled={loading} value={number || ''} onChange={e => setNumber(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Endereço</label>
								<input className="form-control" disabled={loading} value={address || ''} onChange={e => setAddress(e.target.value)}></input>
							</section>

							<section className={'form-group w-100'}>
								<label>Complemento</label>
								<input className="form-control" disabled={loading} value={complement || ''} onChange={e => setComplement(e.target.value)}></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label>Nova senha</label>
								<input
									type="password"
									className="form-control"
									disabled={loading}
									value={newPassword}
									autoComplete="off"
									onChange={e => {
										setNewPassword(e.target.value)
										setPasswordChange(e.target.value != '' || newPasswordConfirm != '')
										setIsPasswordEqual(e.target.value == newPasswordConfirm)
										setMinLenght(passwordChange ? e.target.value.length > 5 && newPasswordConfirm.length > 5 : true)
									}}
									minLength={passwordChange ? 5 : 0}
								></input>
							</section>

							<section className={'form-group ' + widthClassForFormGroup}>
								<label htmlFor="confirm-password">Cofirmar nova senha</label>
								<input
									type="password"
									className="form-control"
									disabled={loading}
									value={newPasswordConfirm}
									onChange={e => {
										setNewPasswordConfirm(e.target.value)
										setPasswordChange(e.target.value != '' || newPassword != '')
										setIsPasswordEqual(e.target.value == newPassword)
										setMinLenght(passwordChange ? e.target.value.length > 4 && newPassword.length > 4 : true)
									}}
									minLength={passwordChange ? 5 : 0}
								></input>
								{!isPasswordsEqual && (
									<span>
										Os campos de senhas devem ter o mesmo valor <br />
									</span>
								)}
								{!minLenght && <span>A senha deve conter pelo menos 5 caracteres</span>}
								{errorMessage && (
									<h3 style={{ marginTop: 10 }} className="text-danger">
										{errorMessage}
									</h3>
								)}
								{successMessage && (
									<h3 style={{ marginTop: 10 }} className="text-success">
										{successMessage}
									</h3>
								)}
							</section>

							<section className={'form-group w-100'}>
								<button type="button" className="btn btn-success" disabled={loading} onClick={e => formValidation(e)}>
									<i className="fas fa-save"></i> SALVAR
								</button>
							</section>
						</>
					)}
				</form>
			</main>
		</Modal>
	)
}
