import consts from './consts'

export function fetchApi<T>(url: string, options: RequestInit): Promise<T> {
	return new Promise((resolve: (data: T) => void, reject: (message: string) => void) => {
		fetch(url, options)
			.then(res => {
				if (res.status === 403) {
					return reject('Recurso proibido, faça login novamente!')
				}

				res
					.json()
					.then((json: T) => ((json as any).error === true || !res.ok ? reject((json as any).message) : resolve(json)))
					.catch(err => reject(err && err.message ? err.message : err))
			})
			.catch(err => {
				console.log({ err })
				reject(err && err.message ? err.message : err)
			})
	})
}

export function fetchGet<T>(path: string, params: any = {}, options: RequestInit = {}): Promise<T> {
	options.method = 'GET'
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		'Content-Type': 'application/json;charset=utf-8',
		...options.headers,
	}

	const paramsUrl = Object.keys(params)
		.map(k => `${k}=${params[k] !== undefined ? params[k] : ''}`)
		.join('&')

	return fetchApi<T>(consts.API_URL + path + (paramsUrl ? '?' + paramsUrl : ''), options)
}

export function fetchPost<T>(path: string, options: RequestInit = {}): Promise<any> {
	options.method = 'POST'
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		'Content-Type': 'application/json;charset=utf-8',
		...options.headers,
	}

	return fetchApi<T>(consts.API_URL + path, options)
}

export function fetchPostFile(path: string, options: RequestInit = {}): Promise<{ id: number | null; filename: string; error?: boolean; message?: string }> {
	options.method = 'POST'
	options.credentials = 'include'
	options.cache = 'no-cache'
	// options.headers = {
	// 	'Content-Type': 'application/json',
	// 	...options.headers,
	// }

	return fetchApi(consts.API_URL + path, options)
}

export function fetchPut<T>(path: string, options: RequestInit = {}): Promise<any> {
	options.method = 'PUT'
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		...options.headers,
		'Content-Type': 'application/json',
	}

	return fetchApi<T>(consts.API_URL + path, options)
}

export function fetchPostOrPut<T>(path: string, id: string | undefined, options: RequestInit = {}): Promise<any> {
	options.method = id ? 'PUT' : 'POST'
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		...options.headers,
		'Content-Type': 'application/json',
	}

	return fetchApi<T>(consts.API_URL + path + (id ? '/' + id : ''), options)
}

export function fetchDelete<T>(path: string, options: RequestInit = {}): Promise<any> {
	options.method = 'DELETE'
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		...options.headers,
		'Content-Type': 'application/json',
	}

	return fetchApi<T>(consts.API_URL + path, options)
}

export function fetchCustom<T>(path: string, options: RequestInit = {}): Promise<any> {
	options.credentials = 'include'
	options.cache = 'no-cache'
	options.headers = {
		...options.headers,
		'Content-Type': 'application/json',
	}

	return fetchApi<T>(consts.API_URL + path, options)
}
