import { CSSProperties, MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import VimeoPlayer from 'react-player/vimeo'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { Link, matchPath, useHistory } from 'react-router-dom'
import consts from '../consts'
import { fetchPost } from '../fetch'
import alertsObserver from '../observers/alerts-observer'
import concreteCaseChangeObserver, { ConcreteCaseChangeObserverShooter } from '../observers/concrete-case-change-observer'
import videoPreviewObserver from '../observers/video-preview-observer'
import { appPath } from '../routes'
import { navigateToLogin, renderScore } from '../utils'
import { GlobalContext } from './GlobalContext'
import { LoadingIcon } from './LoadingIcon'
import { ConcreteCaseForCarousel, UserWatchInfo, UserWatchListType } from './MainBodyTypes'

export function VideoPreview() {
	const [concreteCase, setConcreteCase] = useState<ConcreteCaseForCarousel | null>(null)
	const [style, setStyle] = useState<CSSProperties>(stylesWrapper)
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [videoStarted, setVideoStarted] = useState(false)
	const [loadingAddRemoveMyList, setLoadingAddRemoveMyList] = useState(false)
	// const [updatingScore, setUpdatingScore] = useState(false)
	// const [carouselVideoWrapper, setCarouselVideoWrapper] = useState<HTMLElement | null>(null)
	const [showManualPlay, setShowManualPlay] = useState(true)
	const [playing, setPlaying] = useState(false)
	const [muted, setMuted] = useState(false)

	const animationSizeIncreaseRef = useRef(0)
	const videoWrapperRectRef = useRef<DOMRect>(new DOMRect())
	const requestRef = useRef(0)
	const previousTimeRef = useRef<number | null>(null)
	const playerRef = useRef<VimeoPlayer>(null)
	const history = useHistory()

	const {
		getArrowProps: getArrowPropsForDetailsTooltip,
		getTooltipProps: getTooltipPropsForDetailsTooltip,
		setTooltipRef: setTooltipRefForDetailsTooltip,
		setTriggerRef: setTriggerRefForDetailsTooltip,
		visible: visibleForDetails,
	} = usePopperTooltip()

	const {
		getArrowProps: getArrowPropsForMyListTooltip,
		getTooltipProps: getTooltipPropsForMyListTooltip,
		setTooltipRef: setTooltipRefForMyListTooltip,
		setTriggerRef: setTriggerRefForMyListTooltip,
		visible: visibleForMyListTooltip,
	} = usePopperTooltip()

	const {
		getArrowProps: getArrowPropsForPlayTooltip,
		getTooltipProps: getTooltipPropsForPlayTooltip,
		setTooltipRef: setTooltipRefForPlayTooltip,
		setTriggerRef: setTriggerRefForPlayTooltip,
		visible: visibleForPlayTooltip,
	} = usePopperTooltip()

	const { user, setUser, setConcreteCasesMasterList } = useContext(GlobalContext)

	function animateExpantion(time: number) {
		if (previousTimeRef.current) {
			const deltaTime = time - previousTimeRef.current

			animationSizeIncreaseRef.current = Math.min(0.4, animationSizeIncreaseRef.current + 5 * (deltaTime / 1000))
		}

		previousTimeRef.current = time

		positionPreview(videoWrapperRectRef.current, animationSizeIncreaseRef.current)

		if (animationSizeIncreaseRef.current <= 0.4) {
			requestRef.current = requestAnimationFrame(animateExpantion)
		}
	}

	useEffect(() => {
		function videoPreviewCallback(concreteCase: ConcreteCaseForCarousel | null, videoWrapper: HTMLElement | null) {
			// setCarouselVideoWrapper(videoWrapper)
			setConcreteCase(concreteCase)
			setVideoLoaded(false)
			setVideoStarted(false)
			setShowManualPlay(false)
			setPlaying(true)
			setMuted(false)
			setStyle(stylesWrapper)

			window.cancelAnimationFrame(requestRef.current)
			previousTimeRef.current = null
			animationSizeIncreaseRef.current = 0

			if (!videoWrapper) {
				return
			}

			videoWrapperRectRef.current = videoWrapper.getBoundingClientRect()

			if (videoWrapperRectRef.current.width * 1.4 < window.innerWidth - 50) {
				requestRef.current = window.requestAnimationFrame(animateExpantion)
			} else {
				positionPreview(videoWrapperRectRef.current, 0.01)
			}
		}

		videoPreviewObserver.subscribeConcreteCase(videoPreviewCallback)

		function handleScroll(e: Event) {
			// carouselVideoWrapper && positionPreview(carouselVideoWrapper)
		}

		// as it is, doesn't work...
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
			videoPreviewObserver.unsubscribeConcreteCase(videoPreviewCallback)
			window.cancelAnimationFrame(requestRef.current)
		}
	}, [])

	function positionPreview(rect: DOMRect, percentageBigger: number) {
		// const rect = wrapper.getBoundingClientRect()

		// const percentageBigger = rect.width * 1.4 > window.innerWidth - 20 ? 0 : 0.4

		// 40% bigger
		const height = rect.height * (percentageBigger + 1)
		const width = rect.width * (percentageBigger + 1)

		let left: number

		// if it goes beyond the left of the screen
		// starts exacly at the left
		if (rect.left - rect.width * percentageBigger < 0) {
			left = rect.left
			// if it goes beyond the right of the screen
			// ends exacly at the right
		} else if (rect.left + rect.width * (percentageBigger + 1) > window.innerWidth) {
			left = rect.left - rect.width * percentageBigger
			// otherwise starts 10% to the left (20% to the left, 20% to the right)
		} else {
			left = rect.left - rect.width * (percentageBigger / 2)
		}

		// wrapperRef.current.style.left = left.toString()+'px'
		// wrapperRef.current.style.top = (rect.y - height * 0.2 + 0).toString()+'px'
		// wrapperRef.current.style.height = height.toString()+'px'
		// wrapperRef.current.style.width = width.toString() + 'px'

		// 80 is the height if the navbar
		const top = Math.min(Math.max(rect.y - height * 0.2 + 0, 80), window.innerHeight - height - 80)

		setStyle(prev => ({
			...prev,
			left,
			top,
			height,
			width,
		}))
	}

	function handleDetailsClick(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		event.stopPropagation()

		if (matchPath(window.location.pathname, appPath(consts.app_paths.teachers))) {
			history.push(appPath(consts.app_paths.teachers_concrete_case, { id: concreteCase?.id }), { t: playerRef.current?.getCurrentTime() })
		} else {
			history.push(appPath(consts.app_paths.concrete_case, { id: concreteCase?.id }), { t: playerRef.current?.getCurrentTime() })
		}

		setConcreteCase(null)
		videoPreviewObserver.fireConcreteCase(null, null)
	}

	function handleDetailsClickFromComments(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		event.stopPropagation()

		if (matchPath(window.location.pathname, appPath(consts.app_paths.teachers))) {
			history.push(appPath(consts.app_paths.teachers_concrete_case, { id: concreteCase?.id }), { comments: true, t: playerRef.current?.getCurrentTime() })
		} else {
			history.push(appPath(consts.app_paths.concrete_case, { id: concreteCase?.id }), { comments: true, t: playerRef.current?.getCurrentTime() })
		}

		setConcreteCase(null)
		videoPreviewObserver.fireConcreteCase(null, null)
	}

	function handleMyListClick(operation: 0 | 1) {
		if (!user) {
			return navigateToLogin(history)
		}

		setLoadingAddRemoveMyList(true)

		fetchPost(consts.api_paths.add_remove_from_my_list, {
			body: JSON.stringify({
				concreteCaseId: concreteCase?.id,
				operation: operation,
			}),
		})
			.then((data: { obj: UserWatchInfo }) => {
				setConcreteCase(prev =>
					!prev
						? prev
						: {
								...prev,
								userWatchInfo: data.obj,
						  }
				)

				setConcreteCasesMasterList(prev => prev.map(cc => (cc.id === concreteCase?.id ? { ...cc, userWatchInfo: data.obj } : cc)))

				if (concreteCase) {
					concreteCaseChangeObserver.fire({ ...concreteCase, userWatchInfo: data.obj }, ConcreteCaseChangeObserverShooter.VideoPreview)
				}
			})
			.catch(err => alertsObserver.fire('error', `Ao ${operation === 0 ? 'remover' : 'inserir'} na sua lista: ${err}`))
			.finally(() => setLoadingAddRemoveMyList(false))
	}

	// function handleScoreChangeClick(newScore: number) {
	// 	if (!user) {
	// 		return navigateToLogin(history)
	// 	}

	// 	setUpdatingScore(true)

	// 	fetchPost(consts.api_paths.update_score, {
	// 		body: JSON.stringify({
	// 			concreteCaseId: concreteCase?.id,
	// 			score: newScore,
	// 		}),
	// 	})
	// 		.then((data: { obj: UserWatchInfo }) => {
	// 			setConcreteCase(prev =>
	// 				!prev
	// 					? prev
	// 					: {
	// 							...prev,
	// 							userWatchInfo: data.obj,
	// 					  }
	// 			)

	// 			if (concreteCase) {
	// 				concreteCaseChangeObserver.fire({ ...concreteCase, userWatchInfo: data.obj }, ConcreteCaseChangeObserverShooter.VideoPreview)
	// 			}
	// 		})
	// 		.catch(err => alertsObserver.fire('error', 'Ao trocar nota: ' + err))
	// 		.finally(() => setUpdatingScore(false))
	// }

	if (!concreteCase || window.innerWidth < 650) {
		return null
	}

	const player = (
		<VimeoPlayer
			ref={playerRef}
			width={style.width}
			muted={muted}
			playing={playing}
			style={{ display: videoStarted ? '' : 'none', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
			height={style.height}
			url={concreteCase.introVideo}
			onStart={() => setVideoStarted(true)}
			onError={() => {
				setPlaying(false)

				if (!muted) {
					setTimeout(() => {
						setMuted(true)
						setPlaying(true)
					}, 100)
				} else {
					setShowManualPlay(true)
				}
			}}
			onReady={() => {
				setVideoLoaded(true)
			}}
		/>
	)

	return (
		<div
			style={{ ...style, height: '' }}
			onMouseLeave={() => {
				videoPreviewObserver.fireConcreteCase(null, null)
				setConcreteCase(null)
				// setCarouselVideoWrapper(null)
				setVideoLoaded(false)
				setVideoStarted(false)
			}}
		>
			<div
				style={{ cursor: 'pointer', display: showManualPlay ? 'none' : '', opacity: 0, height: style.height, width: style.width, position: 'absolute', zIndex: 200 }}
				onClick={handleDetailsClick}
			></div>
			<div>
				{!videoLoaded ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10, zIndex: 202 }} /> : null}
				{!videoStarted && !showManualPlay ? <LoadingIcon style={{ fontSize: 30, position: 'absolute', top: 10, left: 10, zIndex: 201, color: 'gray' }} /> : null}
				{showManualPlay && (
					<i
						className="cursor-pointer fas fa-play"
						style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-100%)', fontSize: 60, display: 'block' }}
						onClick={e => {
							e.stopPropagation()
							setPlaying(true)
							setShowManualPlay(false)
						}}
					/>
				)}
				<img
					src={consts.DYNAMIC_FILES_URL + '/' + concreteCase.thumbnail}
					style={{ height: style.height, width: style.width, borderRadius: 2, display: videoStarted ? 'none' : '', textShadow: '0 0 7px black' }}
					onClick={handleDetailsClick}
				/>
				{player}
				{/* <VimeoPlayer
					ref={playerRef}
					width={style.width}
					allow="autoplay"
					playing={playing}
					style={{ display: videoStarted ? '' : 'none', borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
					height={style.height}
					url={concreteCase.introVideo}
					onStart={() => setVideoStarted(true)}
					onError={() => {
						setShowManualPlay(true)
						setPlaying(false)
					}}
					onReady={() => setVideoLoaded(true)}
				/> */}
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10, paddingLeft: 15 }}>
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					{/* <i ref={setTriggerRefForPlayTooltip} className={'cursor-pointer fas fa-play'} style={{ fontSize: 30 }} onClick={handleDetailsClick}></i> */}
					<Link to={appPath(consts.app_paths.concrete_case, { id: concreteCase.id })} onClick={handleDetailsClick}>
						<i ref={setTriggerRefForPlayTooltip} className={'cursor-pointer fas fa-play'} style={{ fontSize: 30 }}></i>
					</Link>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					{loadingAddRemoveMyList ? (
						<LoadingIcon />
					) : (
						<i
							ref={setTriggerRefForMyListTooltip}
							onClick={() => handleMyListClick(concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 0 : 1)}
							className={`cursor-pointer fas ${concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 'fa-check-circle' : 'fa-plus-circle'}`}
							style={{ fontSize: 30 }}
						></i>
					)}
				</div>
				<div
					style={{
						position: 'relative',
						border: '1px solid gray',
						borderRadius: 5,
						paddingBottom: 5,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-around',
						flexGrow: 2,
					}}
				>
					<div style={{ position: 'absolute', top: -10, backgroundColor: 'black' }}>{renderScore(concreteCase.score || 0)}</div>
					<h3 className="cursor-pointer" style={{ marginTop: 15 }} onClick={handleDetailsClickFromComments}>
						Comentários e Dúvidas
					</h3>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-around', flexGrow: 1 }}>
					<i
						ref={setTriggerRefForDetailsTooltip}
						className="border-1px-white border-2px-hover cursor-pointer fas fa-chevron-down"
						style={{ fontSize: 20, borderRadius: '50%', padding: 10 }}
						onClick={handleDetailsClick}
					></i>
				</div>
			</div>
			{concreteCase.modules.length > 0 && concreteCase.userWatchInfo?.certificateFile && (
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<a href={consts.DYNAMIC_FILES_URL + '/' + concreteCase.userWatchInfo.certificateFile} target="_blank" className="btn btn-danger" style={{ marginRight: 15 }}>
						Emitir certificado
					</a>
				</div>
			)}

			{visibleForDetails && (
				<div ref={setTooltipRefForDetailsTooltip} {...getTooltipPropsForDetailsTooltip({ className: 'tooltip-container' })}>
					<div {...getArrowPropsForDetailsTooltip({ className: 'tooltip-arrow' })} />
					Detalhes
				</div>
			)}

			{visibleForMyListTooltip && (
				<div ref={setTooltipRefForMyListTooltip} {...getTooltipPropsForMyListTooltip({ className: 'tooltip-container' })}>
					<div {...getArrowPropsForMyListTooltip({ className: 'tooltip-arrow' })} />
					{concreteCase.userWatchInfo?.type === UserWatchListType.MyList ? 'Remover da minha lista' : 'Adicionar a minha lista'}
				</div>
			)}

			{visibleForPlayTooltip && (
				<div ref={setTooltipRefForPlayTooltip} {...getTooltipPropsForPlayTooltip({ className: 'tooltip-container' })}>
					<div {...getArrowPropsForPlayTooltip({ className: 'tooltip-arrow' })} />
					Executar
				</div>
			)}

			{/* <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 15 }}>
				<h2 style={{ marginBottom: 10 }}>Reproduzir módulo 1 de {concreteCase.modules.length}</h2>
				<h3 style={{ marginBottom: 10 }}>{concreteCase.title}</h3>
				<div className="oh-hi-mark">
					<p style={{ marginBottom: 10 }} dangerouslySetInnerHTML={{ __html: marked(concreteCase.description.length > 400 ? concreteCase.description.substring(0, 400) + ' (...)' : concreteCase.description) }}></p>
				</div>
			</div> */}
		</div>
	)
}

const stylesWrapper: CSSProperties = {
	position: 'fixed',
	width: 600,
	height: 400,
	zIndex: 300,
	left: 50,
	top: 200,
	backgroundColor: '#141414',
	borderRadius: 5,
	boxShadow: '0px 3px 3px 3px rgba(0,0,0,0.15)',
}
