import { ChangeEvent, KeyboardEvent, MouseEvent, useReducer, useState } from 'react'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom'
import consts from '../../consts'
import { useEffectAsync } from '../../custom-hooks'
import { fetchGet } from '../../fetch'
import alertsObserver from '../../observers/alerts-observer'
import { appPath } from '../../routes'
import { PaginationState, SortType } from '../../types'
import { defaultCatch, getNextSortOrder, getSortOrderForRequest } from '../../utils'
import { LoadingButton } from '../LoadingButton'
import { LoadingIcon } from '../LoadingIcon'
import { TableSortArrow } from '../TableSortArrow'
import { User, UserTypeDesc } from './UserTypes'

export type UserTableSortFields = {
	id: SortType
	name: SortType
	phone: SortType
	emailHotmart: SortType
	email: SortType
	type: SortType
}

type SortField = keyof UserTableSortFields

export type UserResponse = [User[], number]

export function UserGrid() {
	const [forceUpdate, setForceUpdate] = useReducer(x => x + 1, 0)
	const [loading, setLoading] = useState<boolean>(false)
	const [pagination, setPagination] = useState<PaginationState>(consts.paginationStateDefault)
	const [sortOrder, setSortOrder] = useState<UserTableSortFields>({ id: 'none', name: 'none', phone: 'none', emailHotmart: 'none', email: 'none', type: 'none' })
	const [inputFilter, setInputFilter] = useState<string>('')

	const [loadingPassword, setLoadingPassword] = useState<{ id: number; loading: boolean }[]>([])

	const [users, setUsers] = useState<User[]>([])
	const [originalUsers, setOriginalUsers] = useState<User[]>([])

	useEffectAsync<UserResponse>(
		() => {
			setLoading(true)
			return fetchGet(consts.api_paths.users, {
				_page: pagination.activePage,
				_filter: pagination.filter,
				_order: getSortOrderForRequest(sortOrder),
			})
		},
		handleResponse,
		defaultCatch,
		setLoadingFalse,
		[forceUpdate, pagination.activePage, pagination.filter, sortOrder]
	)

	function setLoadingFalse() {
		setLoading(false)
	}

	function handleReloadClick() {
		setForceUpdate()
	}

	function handlePaginationChange(page: number) {
		setPagination(prev => ({ ...prev, activePage: page }))
	}

	function handleResponse(data: UserResponse) {
		setStateUsers(data[0], pagination.filter)
		setPagination(prev => ({
			...prev,
			totalCount: data[1],
		}))
	}

	function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
		const filter = event.target.value

		setInputFilter(filter)

		setStateUsers(originalUsers, filter)
	}

	function handleSortOrderClick(event: MouseEvent<HTMLTableHeaderCellElement>, field: SortField) {
		if (event.currentTarget.style.cursor === 'not-allowed') {
			return
		}

		const nextSort = getNextSortOrder(sortOrder[field])

		const newSortOrder = { ...sortOrder }
		newSortOrder[field] = nextSort

		setSortOrder(newSortOrder)
	}

	function handleSearchKeyPress(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			setPagination(prev => ({ ...prev, activePage: 1, filter: inputFilter }))
			setForceUpdate()
		}
	}

	function setStateUsers(newUsers: User[], filter: string) {
		setOriginalUsers(newUsers)
		setUsers(getFilteredUsers(newUsers, filter))
	}

	function getFilteredUsers(users: User[], filter: string) {
		if (filter.trim() === '') {
			return users.slice()
		}

		filter = filter.toUpperCase()

		return users.filter(
			user =>
				user.id?.toString().toUpperCase().includes(filter) ||
				user.name?.toString().toUpperCase().includes(filter) ||
				user.phone?.toString().toUpperCase().includes(filter) ||
				user.emailHotmart?.toString().toUpperCase().includes(filter) ||
				user.email?.toString().toUpperCase().includes(filter) ||
				UserTypeDesc[user.type].toString().toUpperCase().includes(filter)
		)
	}
	function generateRandomPassword(id: number) {
		setUsers(users.map(u => (u.id == id ? { ...u, password: '' } : u)))
		fetchGet<{ password: string }>(consts.api_paths.users_set_random_password + '/' + id)
			.then(json => {
				setLoadingPassword([...loadingPassword, { id: id, loading: true }])
				setUsers(users.map(u => (u.id == id ? { ...u, password: json.password, passwordPlainText: true } : u)))
				alertsObserver.fire('success', `Sucesso ao trocar senha do Usuário`)
			})
			.catch(err => {
				setUsers(users.map(u => (u.id == id ? { ...u, password: 'OK' } : u)))
				alertsObserver.fire('error', `Erro ao trocar senha do Usuário`)
			})
	}

	return (
		<section className="w-100 flex flex-column gap-sm">
			<h1 className="text-primary font-md">Cadastro de Usuários</h1>

			<div className="flex wrap gap-sm space-between">
				<div className="flex gap-sm align-items-center">
					<LoadingButton animated={loading} disabled={loading} onClick={handleReloadClick} />
					<span>|</span>
					<Link to={appPath(consts.app_paths.admin_users_new)}>
						<button className="btn btn-success">
							<i className="fas fa-plus"></i> Adicionar
						</button>
					</Link>
				</div>
				<div>
					<div className="input-group">
						<input type="text" className="form-control" disabled={loading} value={inputFilter} onChange={handleSearchChange} onKeyPress={handleSearchKeyPress} />
						<i className="fa fa-search input-addon" />
					</div>
				</div>
			</div>

			<table className="table w-100">
				<thead>
					<tr>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'id')}>
							<div className="flex space-between">
								<span>Código</span>
								<TableSortArrow order={sortOrder.id} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'name')}>
							<div className="flex space-between">
								<span>Nome</span>
								<TableSortArrow order={sortOrder.name} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'phone')}>
							<div className="flex space-between">
								<span>Telefone</span>
								<TableSortArrow order={sortOrder.phone} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'emailHotmart')}>
							<div className="flex space-between">
								<span>E-mail Hotmart</span>
								<TableSortArrow order={sortOrder.emailHotmart} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'email')}>
							<div className="flex space-between">
								<span>E-mail Sistema</span>
								<TableSortArrow order={sortOrder.email} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select" onClick={e => handleSortOrderClick(e, 'type')}>
							<div className="flex space-between">
								<span>Status</span>
								<TableSortArrow order={sortOrder.type} />
							</div>
						</th>
						<th style={loading ? { cursor: 'not-allowed' } : {}} className="hover-select">
							<div className="flex space-between">
								<span>Senha</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{users.map(user => (
						<tr key={user.id}>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{user.id}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{user.name}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{user.phone}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{user.emailHotmart}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{user.email}</Link>
							</td>
							<td>
								<Link to={appPath(consts.app_paths.admin_users_id, { id: user.id })}>{UserTypeDesc[user.type]}</Link>
							</td>
							<td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								{user.passwordPlainText ? (
									user.password
								) : (
									<>
										{user.password == '' ? (
											<LoadingIcon />
										) : (
											<button className="btn btn-primary" onClick={() => generateRandomPassword(user.id)}>
												Gerar senha
											</button>
										)}
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>

			<Pagination
				activePage={pagination.activePage}
				itemsCountPerPage={pagination.countPerPage}
				totalItemsCount={pagination.totalCount}
				pageRangeDisplayed={pagination.range}
				onChange={handlePaginationChange}
			/>
		</section>
	)
}
