import { CSSProperties, MouseEvent, useRef, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import consts from '../consts'
import globalsObserver from '../observers/globals-observer'
import videoPreviewObserver from '../observers/video-preview-observer'
import { appPath } from '../routes'
import { UserType } from './cruds/UserTypes'
import { LoggedUserInfo } from './GlobalContext'
import { ConcreteCaseForCarousel } from './MainBodyTypes'

export function Video(props: { concreteCase: ConcreteCaseForCarousel; showThumbnail: boolean; style?: CSSProperties; user: LoggedUserInfo | null }) {
	const timeoutId = useRef<NodeJS.Timeout>()
	const refSelf = useRef<HTMLDivElement>(null)
	const [loadImageError, setLoadImageError] = useState(false)

	const history = useHistory()

	function handleMouseEnter() {
		if (props.concreteCase.id && refSelf.current) {
			timeoutId.current = setTimeout(() => {
				if (refSelf.current && !globalsObserver.getDraggingCarousel()) {
					// const rect = refSelf.current.getBoundingClientRect()

					// if (rect.left < 0 || rect.right > window.innerWidth) return

					videoPreviewObserver.fireConcreteCase(props.concreteCase, refSelf.current)
				}
			}, 300)
		}
	}

	function handleMouseLeave() {
		timeoutId.current && clearTimeout(timeoutId.current)
	}

	function handleDetailsClick(event: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
		event.stopPropagation()

		// modalsObserver.fire('concrete-case', 'open', props)
		if (matchPath(window.location.pathname, appPath(consts.app_paths.teachers))) {
			history.push(appPath(consts.app_paths.teachers_concrete_case, { id: props.concreteCase.id }))
		} else {
			history.push(appPath(consts.app_paths.concrete_case, { id: props.concreteCase.id }))
		}

		videoPreviewObserver.fireConcreteCase(null, null)
	}

	const modulesDuration = props.concreteCase.modules.reduce((sum, el) => sum + el.duration, 0)
	const percentageWatched = Math.min(props.concreteCase.userWatchInfo?.durationTotal && modulesDuration > 0 ? (props.concreteCase.userWatchInfo?.durationTotal / modulesDuration) * 100 : 0, 100)

	const thumbnail = props.concreteCase.thumbnailSm || props.concreteCase.thumbnail

	return (
		<article style={{ position: 'relative', ...(props.style || {}) }} ref={refSelf} className="video">
			{props.showThumbnail && thumbnail ? (
				<img
					className="video__thumbnail"
					src={consts.DYNAMIC_FILES_URL + '/' + thumbnail}
					alt={props.concreteCase.title}
					onClick={handleDetailsClick}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onError={() => {
						setLoadImageError(true)
					}}
				/>
			) : null}
			{loadImageError ? <i className="fas fa-times-circle floating-error"></i> : null}
			{percentageWatched ? <div style={{ height: 3, width: `${percentageWatched}%`, backgroundColor: 'red', position: 'absolute', left: 0, bottom: 0 }}></div> : null}
			{(props.user?.type === UserType.Admin || props.user?.type === UserType.Teacher) && props.concreteCase.pendingCommentsCount ? (
				<div title="Comentários pendentes" className="video__pending-comments">
					{props.concreteCase.pendingCommentsCount}
				</div>
			) : null}
		</article>
	)
}
